import React from 'react'
import { Grid } from '@mui/material'
import { grey } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PersonIcon from '@mui/icons-material/Person';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ClearIcon from '@mui/icons-material/Clear';
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import Helmet from 'react-helmet'
import { HomeItem } from '../../components/common/home/HomeItem'
import { Role } from '../../common/roles/role'
import withRole from '../../common/roles/withRole'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import StorefrontIcon from '@mui/icons-material/Storefront';

export default function Home () {
  const RestrictedMarinaHomeItem = withRole([Role.Marina])(HomeItem)
  const RestrictedKioskoHomeItem = withRole([Role.Kiosko])(HomeItem)
  const RestrictedPointsOfSaleButton = withRole([Role.Marina, Role.Editor, Role.Consulta, Role.Distribuidor])(HomeItem)
  const RestrictedConsumtionButton = withRole([Role.Marina, Role.Kiosko, Role.Distribuidor])(HomeItem)
  const RestrictedMarinaKioskoButton = withRole([Role.Marina, Role.Kiosko])(HomeItem)

  return (
    <>
      <Helmet>
        <title>Inicio - Marina BCN</title>
      </Helmet>
      <Grid container spacing={3} sx={{
        '& .MuiGrid-item': {
          display: 'flex'
        },
        '& .MuiPaper-root': {
            bgcolor: grey[100],
            flexGrow: 1
        },
        '& .MuiCardContent-root': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        '& .MuiAvatar-root': {
          marginTop: 1,
          marginBottom: 0.5,
          bgcolor: 'secondary.main',
          width: 40,
          height: 40
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1.5rem',
          color: grey[100]
        },
        '& .MuiTypography-root': {
            marginTop: 0.5,
            marginBottom: 0,
            textAlign: 'center'
        }
      }}>
        <HomeItem text='Consumo del día' icon={<AssignmentTurnedInIcon />} to='/day-consumptions' />
        <HomeItem text='Consumo Histórico' icon={<AssignmentIcon />} to='/historical-consumptions' />
        <RestrictedConsumtionButton text='Venta' icon={<CheckIcon />} to='/consumptions/new' />
        <RestrictedMarinaHomeItem text='Venta masiva' icon={<DoneAllIcon />} to='/consumptions/massive' />
        <RestrictedMarinaKioskoButton text='Venta atrasada' icon={<WatchLaterIcon />} to='/consumptions/new-delayed' />
        <RestrictedConsumtionButton text='Anular venta' icon={<ClearIcon />} to='/consumptions/cancel' />
        <RestrictedMarinaHomeItem text='Anular histórico' icon={<ContentPasteOffIcon />} to='/historical-consumptions/cancel' />
        <RestrictedKioskoHomeItem text='Mis clientes' icon={<AssignmentIndIcon />} to='/clients' />
        <RestrictedMarinaHomeItem text='Usuarios' icon={<PersonIcon />} to='/users' />
        <RestrictedPointsOfSaleButton text='Puntos de venta' icon={<StorefrontIcon />} to='/points-of-sale' />
      </Grid>
    </>
  )
}
