import React from "react";
import { useState, useEffect } from "react";
import { Card, CardHeader, CircularProgress, CardContent, Paper, Box, Avatar, IconButton, Stack, Button, List, Menu, ListItem, TextField, Chip, Divider, Grid, MenuItem, ListItemIcon, InputAdornment } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Helmet } from "react-helmet";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { es } from "date-fns/locale";
import { useSnackbar } from 'notistack'
import GroupIcon from '@mui/icons-material/Group';
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from '@mui/icons-material/Refresh';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOffIcon from '@mui/icons-material/LocationOff';

import useModal from "../../../common/hooks/useModal";
import { SubmitButton } from "../../common/forms/submitButton";
import { getFormattedFieldName, getFormattedStatus } from "../../../helpers";
import AssignAddressForm from "./AssignAddressForm";
import AddressForm from "./AddressForm";
import pointOfSaleService from '../../../services/pointsOfSale.service';
import addressService from '../../../services/address.service';
import { getSimilarity } from "../../utils/Similarity";
import { fetchPointsOfSale } from "../../../common/features/pointsOfSale/pointsOfSaleSlice";
import { useDispatch, useSelector } from 'react-redux';

export const EditSalePointForm = ({ allData, addresses, distributors, showMigrateClients, setIsLoading, setShowPos, setIsAction }) => {

    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { role } = useSelector(state => state.auth.user)

    const [isSubmited, setIsSubmited] = useState(false)

    const [dataSalePoint, setDataSalePoint] = useState({
        id: allData.id ?? '',
        name: allData.name ?? '',
        id_distributor: allData.id_distributor ?? '',
        code_distributor: allData.code_distributor ?? '',
        code_editor: allData.code_editor ?? '',
        phone: allData.phone ?? '',
        nif: allData.nif ?? '',
        email: allData.email ?? '',
        status: allData.status ?? '',
        start_date: allData.start_date ? new Date(allData.start_date) : '',
        end_date: allData.end_date ? new Date(allData.end_date) : '',
        id_address: allData.id_address ?? '',
        description: allData.description ?? '',
    })

    const [dataAddress, setDataAddress] = useState({
        address_id: allData.id_address ?? '',
        address_type: allData.address_type ?? '',
        address_name: allData.address_name ?? '',
        address_number: allData.address_number ?? '',
        address_floor: allData.address_floor ?? '',
        address_door: allData.address_door ?? '',
        address_additional: allData.address_additional ?? '',
        address_postcode: allData.address_postcode ?? '',
        address_city: allData.address_city ?? '',
        address_province: allData.address_province ?? '',
        address_country: allData.address_country ?? '',
        address_latitude: allData.address_latitude ?? '',
        address_longitude: allData.address_longitude ?? '',
        address_title: allData.address_title ?? '',
        address_description: allData.address_description ?? '',
        address_full: allData.address_full ?? ''
    });

    const modal = useModal()

    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditSalePoint, setIsEditSalePoint] = useState(false);
    const [isEditAddress, setIsEditAddress] = useState(false);
    const [isAssignAddress, setIsAssignAddress] = useState(false);
    const hasAddress = () => {
        return allData.id_address !== '' && allData.id_address !== null && allData.id_address !== undefined;
    };

    const open = Boolean(anchorEl); // control to open actions menu

    const showItems = ["name", "code_distributor", "code_editor", "phone", "nif", "email", "description", "start_date", "end_date"];

    let salePointStatusLabel = getFormattedStatus(dataSalePoint.status)

    const handleActionsClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleActionsClose = () => {
        setAnchorEl(null);
    };

    const checkSimilarity = (element, index, array) => {
        if (dataAddress["address_id"] !== element["id"]) {
            if (dataAddress["address_name"] && dataAddress["address_number"] && dataAddress["address_postcode"]) {
                if (dataAddress["address_postcode"] === element["postcode"]){
                    let similarity = getSimilarity(
                        element["name​"] + "-" + element["number​"] ,
                        dataAddress["address_name"] + "-" + dataAddress["address_number"] 
                    );
                    if (similarity * 100 > 75) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    // 1) handleSubmit here test if adress exists to avoid create duplications before submit data, then submitForm

    const handleSubmit = async (values, { setSubmitting }) => {
        setIsAction(true)
        setIsSubmited(true)
        if (isEditAddress) {
            try {
                //check if the address already exists
                let addressExists = addresses.find(checkSimilarity);

                if (addressExists) {
                    modal.show({
                        content: <><p>Hay una dirección similar en: <strong>{addressExists['name​'] + " " + addressExists['number​']}</strong></p><p>¿Estás seguro que quieres actualizar esta dirección sin reutilizar una ya existente?</p></>,
                        onSubmit: () => {
                            submitForm(values, { setSubmitting })
                        },
                        secondaryBtnText: 'Cancelar',
                        secondaryBtnHandler: () =>{
                          setIsSubmited(false)
                        },
                        variant: 'error'
                    })
                } else {
                    submitForm(values, { setSubmitting })
                }
            } catch (error) {
                console.error("find address ", error);
            }
        }
        else {
            submitForm(values, { setSubmitting })
        }
    }

    // 2) submitForm function send data to respective endpoints (address data and/or salepoint data)

    const submitForm = async () => {
        try {
            if (isEditAddress) {
                let dataAddressDB = Object.keys(dataAddress).reduce((acc, key) => ({ ...acc, [key.replace('address_', '')]: dataAddress[key] }), {})
                if (dataSalePoint['id_address'] !== "") {
                    //PUT address
                    dataAddressDB['id'] = dataSalePoint['id_address']
                    await addressService.updatebyId(dataAddressDB, dataSalePoint['id_address'])
                        .then(response => {
                            if (response.status === 200) {
                                let dataSalePointDB = dataSalePoint
                                dataSalePointDB['start_date'] = typeof dataSalePoint['start_date'] === 'object' ? dataSalePoint['start_date'].getTime() : dataSalePoint['start_date']
                                dataSalePointDB['end_date'] = typeof dataSalePoint['end_date'] === 'object' ? dataSalePoint['end_date'].getTime() : dataSalePoint['end_date']

                                // PUT pointOfSale
                                pointOfSaleService.updatebyId(dataSalePointDB, dataSalePointDB['id'])
                                .then(response => {
                                    if (response.status === 200) {
                                        enqueueSnackbar('Punto de venta actualizado correctamente', {
                                            variant: 'success'
                                        })
                                        dispatch(fetchPointsOfSale(role))
                                        setShowPos(false)
                                    } else {
                                        enqueueSnackbar(response.message, {
                                            variant: 'error'
                                        })
                                    }
                                })
                            }
                            else {
                                enqueueSnackbar(response.message, {
                                    variant: 'error'
                                })
                            }
                        })
                } else {
                    // POST address (newId) 
                    await addressService.insert(dataAddressDB)
                        .then(response => {
                            if (response.status === 200) {
                                setDataSalePoint({ ...dataSalePoint, id_address: response.id })
                                let dataSalePointDB = dataSalePoint
                                dataSalePointDB['id_address'] = response.id // assign new id_address before send new info
                                dataSalePointDB['start_date'] = typeof dataSalePoint['start_date'] === 'object' ? dataSalePoint['start_date'].getTime() : dataSalePoint['start_date']
                                dataSalePointDB['end_date'] = typeof dataSalePoint['end_date'] === 'object' ? dataSalePoint['end_date'].getTime() : dataSalePoint['end_date']

                                // PUT pointOfSale
                                pointOfSaleService.updatebyId(dataSalePointDB, dataSalePointDB['id']).then(response => {
                                    if (response.status === 200) {
                                        enqueueSnackbar('Punto de venta actualizado correctamente', {
                                            variant: 'success'
                                        })
                                        dispatch(fetchPointsOfSale(role))
                                        setShowPos(false)
                                    } else {
                                        enqueueSnackbar(response.message, {
                                            variant: 'error'
                                        })
                                    }
                                })
                            } else {
                                enqueueSnackbar(response.message, {
                                    variant: 'error'
                                })
                            }
                        })
                }
            } else {
                dataSalePoint['start_date'] = typeof dataSalePoint['start_date'] === 'object' ? dataSalePoint['start_date'].getTime() : dataSalePoint['start_date']
                dataSalePoint['end_date'] = typeof dataSalePoint['end_date'] === 'object' ? dataSalePoint['end_date'].getTime() : dataSalePoint['end_date']
                await pointOfSaleService.updatebyId(dataSalePoint, dataSalePoint['id'])
                .then(response => {
                    if (response.status === 200) {
                        enqueueSnackbar('Punto de venta actualizado correctamente', {
                            variant: 'success'
                        })
                        dispatch(fetchPointsOfSale(role))
                    } else {
                        enqueueSnackbar(response.message, {
                            variant: 'error'
                        })
                    }
                })

            }
        } catch (error) {
            enqueueSnackbar(error.message, {
                variant: 'error'
            })
        } finally {
            setIsSubmited(false)
            setShowPos(false)
        }
    }

    // Generic function for handle change values on form fields

    const onChangeField = (event, item, setFieldValue, objectTarget) => {
        const value = event.target.value
        setFieldValue(item, value)
        if (objectTarget === "salePoint") {
            setDataSalePoint({ ...dataSalePoint, [item]: value })
            setIsEditSalePoint(true)
        }
        if (objectTarget === "address") {
            setDataAddress({ ...dataAddress, [item]: value })
            setIsEditAddress(true)
        }
    }

    const onChangeDate = (item, value, setFieldValue) => {
        setFieldValue(item, value)
        setDataSalePoint({ ...dataSalePoint, [item]: value })
        setIsEditSalePoint(true)
    };

    // Reset Button 

    const handleClear = (handleReset) => {
        handleReset();
        setIsEditSalePoint(false); setIsEditAddress(false); setIsAssignAddress(false)

        setDataSalePoint({
            ...dataSalePoint,
            'name': allData.name,
            'id_address': allData.id_address,
            'start_date': allData.start_date,
            'end_date': allData.end_date,
            'code_distributor': allData.code_distributor,
            'code_editor': allData.code_editor,
            'nif': allData.nif,
            'phone': allData.phone,
            'email': allData.email,
            'description': allData.description,
        }) // set to original salePoint data
    }

    // Validation Schema

    const validationSchema = Yup.object().shape({
        code_distributor: Yup.string()
            .required('El campo "Código Distribuidor" es requerido')
            .matches(/^[0-9]+$/, 'El valor debe ser numérico')
            .max(8, 'El código no puede ser mayor de 8 dígitos'),
        code_editor: Yup.string()
            .required('El campo "Código Editor" es requerido')
            .matches(/^[0-9]+$/, 'El valor debe ser numérico')
            .max(6, 'El código no puede ser mayor de 6 dígitos'),
        name: Yup.string().required('El campo "Nombre" es requerido').max(50, 'El "Nombre" no puede ser mayor de 50 carácteres'),
        nif: Yup.string()
            .matches(/^\d{8}[A-HJ-NP-TV-Z]$|^[ABCDEFGHJKLMNPQRSUVW]\d{7}[0-9A-J]$|^[Aa][0-9]{6}[A-Za-z0-9]$/, 'NIF/CIF no válido'),
        phone: Yup.string()
            .max(13, 'El teléfono no puede ser mayor de 13 dígitos')
            .matches(/^\+(34|376)\d{6,14}$/, "El prefijo (+34) o (+376) es requerido"),
        start_date: Yup.date().typeError('Fecha no válida').required('El campo "Fecha de Alta" es requerido').test('test-out-of-range', 'Fecha fuera de rango disponible', function (value) {
            const end_date = this.parent.end_date;
            if (value > end_date) {
                return false;
            }
            return true
        }),
        end_date: Yup.date().typeError('Fecha no válida').required('El campo "Fecha de Baja" es requerido').test('test-out-of-range', 'Fecha fuera de rango disponible', function (value) {
            const start_date = this.parent.start_date;
            if (value < start_date) {
                return false;
            }
            return true
        }),
        address_latitude: Yup.string()
            .matches(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/, 'La latitud debe ser un valor válido'),
        address_longitude: Yup.string()
            .matches(/^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/, 'La longitud debe ser un valor válido')
    })

    // Actions

    const [isActionToReactivate, setIsActionToReactivate] = useState(false)
    const [isActionToUnsubscribe, setIsActionToUnsubscribe] = useState(false)
    const [isActionToUnlinkAddress, setIsActionToUnlinkAddress] = useState(false)

    const actionToUnsubscribe = () => {
        setIsAction(true)
        setIsLoading(true)
        dataSalePoint['end_date'] = Date.now()
        pointOfSaleService.updatebyId(dataSalePoint, dataSalePoint['id'])
        .then(response => {
            setIsLoading(false)
            if (response.status === 200) {
                enqueueSnackbar("Punto de venta dado de baja", {
                    variant: 'success'
                })
                dispatch(fetchPointsOfSale(role))
                setShowPos(false)
            }
            else {
                enqueueSnackbar(response.message, {
                    variant: 'error'
                })
            }
        }).catch(err => {
            enqueueSnackbar(err.message, {
                variant: 'error'
            })
        })
    }

    const actionToReactivate = () => {
        setIsAction(true)
        setIsLoading(true)
        dataSalePoint['end_date'] = Date.parse("Jan 1, 2100")
        pointOfSaleService.updatebyId(dataSalePoint, dataSalePoint['id'])
        .then(response => {
            setIsLoading(false)
            if (response.status === 200) {
                enqueueSnackbar("Punto de venta reactivado", {
                    variant: 'success'
                })
                dispatch(fetchPointsOfSale(role))
                setShowPos(false)
            }
            else {
                enqueueSnackbar(response.message, {
                    variant: 'error'
                })
            }
        }).catch(err => {
            enqueueSnackbar(err.message, {
                variant: 'error'
            })
        })
    }

    const actionToUnlinkAddress = () => {
        setIsAction(true)
        dataSalePoint['id_address'] = "";
        pointOfSaleService.updatebyId(dataSalePoint, dataSalePoint['id'])
        .then(response => {
            setIsLoading(false)
            if (response.status === 200) {
                enqueueSnackbar("Dirección desvinculada correctamente", {
                    variant: 'success'
                })
                dispatch(fetchPointsOfSale(role))
                setShowPos(false)
            }
            else {
                enqueueSnackbar(response.message, {
                    variant: 'error'
                })
            }
        }).catch(err => {
            enqueueSnackbar(err.message, {
                variant: 'error'
            })
        })
    }

    useEffect(() => {
        if (isActionToReactivate) {
            setIsActionToReactivate(false)
            actionToReactivate()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionToReactivate,])

    useEffect(() => {
        if (isActionToUnsubscribe) {
            setIsActionToUnsubscribe(false)
            actionToUnsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionToUnsubscribe])

    useEffect(() => {
        if (isActionToUnlinkAddress) {
            setIsActionToUnlinkAddress(false)
            actionToUnlinkAddress()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActionToUnlinkAddress])

    if (isActionToReactivate || isActionToUnsubscribe || isActionToUnlinkAddress) {
        return (
            <CircularProgress size={24} color="secondary"></CircularProgress>
        )
    } else {
        <div>Error</div>
    }

    return (
        <>
            <Helmet>
                <title>Punto de Venta - Marina BCN</title>
            </Helmet>

            <h3>Punto de Venta</h3>

            <Formik
                initialValues={{ ...dataSalePoint, ...dataAddress }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ handleChange, handleReset, handleBlur, setFieldValue, values, isSubmitting, isValid, touched, errors, resetForm }) => (

                    <Box>
                        <Form>
                            <Card sx={{ mb: 2 }}>
                                <Paper elevation={0}>
                                    <CardHeader
                                        sx={{ pt: 3, pb: 0, '& .MuiCardHeader-action': { my: 0, mx: 2 } }}
                                        avatar={
                                            <Avatar sx={{ width: 42, height: 42, ml: 2 }} aria-label="point-of-sale">
                                                <StorefrontIcon />
                                            </Avatar>
                                        }
                                        action={
                                            <>
                                                <Chip
                                                    sx={{ mr: 1 }}
                                                    variant="outlined"
                                                    label={salePointStatusLabel.text}
                                                    color={salePointStatusLabel.color}
                                                >
                                                </Chip>
                                                <IconButton
                                                    onClick={handleActionsClick}
                                                    aria-controls={open ? 'account-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    id="account-menu"
                                                    open={open}
                                                    onClose={handleActionsClose}
                                                    onClick={handleActionsClose}
                                                    slotProps={{
                                                        elevation: 0,
                                                        sx: {
                                                            overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, minWidth: 140,
                                                            '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
                                                            '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                                                        },
                                                    }}
                                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                >

                                                    {dataSalePoint.status === "BAJA" ? (
                                                        <MenuItem onClick={(evt) =>
                                                            modal.show({
                                                                title: "Reactivar",
                                                                content: `¿Estás seguro que quieres volver reactivar el punto de venta ${dataSalePoint.name}?`,
                                                                onSubmit: () => {
                                                                    handleClear(handleReset)
                                                                    setIsActionToReactivate(true)
                                                                },
                                                            })
                                                        }>
                                                            <ListItemIcon>
                                                                <RefreshIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            Reactivar
                                                        </MenuItem>
                                                    ) : null}

                                                    {dataSalePoint.status === "ACTIVO" ? (
                                                        <MenuItem onClick={(evt) =>
                                                            modal.show({
                                                                title: "Dar de Baja",
                                                                content: `¿Estás seguro que quieres dar de baja el punto de venta ${dataSalePoint.name}?`,
                                                                onSubmit: () => {
                                                                    handleClear(handleReset)
                                                                    setIsActionToUnsubscribe(true)
                                                                },
                                                            })
                                                        }>
                                                            <ListItemIcon>
                                                                <DeleteIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            Dar de baja
                                                        </MenuItem>
                                                    ) : null}
                                                    <MenuItem onClick={showMigrateClients}>
                                                        <ListItemIcon>
                                                            <GroupIcon fontSize="small" />
                                                        </ListItemIcon>
                                                        Migrar clientes
                                                    </MenuItem>
                                                    {hasAddress ? (
                                                        <MenuItem onClick={(evt) => {
                                                            modal.show({
                                                                title: "Desvincular dirección",
                                                                content: <><p>¿Estás seguro que quieres desvincular la dirección: <strong>{dataAddress.address_full}</strong> del punto de venta {dataSalePoint.name}?</p></>,
                                                                onSubmit: () => {
                                                                    handleClear(handleReset)
                                                                    actionToUnlinkAddress()
                                                                }
                                                            })
                                                        }}>
                                                            <ListItemIcon>
                                                                <LocationOffIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            Desvincular dirección
                                                        </MenuItem>
                                                    ) : null}
                                                </Menu>
                                            </>
                                        }
                                        title={values.name}
                                        subheader={distributors.map(distributor => allData.id_distributor === distributor.id ? distributor.name : '')}
                                    >
                                    </CardHeader>
                                    <CardContent sx={{ pb: 0 }}>
                                        <Grid container>
                                            <Grid item xs={12} md={4}>
                                                <List dense={true} sx={{ pb: 0 }}>
                                                    {Object.keys(dataSalePoint).map((item, i) => (
                                                        showItems.includes(item) ? (
                                                            <ListItem key={i} alignItems="flex-start" sx={{ mb: 1 }}>
                                                                {(item === "start_date" || item === "end_date") ? (
                                                                    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                                                                        <DesktopDatePicker
                                                                            setFieldValue={setFieldValue}
                                                                            label={getFormattedFieldName(item)}
                                                                            inputFormat="dd/MM/yyyy"
                                                                            value={values[item] ? values[item] : null}
                                                                            onChange={(value) => { onChangeDate(item, value, setFieldValue) }}
                                                                            views={['day']}
                                                                            renderInput={(params) => (
                                                                                <TextField {...params} name={item} size="small" fullWidth helperText={errors[item]} error={Boolean(errors[item])} />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                ) : (
                                                                    <Field
                                                                        as={TextField}
                                                                        variant='outlined'
                                                                        margin="none"
                                                                        size="small"
                                                                        label={getFormattedFieldName(item)}
                                                                        value={values[item]}
                                                                        onChange={(event) => { onChangeField(event, item, setFieldValue, "salePoint") }}
                                                                        name={item}
                                                                        fullWidth
                                                                        error={Boolean(errors[item])}
                                                                        helperText={errors[item]}
                                                                    />
                                                                )}
                                                            </ListItem>
                                                        ) : ('')
                                                    ))}

                                                </List>
                                            </Grid>
                                            <Divider orientation="vertical" flexItem sx={{ m: 2, mb: 4 }} />
                                            {!isAssignAddress ? (
                                                (hasAddress) ? (
                                                    <Grid container item xs={12} md>
                                                        <List dense={true}>
                                                            <ListItem>
                                                                <Field
                                                                    as={TextField}  // only informative Textfield with full address
                                                                    variant='outlined'
                                                                    margin="none"
                                                                    size="small"
                                                                    value={values.address_full}
                                                                    label={getFormattedFieldName("address_full")}
                                                                    name={"address_full"}
                                                                    fullWidth
                                                                    disabled
                                                                    sx={{ mb: 1, '&.MuiFormControl-root .MuiOutlinedInput-root': { p: 0 } }}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton disabled>
                                                                                    <LocationOnIcon></LocationOnIcon>
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        )
                                                                    }}
                                                                />
                                                            </ListItem>

                                                            <AddressForm        //all the rest of Address (ListItems) Fields
                                                                values={values}
                                                                touched={touched}
                                                                errors={errors}
                                                                onChangeField={onChangeField}
                                                                setFieldValue={setFieldValue}
                                                            />

                                                        </List>
                                                    </Grid>
                                                ) : (
                                                    <Grid item xs={12} md >
                                                        <AddressForm        //all the rest of Address (ListItems) Fields
                                                            values={values}
                                                            touched={touched}
                                                            errors={errors}
                                                            onChangeField={onChangeField}
                                                            setFieldValue={setFieldValue}
                                                        />
                                                    </Grid>
                                                )

                                            ) : (
                                                <Grid container item xs={12} md>
                                                    <AssignAddressForm
                                                        addresses={addresses}
                                                        touched={touched}
                                                        errors={errors}
                                                        setFieldValue={setFieldValue}
                                                        setDataSalePoint={setDataSalePoint}
                                                        dataSalePoint={dataSalePoint}
                                                        setIsEditSalePoint={setIsEditSalePoint}
                                                    />
                                                </Grid>
                                            )}

                                        </Grid>
                                    </CardContent>
                                </Paper>
                            </Card>
                            <Stack spacing={1} direction="row" justifyContent={"space-between"}>
                                <Stack spacing={1} direction="row">
                                    <Button onClick={() => { setShowPos(false) }} startIcon={<ArrowBackIcon />}>
                                        Volver
                                    </Button>
                                    <Button disabled={!isEditSalePoint && !isEditAddress} onClick={() => { handleClear(handleReset) }} type="reset" variant="outlined" color="secondary">
                                        Cancelar
                                    </Button>
                                    <SubmitButton
                                        text='Guardar Cambios'
                                        isValid={isValid}
                                        isSubmitting={isSubmitting || isSubmited}
                                        disabled={!isEditSalePoint && !isEditAddress}
                                    >
                                    </SubmitButton>
                                </Stack>
                                <Button onClick={() => { setIsAssignAddress(true) }} variant="outlined" color="primary">
                                    Asignar Dirección
                                </Button>
                            </Stack>
                        </Form>
                    </Box>
                )}
            </Formik>

        </>
    )
}
