import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRouteMatch } from 'react-router-dom'

export const MarinaAppBar = ({isAuthenticated, handleDrawerToggle}) => {
  const isHome = useRouteMatch('/home') !== null;

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          '& .marina-logo': {
            textDecoration: 'none'
          },
          '& .marina-logo img': {
            marginLeft: { sm: '-15px' }
          }
        }}
      >
        {(isAuthenticated && !isHome) ? (
          <IconButton
            color="inherit"
            aria-label="home"
            edge="start"
            component={Link}
            to="/home"
            sx={{ display: { sm: "none" } }}
          >
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <Box
            sx={{
              width: '28px',
              height: '40px',
              display: { sm: "none" }
            }}
          />
        )}
        <Link to="/home" className="marina-logo">
          {(process.env.REACT_APP_ENVIRONMENT === 'des' || process.env.REACT_APP_ENVIRONMENT === 'tes') ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                '& > span': {
                  textTransform: 'uppercase',
                  lineHeight: 1,
                  color: 'white',
                  backgroundColor: 'secondary.main',
                  py: 0.5,
                  px: 0.75,
                  borderRadius: 2,
                  fontSize: '14px'
                }
              }}
            >
              <img
                src="/marina-logo.png"
                height="56"
                alt="Marina Logo"
                style={{ display: "block" }}
              />
              <span>{ process.env.REACT_APP_ENVIRONMENT }</span>
            </Box>
          ) : (
            <img
              className="marina-logo"
              src="/marina-logo.png"
              height="56"
              alt="Marina Logo"
              style={{ display: "block" }}
            />
          )}
        </Link>
        {isAuthenticated ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <Box
            sx={{
              width: '28px',
              height: '40px',
              display: { sm: "none" }
            }}
          />
        )}
      </Toolbar>
    </AppBar>
  );
};
