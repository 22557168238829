import React from 'react';
import { List, ListItem, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const AssignAddressForm = ({
  addresses,
  touched,
  errors,
  dataSalePoint,
  setFieldValue,
  setDataSalePoint,
  setIsEditSalePoint,
}) => {
  return (
    <List dense={true} sx={{flexGrow:1}}>
      <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
        <Autocomplete
          id='id_address'
          size='small'
          loading
          loadingText={"Cargando..."}
          fullWidth
          sx={{ pt: 0, mb: 2 }}
          disableClearable
          options={addresses}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Dirección completa"
              error={touched.id_address && Boolean(errors.id_address)}
              helperText="Asigna una dirección existente"
            />
          )}
          getOptionLabel={(option) => option.info_address}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(event, value) => {
            setFieldValue("id_address", value?.id ? value.id : '');
            setDataSalePoint({ ...dataSalePoint, 'id_address': value.id });
            setIsEditSalePoint(true);
          }}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <span>{option.info_address}</span>
            </li>
          )}
        >
        </Autocomplete>
      </ListItem>
    </List>
  );
};

export default AssignAddressForm;
