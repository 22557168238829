import { Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { grey } from '@mui/material/colors';
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import useModal from "../../common/hooks/useModal";
import clientService from "../../services/client.service";
import { useSnackbar } from 'notistack'
import { useHistory } from "react-router-dom";

export const ClientsTable = ({ rowsState, setRowsState }) => {
  const modal = useModal();
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory();

  const { rows, loading } = rowsState;

  const columns = [
    {
      headerName: "Nombre",
      title: "alias",
      field: "alias",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body1">{params.value}</Typography>
          <Typography variant="body2" color={grey[500]}>{params.row.card}</Typography>
        </Stack>
      ),
    },
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      width: 140,
      renderCell: (cellValues) => {
        return (
          <>
            <Chip
              label="Venta"
              size="small"
              variant="outlined"
              onClick={(evt) =>
                history.push({
                  pathname: '/consumptions/new',
                  state: { cardNumber: cellValues.row.card }
                })
              }
            />
            <Tooltip title="Editar">
              <IconButton
                size="small"
                aria-label="Editar cliente"
                onClick={(evt) => history.push(`/client/${cellValues.id}`)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar">
              <IconButton
                size="small"
                aria-label="Eliminar cliente"
                onClick={(evt) =>
                  modal.show({
                    title: "Eliminar cliente",
                    content: `¿Estás seguro que quieres eliminar el cliente ${cellValues.row.card}?`,
                    onSubmit: () => {
                      handleDeleteClient(cellValues.id);
                    },
                  })
                }
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleDeleteClient = (clientId) => {
    setRowsState({ ...rowsState, loading: true });
    clientService
      .deletebyId({ clientId })
      .then((response) => {
        if (response.status === 200) {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          setRowsState({ ...rowsState, loading: false });
          window.location.reload(false);
        } else {
          enqueueSnackbar(response.message, {
            variant: "error",
          });
          setRowsState({ ...rowsState, loading: false });
          return;
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
        setRowsState({ ...rowsState, loading: false });
      });
  };

  return (
    <div style={{ height: 470, width: "100%", marginBottom: 30 }}>
      <DataGrid
        density="comfortable"
        rows={rows}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        loading={loading}
        pagination
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No hay resultados
            </Stack>
          )
        }}
      />
    </div>
  );
};
