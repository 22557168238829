import { Box, Drawer } from '@mui/material'
import { DrawerContent } from './DrawerContent'

export const DrawerContainer = ({
  role,
  email,
  drawerOpen,
  handleDrawerToggle,
  ...props
}) => {
  const drawerWidth = 280
  const { window } = props
  const container =
    window !== undefined ? () => window().document.body : undefined
  return (
    <Box
      component='nav'
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <Drawer
        anchor='right'
        container={container}
        variant='temporary'
        open={drawerOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            justifyContent: 'space-between'
          }
        }}
      >
        <DrawerContent role={role} email={email} handleDrawerToggle={handleDrawerToggle} />
      </Drawer>
      <Drawer
        variant='permanent'
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            justifyContent: 'space-between'
          }
        }}
        open
      >
        <DrawerContent role={role} email={email} handleDrawerToggle={handleDrawerToggle} />
      </Drawer>
    </Box>
  )
}
