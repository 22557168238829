import React from "react"
import { Helmet } from "react-helmet";
import { useState } from "react";
import { Card, Paper, Stack, Button, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import AddressForm from "../../components/salePoints/forms/AddressForm"
import { SubmitButton } from "../../components/common/forms/submitButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export const NewAddress = () => {


    const history = useHistory()

    const [dataAddress, setDataAddress] = useState({
        address_type: '',
        address_name: '',
        address_number: '',
        address_floor: '',
        address_door: '',
        address_additional: '',
        address_postcode: '',
        address_city: '',
        address_province: '',
        address_country: '',
        address_latitude: '',
        address_longitude: '',
        address_title: '',
        address_description: '',
    });

    const validationSchema = Yup.object().shape({})

    const handleSubmit = (values) => {
        console.log(dataAddress)
    }

    const onChangeField = (event, item, setFieldValue, objectTarget) => {
        const value = event.target.value
        setFieldValue(item, value)
        setDataAddress({ ...dataAddress, [item]: value })
    }



    return (
        <>
            <h3>Nueva Dirección</h3>
            <Helmet>
                <title>Nueva Dirección - Marina BCN</title>
            </Helmet>

            <Formik
                initialValues={{ ...dataAddress }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ handleChange, handleReset, handleBlur, setFieldValue, values, isSubmitting, isValid, touched, errors, resetForm }) => (
                    <Form>
                        <Grid container sx={12} md={8}>
                            <Card sx={{mb:2}}>
                                <Paper>
                                    <AddressForm        //all the rest of Address (ListItems) Fields
                                        values={values}
                                        touched={touched}
                                        errors={errors}
                                        onChangeField={onChangeField}
                                        setFieldValue={setFieldValue}
                                    />
                                </Paper>
                            </Card>
                            <Stack spacing={1} direction="row" justifyContent={"space-between"}>
                                <Stack spacing={1} direction="row">
                                    <Button onClick={() => { history.back() }} variant="outlined" color="primary">
                                        Volver
                                    </Button>
                                </Stack>
                                <SubmitButton
                                    text='Guardar Cambios'
                                    isValid={isValid}
                                    isSubmitting={isSubmitting}
                                >
                                </SubmitButton>
                            </Stack>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </>
    );
}

