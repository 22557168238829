import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'

import { MassiveConsumptionsForm } from '../../components/consumptions/forms/massiveConsumptionsForm'

export default function MassiveConsumptions() {

  const { user } = useSelector(state => state.auth)

  return (
    <>
      <h3>Venta masiva</h3>
      <Helmet>
        <title>Venta masiva - Marina BCN</title>
      </Helmet>

      <Grid>
          <p>
            Selecciona un PDV, una tarjeta y un rango de fechas para procesar todas las ventas
          </p>
          <MassiveConsumptionsForm
            user={user}
          />
      </Grid>
    </>
  )
}
