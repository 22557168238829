import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import distributorsService from "../../../services/distributors.service";

const initialState = {
  distributors: [],
  status: "idle",
  error: null,
};

export const fetchDistributors = createAsyncThunk(
  "distributors/getDistributors",
  async (role) => {
    const response = await distributorsService.getAll({role});
    return response;
  }
);

const distributorsSlice = createSlice({
  name: "distributors",
  initialState,
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDistributors.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchDistributors.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.distributors = action.payload;
      })
      .addCase(fetchDistributors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default distributorsSlice.reducer;

export const selectAllDistributors = (state) => state.distributors.distributors;

