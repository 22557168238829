import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const insert = async ({ point_of_sale, card_number, publication_id, date_edition_from, date_edition_to }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("MASSIVE_CONSUMPTIONS"), {
    method: "POST",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ point_of_sale, card_number, publication_id, date_edition_from, date_edition_to }),
    
  }).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const massiveConsumptionsService = {
  insert
};

export default massiveConsumptionsService;
