import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const getAll = async (props) => {
  const {role} = props
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpointbyRole(role,"DISTRIBUTOR"), {
    method: "GET",
    headers: {
      Authorization: userToken,
    },
  })
    .then((response) => response.json())
    .then((data) => data.distributors)
    .catch((err) => {logout(err)});
}

const distributorsService = {
  getAll,
}

export default distributorsService