import { Autocomplete, Box, FormHelperText, TextField } from "@mui/material";
import { Formik } from "formik";
import { useEffect } from "react";
import { Role } from '../../common/roles/role'
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPointsOfSale,
  selectAllPointsOfSale,
} from "../../common/features/pointsOfSale/pointsOfSaleSlice";
import * as Yup from 'yup'


export const PointsOfSaleFilter = ({ pointOfSale, setPointOfSale }) => {

  const pointsOfSale = useSelector(selectAllPointsOfSale);
  const pointsOfSaleStatus = useSelector((state) => state.pointsOfSale.status);
  const dispatch = useDispatch();

  const { role } = useSelector(
    state => state.auth.user
  )

  useEffect(() => {
    if (role !== Role.Kiosko && pointsOfSaleStatus === 'idle') {
      dispatch(fetchPointsOfSale(role))
    }
  }, [pointsOfSaleStatus, dispatch, role]);

  const handleSelectPointOfSale = (evt) => {
    setPointOfSale(evt.target.value);
  };

  const validationSchema = Yup.object().shape({
    point_of_sale: Yup.string()
  })

  return (
    <Box mb={5}>
      <Formik
        onSubmit={handleSelectPointOfSale}
        validationSchema={validationSchema}
      >
        {( {touched, errors, setFieldValue}) => (
          <Box sx={{ width: '100%', marginBottom: 1 }}>
                <Autocomplete
                  id='point-of-sale'
                  size='small'
                  loading
                  loadingText={"Cargando..."}
                  options={ pointsOfSale }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Punto de venta"
                      error={touched.point_of_sale && Boolean(errors.point_of_sale)}
                    />
                  )}
                  getOptionLabel={(option) => (option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id} >
                        {(option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                      </li>
                    )
                  }}
                  onChange={(event, value) => {
                    setPointOfSale(value?.id ? value.id : '');
                  }}
                  sx={{ maxWidth: 515 }}
                >
                </Autocomplete>
                {(touched.point_of_sale && errors.point_of_sale) && <FormHelperText htmlFor='point-of-sale' error>{errors.point_of_sale}</FormHelperText>}
              </Box>
        )}
      </Formik>
    </Box>
  );
};
