import { Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../../helpers";

export const NewConsumptionTable = ({ services, handleDataSelection }) => {

  const columns = [
    { headerName: "Publicación", title: "name", field: "name", minWidth: 200 },
    { headerName: "Copias", title: "copies", field: "copies", width: 80, align: "right" },
  ];

  return (
    <div style={{ height: 270, width: "100%", marginBottom: 30 }}>
      <DataGrid
        rows={services}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        onSelectionModelChange={handleDataSelection}
        isRowSelectable={(params) => params.row.consumed === 0}
        getRowId={(row) => row.id_service}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No hay resultados
            </Stack>
          )
        }}
      />
    </div>
  );
};
