import Helmet from 'react-helmet'
import { NewSalePointForm } from '../../components/salePoints/forms/NewSalePointForm'

export default function NewSalePoint () {
  return (
    <>
      <h3>Nuevo punto de venta</h3>
      <Helmet>
        <title>Nuevo punto de venta - Marina BCN</title>
      </Helmet>
      <NewSalePointForm></NewSalePointForm>
    </>
  )
}
