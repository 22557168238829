import { Auth } from "@aws-amplify/auth";

export const logout = async (err) => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    console.log("err logout=>" + err)
    window.location.href="/login"
  };