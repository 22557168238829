import { Auth } from 'aws-amplify'
import userService from './user.service'

const confirmSignUp = (username, code, codeTemp) => {
  return Auth.confirmSignUp(username, code, {
    clientMetadata: { codeTemp: codeTemp }
  })
    .then(user => {
      console.log('Confirmed user =>' + user)
      return true
    })
    .catch(err => {
      console.log('error confirming user:', err)
      throw err
    })
}

const signUp = async (
  username,
  first_name,
  last_name_first,
  last_name_second,
  password,
  phone_number,
  email,
  pointOfSale = '',
  role = '',
  editorial = '',
  distribuidor = ''
) => {

    let newUser = {}

    newUser.username = username
    newUser.emailAddress = email
    newUser.firstName = first_name
    newUser.lastNameFirst = last_name_first
    newUser.lastNameSecond = last_name_second
    newUser.password = password
    newUser.phone = phone_number
    newUser.role = role
    newUser.pointOfSale = pointOfSale
    newUser.editorial = editorial
    newUser.distribuidor = distribuidor

    return userService.insert(
      { user: newUser }
    ).then((response) => {
      return response;
    })
    .catch(err => {
      throw err
    })
}

const login = async (email, password, newPassword) => {
  return Auth.signIn(email, password)
    .then(user => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return Auth.completeNewPassword(user, newPassword)
          .then(user => {
            return Auth.signIn(
              user.challengeParam.userAttributes.email,
              newPassword
            ).then(user => {
              console.log(user)
              return JSON.stringify(user)
            })
          })
          .catch(e => {
            console.log(e)
          })
      } else {
        console.log(user)
        return JSON.stringify(user)
      }
    })
    .catch(err => {
      if (
        err.code === 'UserNotFoundException' ||
        err.code === 'NotAuthorizedException'
      ) {
        err.message = 'Correo y/o contraseña inválidos'
      }
      if (err.code === 'UserNotConfirmedException') {
        err.message = 'Usuario pendiente de validación'
      }
      throw err
    })
}

const logout = () => {
  Auth.signOut().then(data => JSON.stringify(data))
}

const sendConfirmationCode = email => {
  return Auth.forgotPassword(email)
    .then(data => console.log(data))
    .catch(err => {
      throw err
    })
}

const setNewPassword = (email, code, new_password) => {
  return Auth.forgotPasswordSubmit(email, code, new_password)
    .then(data => data)
    .catch(err => {
      throw err
    })
}

const getAuthToken = async () => {
  return Auth.currentSession().then(data => data.idToken.jwtToken)
}

const authService = {
  confirmSignUp,
  signUp,
  login,
  logout,
  sendConfirmationCode,
  setNewPassword,
  getAuthToken
}

export default authService
