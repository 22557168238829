 //GET FUNCTION SIMILARITY BETWEEN TWO SENTENCES
export const getSimilarity = (str1, str2) => {
str1 = str1.toLowerCase();
str2 = str2.toLowerCase();
const longer = str1.length > str2.length ? str1 : str2;
const shorter = str1.length > str2.length ? str2 : str1;
const longerLength = longer.length;
if (longerLength === 0) {
    return 1.0;
}
return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
}

//EDIT DISTANCE FUNCTION
export const editDistance = (str1, str2) => {
const distance = [];
for (let i = 0; i <= str1.length; i++) {
    distance[i] = [];
    distance[i][0] = i;
}
for (let j = 0; j <= str2.length; j++) {
    distance[0][j] = j;
}
for (let i = 1; i <= str1.length; i++) {
    for (let j = 1; j <= str2.length; j++) {
    distance[i][j] = Math.min(
        distance[i - 1][j] + 1,
        distance[i][j - 1] + 1,
        distance[i - 1][j - 1] + (str1[i - 1] === str2[j - 1] ? 0 : 1)
    );
    }
}
return distance[str1.length][str2.length];
}