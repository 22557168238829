import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const migrate = async ( pdv_old, pdv_new ) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("MIGRATE_COSTUMERS"), {
    method: "POST",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ pdv_old, pdv_new }),
    
  }).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const migrateCostumersService = {
  migrate
};

export default migrateCostumersService;
