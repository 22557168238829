import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import addressService from "../../../services/address.service";

const initialState = {
  addresses: [],
  status: "idle",
  error: null,
};

export const fetchAddresses = createAsyncThunk(
  "addresses/getAddresses",
  async (props) => {
    const response = await addressService.getAll(props);
    return response;
  }
);

const addressesSlice = createSlice({
  name: "addresses",
  initialState,
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAddresses.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAddresses.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.addresses = action.payload.addresses;
      })
      .addCase(fetchAddresses.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default addressesSlice.reducer;

export const selectAllAddresses = (state) => state.addresses.addresses;

export const selectAddressById = (addressId) => (state) => { return state.addresses.addresses.find((address) => { return address.id === addressId}) }