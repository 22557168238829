import React from "react";
import { useState } from "react";
import { Card, Paper, Box, Stack, Button, List, ListItem, TextField, Autocomplete } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import useModal from "../../../common/hooks/useModal";
import { SubmitButton } from "../../common/forms/submitButton";
import migrateCostumersService from '../../../services/migrateCostumers.service'
import { selectAllPointsOfSale, selectPointOfSaleById } from "../../../common/features/pointsOfSale/pointsOfSaleSlice";

export const MigrateClientsForm = ({ posId, setIsMigrateClients }) => {

  const modal = useModal()
  const originId = parseInt(posId, 10)
  const pointsOfSale = useSelector(selectAllPointsOfSale)

  const originPointOfSale = useSelector(selectPointOfSaleById(originId))
  const [selectedPointOfSale, setSelectedPointOfSale] = useState(null)

  const [dataTransfer, setDataTransfer] = useState({
    id_salePoint_origin: originId,
    id_salePoint_destination: ''
  })

  const { enqueueSnackbar } = useSnackbar()

  const handleSubmit = async (values, setSubmitting) => {

    try {
      await migrateCostumersService.migrate(dataTransfer.id_salePoint_origin, dataTransfer.id_salePoint_destination).then(response => {
        if (response.status === 200) {
          enqueueSnackbar("Los clientes se han copiado y transferido correctamente", {
            variant: 'success'
          })
          setSubmitting(false)
          setIsMigrateClients(false)
        }
        else {
          enqueueSnackbar(response.message, {
            variant: 'error'
          })
          setSubmitting(false)
        }
      })
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error'
      })
    } 
  }

  return (
    <>
      <h3>Migrar clientes</h3>
      <Helmet>
        <title>Migrar clientes - Marina BCN</title>
      </Helmet>

      <Formik
        initialValues={{ ...dataTransfer }}
        onSubmit={(values, {setSubmitting}) => {
          modal.show({
            title: "Migrar Clientes",
            content: <><p>¿Estás seguro que quieres copiar los clientes de {originPointOfSale.name} al punto de venta <strong>{selectedPointOfSale.name}</strong>?</p></>,
            onSubmit: ()=>{handleSubmit(values, setSubmitting)},
          })
        }}
        validateOnMount
      >
        {({ setFieldValue, values, setSubmitting, isSubmitting, isValid, touched, errors }) => (


          <Form>

            <Box sx={{ maxWidth: 450 }}>
              <Card sx={{ mb: 2 }}>

                <Paper sx={{ p: 2 }}>
                  <List>
                    <Stack direction="column" alignItems="center">
                      <ListItem>
                        <Field
                          as={TextField}
                          variant='outlined'
                          margin="none"
                          size="small"
                          value={originPointOfSale.name + ' - ' + originPointOfSale.code_editor}
                          label={"Punto de venta"}
                          helperText="Origen"
                          name={"id_salePoint_origin"}
                          fullWidth
                          disabled
                        />
                      </ListItem>

                      <ArrowDownwardIcon sx={{ mb: 2 }} />

                      <ListItem>
                        <Autocomplete
                          id="id_salePoint_destination"
                          size="small"
                          loading
                          loadingText={"Cargando..."}
                          disableClearable
                          fullWidth
                          options={pointsOfSale}
                          value={selectedPointOfSale ? selectedPointOfSale : null}
                          onChange={(event, value) => {
                            setFieldValue("id_salePoint_destination", value?.id ? value.id : '');
                            setDataTransfer({ ...dataTransfer, id_salePoint_destination: value.id })
                            setSelectedPointOfSale(value)
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                helperText="Destinatario"
                                label="Punto de venta"
                                error={touched.id_salePoint_destination && Boolean(errors.id_salePoint_destination)}
                              />
                            );
                          }}
                          getOptionLabel={(option) => (option?.name && option?.code_editor ? option.name + " - " + option.code_editor : "")}
                          isOptionEqualToValue={(option, value) => (option?.id === value?.id)}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option.id}>
                                {option?.name && option?.code_editor ? option.name + " - " + option.code_editor : ""}
                              </li>
                            );
                          }}
                        >
                        </Autocomplete>

                      </ListItem>
                    </Stack>
                  </List>

                </Paper>
              </Card>

              <Stack spacing={1} direction="row" justifyContent={"space-between"}>

                <Button onClick={() => { setIsMigrateClients(false) }} startIcon={<ArrowBackIcon />}>
                    Volver
                </Button>

                <SubmitButton
                  text='Migrar Clientes'
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  disabled={selectedPointOfSale === null}
                >
                </SubmitButton>
              </Stack>
            </Box>
          </Form>
        )}

      </Formik>
    </>
  );
};

