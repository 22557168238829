import { useState, useEffect } from 'react'
import { Box, CircularProgress, Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import { Close, QrCodeScanner } from '@mui/icons-material'
import { getMobileOS } from '../../../helpers'
import { useSnackbar } from 'notistack'

export default function QRButton({ fieldName, setFieldValue, setSource }) {
  const [isWaitingScanner, setIsWaitingScanner] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const mobileOS = getMobileOS();

  useEffect(() => {
    window.onScannerCallback = ({ cardNumber, error }) => {
      if (error) {
        enqueueSnackbar(
          'Ha ocurrido un error en la lectura del QR de la tarjeta. Introdúzcala manualmente',
          {
            variant: 'error'
          }
        )
      } else {
        setFieldValue(fieldName, cardNumber);
        setSource(2);
        enqueueSnackbar('Número de tarjeta escaneado correctamente', {
          variant: 'success'
        })
      }
      setIsWaitingScanner(false)
    };
    return () => {
      window.onScannerCallback = undefined;
    };
  }, [enqueueSnackbar, fieldName, setFieldValue, setSource]);

  const handleScannerClick = () => {
    if (mobileOS === 'android') {
      console.log('enviamos postmessage de Android')
      setIsWaitingScanner(true)
      window.appCallback?.sendMessage('QRSCAN')
    } else if (mobileOS === 'ios') {
      console.log('enviar postmessage de IOS')
      setIsWaitingScanner(true)
      window.webkit.messageHandlers.appCallback.postMessage('QRSCAN')
    }
  };

  const handleModalClose = () => {
    setIsWaitingScanner(false);
  };

  if (mobileOS === 'unknown') {
    return null;
  }

  return (
    <>
      <IconButton
        aria-label='QR scan'
        edge='end'
        onClick={handleScannerClick}
      >
        <QrCodeScanner />
      </IconButton>
      <Dialog
        fullScreen
        open={isWaitingScanner}
        onClose={handleModalClose}
      >
        <Box sx={{ textAlign: 'right', p: 1 }}>
          <IconButton onClick={handleModalClose}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <CircularProgress size={24} />
          <Typography sx={{ my: 2 }}>Esperando escaneo...</Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
