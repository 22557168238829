import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Field, Form, Formik } from "formik";
import { SubmitButton } from "../../../../components/common/forms/submitButton";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import userService from "../../../../services/user.service";

const initialFormValues = {
  code: "",
  password: "",
  confirm_password: "",
};

const schema = Yup.object().shape({
  code: Yup.string()
    .min(6)
    .required("El código de activación no puede estar acío"),
  password: Yup.string().min(8).required("La contraseña no puede estar vacía"),
  confirm_password: Yup.string()
    .min(8)
    .required("La contraseña no puede estar vacía")
    .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir"),
});

const schemaPlusUsername = Yup.object().shape({
  code: Yup.string()
    .min(6)
    .required("El código de activación no puede estar acío"),
  username: Yup.string()
    .email('Formato de correo inválido')
    .required('El campo "email" es obligatorio'),
  password: Yup.string().min(8).required("La contraseña no puede estar vacía"),
  confirm_password: Yup.string()
    .min(8)
    .required("La contraseña no puede estar vacía")
    .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir"),
});

export const ForgotPasswordSubmitForm = ({ username, code }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  if (code) initialFormValues.code=code
  else initialFormValues.code="";
  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const { code, password } = values
      let user = {}
      if (username == null) {
        user.emailAddress = values.username
      } else{
        user.emailAddress = username
      }
      user.code = code
      user.password = password

      userService
      .forgotPassword({ user:user })
      .then((response) => {
        if (response.status !== 200) {
          setSubmitting(false);
          enqueueSnackbar(response.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          setSubmitting(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      });

      history.push("/login");
    } catch (err) {
      setSubmitting(false);
      setErrors({ password: err.message });
    }
  };

  if (username == null) {
    return (
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={schemaPlusUsername}
      >
        {({ handleChange, isSubmitting, values, isValid, touched, errors }) => (
          <Box
            component={Form}
            sx={{
              "& .MuiTextField-root": {
                marginTop: 1,
                marginBottom: 1,
                width: "100",
              },
              "& .MuiTextField-root:last-of-type": { marginBottom: 2 },
              "& .MuiTextField-root:first-of-type": { marginTop: 0 },
            }}
            autoComplete="off"
          >
            <Field
              as={TextField}
              type="email"
              name="username"
              label="Email"
              onChange={handleChange}
              value={values.username}
              fullWidth
              autoComplete="on"
              size="small"
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
            />
            <Field
              as={TextField}
              type="text"
              name="code"
              label="Código de activación"
              onChange={handleChange}
              value={values.code}
              fullWidth
              size="small"
              error={touched.code && Boolean(errors.code)}
              helperText={touched.code && errors.code}
            />
            <Field
              as={TextField}
              type={showPassword ? "text" : "password"}
              name="password"
              label="Contraseña"
              onChange={handleChange}
              value={values.password}
              fullWidth
              size="small"
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              as={TextField}
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              label="Confirma contraseña"
              onChange={handleChange}
              value={values.confirm_password}
              fullWidth
              size="small"
              error={touched.confirm_password && Boolean(errors.confirm_password)}
              helperText={touched.confirm_password && errors.confirm_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <SubmitButton
              text="Aceptar"
              isValid={isValid}
              isSubmitting={isSubmitting}
            />
          </Box>
        )}
      </Formik>
    );
  } else {
    return (
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ handleChange, isSubmitting, values, isValid, touched, errors }) => (
          <Box
            component={Form}
            sx={{
              "& .MuiTextField-root": {
                marginTop: 1,
                marginBottom: 1,
                width: "100",
              },
              "& .MuiTextField-root:last-of-type": { marginBottom: 2 },
              "& .MuiTextField-root:first-of-type": { marginTop: 0 },
            }}
            autoComplete="off"
          >
            <Field
              as={TextField}
              type="text"
              name="code"
              label="Código de activación"
              onChange={handleChange}
              value={values.code}
              fullWidth
              size="small"
              error={touched.code && Boolean(errors.code)}
              helperText={touched.code && errors.code}
            />
            <Field
              as={TextField}
              type={showPassword ? "text" : "password"}
              name="password"
              label="Contraseña"
              onChange={handleChange}
              value={values.password}
              fullWidth
              size="small"
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Field
              as={TextField}
              type={showConfirmPassword ? "text" : "password"}
              name="confirm_password"
              label="Confirma contraseña"
              onChange={handleChange}
              value={values.confirm_password}
              fullWidth
              size="small"
              error={touched.confirm_password && Boolean(errors.confirm_password)}
              helperText={touched.confirm_password && errors.confirm_password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={(event) => event.preventDefault()}
                      edge="end"
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <SubmitButton
              text="Aceptar"
              isValid={isValid}
              isSubmitting={isSubmitting}
            />
          </Box>
        )}
      </Formik>
    );
  }


};
