import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Autocomplete, Grid, Card, Paper, List, ListItem, CardHeader, CardContent, Avatar, Stack, Button } from '@mui/material'
import { Box } from '@mui/system'
import { Field, Form, Formik } from 'formik'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import * as Yup from 'yup'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { SubmitButton } from '../../common/forms/submitButton'
import { Role } from '../../../common/roles/role'
import { signUp } from '../../../common/features/auth/authSlice'
import configEditorial from "../../../config/editorial.json";
import configDistribuidor from "../../../config/distributor.json";
import { formatDateFromUTC } from '../../utils/FormatDateFromUTC'

const initialValues = {
  role: '',
  email: '',
  first_name: '',
  last_name_first: '',
  last_name_second: '',
  phone_number: '',
  point_of_sale: '',
  editorial: '',
  distribuidor: ''
}

const schema = Yup.object().shape({
  role: Yup.string().required('El campo "Tipo de usuario" es requerido'),
  point_of_sale: Yup.string().when('role', {
    is: (role) => { return (role === Role.Kiosko) },
    then: Yup.string().required(
      'El campo "Punto de venta" es requerido para usuarios Kiosko'
    ),
    otherwise: Yup.string()
  }),
  distribuidor: Yup.string().when('role', {
    is: (role) => { return (role === Role.Distribuidor) },
    then: Yup.string().required(
      'El campo "Distribuidor" es requerido para usuarios Distribuidor'
    ),
    otherwise: Yup.string()
  }),
  editorial: Yup.string().when('role', {
    is: (role) => { return (role === Role.Consulta || role === Role.Editor) },
    then: Yup.string().required(
      'El campo "Editorial" es requerido para usuarios Editor/Consulta'
    ),
    otherwise: Yup.string()
  }),
  first_name: Yup.string().required('El campo "Nombre" es requerido'),
  last_name_first: Yup.string().required('El campo "Primer apellido" es requerido'),
  last_name_second: Yup.string().required('El campo "Segundo apellido" es requerido'),
  email: Yup.string()
    .email('Formato de correo inválido')
    .required('El campo "email" es requerido'),
  phone_number: Yup.string()
    .required('El campo "teléfono" es requerido')
    .matches(/^\+(34|376)\d{6,14}$/, "El prefijo (+34) o (+376) es requerido")
    .typeError('Teléfono no válido')
})

export const NewUserForm = ({ pointsOfSale }) => {

  const [formValues, setFormValues] = useState(initialValues)

  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const dispatch = useDispatch()

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let password = Math.random()
        .toString(36)
        .slice(-8)
      const {
        email,
        first_name,
        last_name_first,
        last_name_second,
        point_of_sale,
        role,
        phone_number,
        editorial,
        distribuidor
      } = values

      console.log(values)
      await dispatch(
        signUp({
          username: email,
          first_name,
          last_name_first,
          last_name_second,
          password,
          phone_number: phone_number,
          email,
          pointOfSale: point_of_sale,
          role,
          editorial,
          distribuidor
        })
      ).unwrap()
        .then(response => {
          if (response.status === 200) {
            enqueueSnackbar('Usuario creado correctamente', {
              variant: 'success'
            })
          }
          else {
            enqueueSnackbar(response.message, {
              variant: 'error'
            })
          }
        })
      setSubmitting(false)
      history.push('/users')
    } catch (err) {
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
      resetForm({})
      setSubmitting(false)
    }
  }

  const [editorialField, setEditorialField] = useState(false);
  const [distribuidorField, setDistribuidorField] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState('');

  const onChangeRole = (event, setFieldValue, values) => {
    const { value } = event.target
    setFieldValue("role", value)
    setEditorialField(value === 'editor-admin' || value === 'editor-consulta')
    setDistribuidorField(value === 'distribuidor')
    setSelectedUserRole(value)
    setFormValues({ ...values, point_of_sale: "", editorial: "", distribuidor: "", role: value })
    setFieldValue("editorial", '')
    setFieldValue("distribuidor", '')
  }

  const editorialsByRole = configEditorial[process.env.REACT_APP_ENVIRONMENT]['Editor']

  const editorialList = Object.keys(editorialsByRole).map(editorial => (
    <MenuItem key={editorial} value={editorial}>{editorialsByRole[editorial]}</MenuItem>
  ));

  const distribuidorByRole = configDistribuidor[process.env.REACT_APP_ENVIRONMENT]

  const distribuidorList = Object.keys(distribuidorByRole).map(distribuidor => (
    <MenuItem key={distribuidor} value={distribuidor}>{distribuidorByRole[distribuidor]}</MenuItem>
  ));

  const rolesList = Object.keys(Role).map(role => (
    <MenuItem key={Role[role]} value={Role[role]}>{role}</MenuItem>
  ));


  return (

    <Formik
      initialValues={formValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      validateOnChange={true}  // Activa o Desactiva la validación al cambiar los valores de los campos
      validateOnBlur={true}
    >
      {({ handleChange, handleBlur, setFieldValue, validateForm, values, isSubmitting, isValid, touched, errors }) => (
        <Box sx={{ maxWidth: 450 }}>
          <Form>
            <Card sx={{ mb: 2 }}>
              <Paper>
                <CardHeader
                  sx={{ pt: 3, pb: 0, '& .MuiCardHeader-action': { my: 0, mx: 2 } }}
                  avatar={
                    <Avatar sx={{ width: 42, height: 42, ml: 2 }} aria-label="user">
                    </Avatar>
                  }
                  title={values.first_name + " " + values.last_name_first + " " + values.last_name_second}
                  subheader={formatDateFromUTC(new Date())}
                >
                </CardHeader>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <List dense={true} sx={{ pb: 0 }}>
                        <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
                          <FormControl fullWidth>
                            <InputLabel id='role-label' error={touched.role && Boolean(errors.role)}>Tipo de usuario</InputLabel>
                            <Field
                              component={Select}
                              labelId='role-label'
                              id='role'
                              name='role'
                              label='Tipo de usuario'
                              value={values.role}
                              onChange={(event) => onChangeRole(event, setFieldValue, validateForm, values)}
                              error={touched.role && Boolean(errors.role)}
                              size='small'
                            >
                              {rolesList}
                            </Field>
                            {(touched.role) && <FormHelperText htmlFor='editorial' error>{errors.role}</FormHelperText>}
                          </FormControl>
                        </ListItem>
                        {editorialField && (
                          <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
                            <FormControl fullWidth>
                              <InputLabel id='editorial-label' error={touched.editorial && Boolean(errors.editorial)}>Editorial</InputLabel>
                              <Field
                                component={Select}
                                labelId='editorial-label'
                                id='editorial'
                                name='editorial'
                                label='Editorial'
                                value={values.editorial}
                                onChange={handleChange("editorial")}
                                error={touched.editorial && Boolean(errors.editorial)}
                                size='small'
                              >
                                {editorialList}
                              </Field>
                              {(touched.editorial) && <FormHelperText htmlFor='editorial' error>{errors.editorial}</FormHelperText>}
                            </FormControl>
                          </ListItem>
                        )}

                        {distribuidorField && (
                          <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
                            <FormControl fullWidth>
                              <InputLabel id='distribuidor-label' error={Boolean(touched.distribuidor && errors.distribuidor)}>Distribuidor</InputLabel>
                              <Field
                                component={Select}
                                labelId='distribuidor-label'
                                id='distribuidor'
                                name='distribuidor'
                                label='Distribuidor'
                                value={values.distribuidor}
                                onChange={handleChange("distribuidor")}
                                error={touched.distribuidor && Boolean(errors.distribuidor)}
                                size='small'
                              >
                                {distribuidorList}
                              </Field>
                              {touched.distribuidor && errors.distribuidor && (
                                <FormHelperText htmlFor='distribuidor' error>{errors.distribuidor}</FormHelperText>
                              )}
                            </FormControl>
                          </ListItem>
                        )}

                        {selectedUserRole === Role.Kiosko && (
                          <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
                            <FormControl fullWidth>
                              <Autocomplete
                                id='point-of-sale'
                                size='small'
                                loading
                                loadingText={"Cargando..."}
                                fullWidth
                                options={pointsOfSale}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Punto de venta"
                                    error={touched.point_of_sale && Boolean(errors.point_of_sale)}
                                  />
                                )}
                                getOptionLabel={(option) => (option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderOption={(props, option) => {
                                  return (
                                    <li {...props} key={option.id} >
                                      {(option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                                    </li>
                                  )
                                }}
                                onChange={(event, value) => {
                                  setFieldValue("point_of_sale", value?.id ? value.id : '');
                                }}
                              >
                              </Autocomplete>
                              {(touched.point_of_sale) && <FormHelperText htmlFor='point_of_sale' error>{errors.point_of_sale}</FormHelperText>}
                            </FormControl>
                          </ListItem>
                        )}
                        <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
                          <FormControl fullWidth>
                            <Field
                              as={TextField}
                              type='email'
                              name='email'
                              label='Email'
                              onChange={handleChange("email")}
                              value={values.email}
                              autoComplete='on'
                              size='small'
                              error={touched.email && Boolean(errors.email)}
                            />
                            {(touched.email) && <FormHelperText htmlFor='email' error>{errors.email}</FormHelperText>}
                          </FormControl>
                        </ListItem>
                        <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
                          <FormControl fullWidth>
                            <Field
                              as={TextField}
                              type='string'
                              name='first_name'
                              label='Nombre'
                              onChange={handleChange("first_name")}
                              value={values.first_name}
                              autoComplete='on'
                              size='small'
                              error={touched.first_name && Boolean(errors.first_name)}
                            />
                            {(touched.first_name) && <FormHelperText htmlFor='first_name' error>{errors.first_name}</FormHelperText>}
                          </FormControl>
                        </ListItem>
                        <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
                          <FormControl fullWidth>
                            <Field
                              as={TextField}
                              type='string'
                              name='last_name_first'
                              label='Primer apellido'
                              onChange={handleChange("last_name_first")}
                              value={values.last_name_first}
                              autoComplete='on'
                              size='small'
                              error={touched.last_name_first && Boolean(errors.last_name_first)}
                            />
                            {(touched.last_name_first) && <FormHelperText htmlFor='last_name_first' error>{errors.last_name_first}</FormHelperText>}
                          </FormControl>
                        </ListItem>
                        <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
                          <FormControl fullWidth>
                            <Field
                              as={TextField}
                              type='string'
                              name='last_name_second'
                              label='Segundo apellido'
                              onChange={handleChange("last_name_second")}
                              value={values.last_name_second}
                              autoComplete='on'
                              size='small'
                              error={touched.last_name_second && Boolean(errors.last_name_second)}
                            />
                            {(touched.last_name_second) && <FormHelperText htmlFor='last_name_second' error>{errors.last_name_second}</FormHelperText>}
                          </FormControl>
                        </ListItem>
                        <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
                          <FormControl fullWidth>
                            <Field
                              as={TextField}
                              type='tel'
                              name='phone_number'
                              label='Teléfono'
                              onChange={handleChange("phone_number")}
                              value={values.phone_number}
                              autoComplete='on'
                              size='small'
                              error={touched.phone_number && Boolean(errors.phone_number)}
                            />
                            {(touched.phone_number) && <FormHelperText htmlFor='phone_number' error>{errors.phone_number}</FormHelperText>}
                          </FormControl>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
              </Paper>
            </Card>
            <Stack spacing={1} direction="row">
              <Button onClick={() => { history.push('/users') }} startIcon={<ArrowBackIcon />}>
                  Volver
              </Button>
              <SubmitButton
                text='Guardar'
                isValid={isValid}
                isSubmitting={isSubmitting}
              />
            </Stack>
          </Form>
        </Box>
      )}
    </Formik>
  )
}
