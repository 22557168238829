import { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import useModal from '../../common/hooks/useModal'
import { Role } from '../../common/roles/role'
import { NewConsumptionForm } from '../../components/consumptions/forms/newConsumptionForm'
import { Button } from '@mui/material'
import consumptionsService from '../../services/consumptions.service'
import { useDispatch, useSelector } from 'react-redux'
import { NewConsumptionTable } from '../../components/consumptions/NewConsumptionTable'
import { ClientAlias } from '../../components/clients/ClientAlias'
import { SubmitButton } from '../../components/common/forms/submitButton'
import Helmet from 'react-helmet'
import {
  fetchClients,
  selectAllClients,
  setClients
} from './../../common/features/clients/clientsSlice';

export default function NewConsumption () {
  const { user } = useSelector(state => state.auth)
  const { role } = user
  const modal = useModal()
  const handleSaveClient = () => {
    history.push({
      pathname: '/clients/new',
      state: { cardNumber: cardNum }
    })
  }

  const dispatch = useDispatch();
  const history = useHistory()
  const historyStateNumber = history.location.state?.cardNumber

  const [source, setSource] = useState((historyStateNumber!==undefined)? 3: 1) //1: manual, 2: QR, 3: mis clientes, 4: massiva

  const currentRouterMatch = useRouteMatch();
  const isDelayedConsumption = currentRouterMatch?.path === '/consumptions/new-delayed';

  const [cardNum, setCardNum] = useState(historyStateNumber || '')
  const [services, setServices] = useState([])
  const [selection, setSelection] = useState([])
  const [consumptions, setConsumptions] = useState([])
  const pointOfSale = useSelector(state => state.pointsOfSale.current)
  const isDisabled = selection && selection.length > 0 ? false : true
  const [loadingSaveConsumption, setLoadingSaveConsumption] = useState(false)
  const [enableClientList, setEnableClientList] = useState(!historyStateNumber)


  const clients = useSelector(selectAllClients);
  const clientsStatus = useSelector((state) => state.clients.status);
  const clientsPendingConsumption = useSelector((state) => state.clients.clients?.filter(client => client.consumed === 0))

  useEffect(() => {
    if (Role.Kiosko.includes(role) && clientsStatus === "idle") {
      dispatch(fetchClients());
    }
  }, [clientsStatus, dispatch, role]);

  useEffect(() => {
    if (historyStateNumber) {
      history.replace({ state: {} })
      setEnableClientList(false)
    }
  }, [history, historyStateNumber])


  const handleDataSelection = newSelection => {
    setSelection(newSelection)
    let consum = services.filter(service =>
      newSelection.includes(service.id_service)
    )
    consum.forEach(consumption => {
      consumption.id_pointsale = pointOfSale
      consumption.create_user = user.email
    })
    setConsumptions(consum)
  }

  const handleSaveConsumption = async () => {
    setLoadingSaveConsumption(true)
    consumptionsService
      .insert({
        day : isDelayedConsumption === false? true: false,
        consumptions: consumptions,
        source: source
      }, role)
      .then(response => {
        if (response.status === 200) {
          disableConsumedServices()
          setLoadingSaveConsumption(false)
          modal.show({
            content: response.message,
            variant: 'success',
            secondaryBtnText: user.role === Role.Kiosko ? 'Guardar cliente' : '',
            secondaryBtnHandler: user.role === Role.Kiosko ? handleSaveClient : undefined,
            onSubmit: () => { 
              setServices([])
              updateClients(cardNum) // Refresh Redux state with clients updated 
              setCardNum('') // Clean form field 'cardNum'
            }
          })
        } else {
          setLoadingSaveConsumption(false)
          modal.show({
            content: response.message,
            variant: 'error'
          })
        }
      })
      .catch(err => {
        setLoadingSaveConsumption(false)
        modal.show({
          content: err.message,
          variant: 'error'
        })
      })
  }

  const disableConsumedServices = () => {
    let updatedServices = services.map(service => {
      if (selection.includes(service.id_service)) {
        return { ...service, consumed: 1 }
      } else {
        return { ...service }
      }
    })
    setServices(updatedServices)
    setSelection([])
  }

  const updateClients = (cardNum) => {
    const updatedClients = clients.map(client => {
      if (client.card === cardNum) {
        return {
          ...client,
          consumed: 1
        };
      } else {
        return client;
      }
    });
  
    dispatch(setClients(updatedClients)); // Refresh Redux clients state (setClients action)
    return updatedClients;
  }
  

  return (
    <>
      <h3>Venta { isDelayedConsumption && ' atrasada' }</h3>
      <Helmet>
        <title>Venta - Marina BCN</title>
      </Helmet>

      {!services.length ? (
        <>
          {!enableClientList && (
            <ClientAlias cardNum={cardNum} />
          )}
          <p>
            Introduce una tarjeta o selecciona un cliente guardado para procesar una venta
          </p>
          <NewConsumptionForm
            cardNum={cardNum}
            setCardNum={setCardNum}
            setServices={setServices}
            disableDateField={!isDelayedConsumption}
            enableClientList={enableClientList}
            setSource={setSource}
            source={source}
            isDelayedConsumption={isDelayedConsumption}
            clients={clients}
            clientsPendingConsumption={clientsPendingConsumption}
          />
        </>
      ) : (
        <>
          <ClientAlias cardNum={cardNum} />
          <NewConsumptionTable
            services={services}
            handleDataSelection={handleDataSelection}
          />
          <SubmitButton
            text='Confirmar'
            onClick={handleSaveConsumption}
            variant='contained'
            color='secondary'
            isValid={!isDisabled}
            isSubmitting={loadingSaveConsumption}
          />
          <Button sx={{ml: 1}} variant='outlined' color='primary' onClick={ () => setServices([]) }>Cancelar</Button>
        </>
      )}
    </>
  )
}
