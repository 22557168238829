import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { TextField } from "@mui/material";
import { es } from "date-fns/locale";
import { useState } from "react";

export default function DatePicker({value, fieldName, label, name, setFieldValue, maxDate, minDate, views = ['day', 'month', 'year'], customHelperText, customError, customOnChange, disabled}) {
  
  const [dateValue, setDateValue] = useState(value);

  const handleChange = (dateValue) => {
    setDateValue(dateValue)
    setFieldValue(fieldName, dateValue);
    customOnChange(fieldName, dateValue); // handle custom from parent component 
  };

  return (
    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        label={label}
        inputFormat="dd/MM/yyyy"
        value={dateValue}
        onChange={handleChange}
        maxDate={maxDate}
        minDate={minDate}
        views={views}
        disabled={disabled}
        renderInput={(params) => (
          <TextField {...params} name={name} size="small" fullWidth helperText={customHelperText} error={customError} />
          )}
      />
    </LocalizationProvider>
  );
}
