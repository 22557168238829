import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import pointsOfSaleService from "../../services/pointsOfSale.service";
import { EditSalePointForm } from "../../components/salePoints/forms/EditSalePointForm";
import { MigrateClientsForm } from "../../components/salePoints/forms/MigrateClientsForm";


export const SalePoint = ({posId, setShowPos, addresses, distributors, setIsAction}) => {


  const [allData, setAllData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isMigrateClients, setIsMigrateClients] = useState(false);

  const showMigrateClients = () => {
    setIsMigrateClients(true)
  }

  useEffect(() => {
    async function fetchAllDataInfo() {
      const response = await pointsOfSaleService.getById(posId)
      setAllData(response.pointOfSale)
      setIsLoading(false)
    }
    fetchAllDataInfo()
  }, [posId])
    

  if (isLoading) {
    return (
      <CircularProgress size={24} color="secondary"></CircularProgress>
    )
  } else {
    <div>Error</div>
  }

  if (isMigrateClients) {
    return (
      <MigrateClientsForm posId={posId} setIsMigrateClients={setIsMigrateClients}></MigrateClientsForm>
    )
  } else {
    return (
      <EditSalePointForm allData={allData} addresses={addresses} distributors={distributors} showMigrateClients={showMigrateClients} setIsLoading={setIsLoading} setShowPos={setShowPos} setIsAction={setIsAction}></EditSalePointForm>
    )
  }
}