import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const getAll = async (props) => {
  const {id_pointSale, id_distributor, address_full, role} = props
  const userToken = await authService.getAuthToken()

  return fetch(apiService.getEndpointbyRole(role, "MAPMARKER") +
  ( id_pointSale || id_distributor || address_full? `?` : '' ) +
  ( id_pointSale ? `&id=${id_pointSale}` : '' ) +
  ( id_distributor ? `&id_distributor=${id_distributor}` : '' ) +
  ( address_full ? `&address_full=${address_full}` : '' ) , {

    method: "GET",
    headers: {
      Authorization: userToken,
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => {
      logout(err)
    });
}

const mapMarkerService = {
  getAll
}

export default mapMarkerService