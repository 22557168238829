import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { formatDateFromUTC } from "../utils/FormatDateFromUTC";
import configPublication from "../../config/publication.json"

export const StepperSummary = ({
  reportDataToSend
}) => {

  const { user } = useSelector((state) => state.auth);
  const { email } = user;

  const publicationsSummaryList = () => {
    const listNames = []
    const listConfig = Object.keys(configPublication[1])

    reportDataToSend.publication_id.forEach( id => {
      listConfig.forEach(pub =>{
        if(pub === id){
          listNames.push(configPublication[1][pub]["label"])
        }
      })
    });
    return(listNames.join(", "))
     
  }

   return (
    <Box sx={{mb:3}}>
      <Typography variant="body2" gutterBottom>En breve recibirás un correo electrónico a <i><b>{email}</b></i> con el informe generado:</Typography>
      <p></p>
      <Typography variant="body2" gutterBottom style={{ marginLeft: '10px' }}>Nombre del informe: <i>{reportDataToSend.report_title}</i></Typography>
      <Typography variant="body2" gutterBottom style={{ marginLeft: '10px' }}>Tipo: {reportDataToSend.report_type}</Typography>
      <p></p>
      <Typography variant="body2" gutterBottom>Filtros seleccionados:</Typography>
      <p></p>
      <ul>
        {reportDataToSend.point_of_sale !== ''?(
            <li>
            <Typography variant="body2" gutterBottom>
              Punto de venta: {reportDataToSend.point_of_sale}
            </Typography>
          </li>
        ):('')}
        {reportDataToSend.type_of_sale !== ''?(
          <li>
            <Typography variant="body2" gutterBottom>
              Tipo de venta: {reportDataToSend.type_of_sale}
            </Typography>
          </li>
        ):('')}
        {reportDataToSend.distributor_id !== ''?(
          <li>
            <Typography variant="body2" gutterBottom>
              Distribuidor: {reportDataToSend.distributor_id}
            </Typography>
          </li>
        ):('')}
        {reportDataToSend.publication_id !== ''?(
          <li>
            <Typography variant="body2" gutterBottom>
              Publicaciones: {publicationsSummaryList()}
            </Typography>
          </li>
        ):('')}
        {reportDataToSend.user_status !== ''?(
          <li>
            <Typography variant="body2" gutterBottom>
              Estado de usuario: {reportDataToSend.user_status}
            </Typography>
          </li>
        ):('')}
        {reportDataToSend.date_from !== ''?(
          <li>
            <Typography variant="body2" gutterBottom>
              Fechas de consumos desde {formatDateFromUTC(reportDataToSend.date_from)} hasta {formatDateFromUTC(reportDataToSend.date_to)}
            </Typography>
          </li>
        ):('')}
        {reportDataToSend.date_edition_from !== ''?(
          <li>
            <Typography variant="body2" gutterBottom>
              Fechas de edición desde {formatDateFromUTC(reportDataToSend.date_edition_from)} hasta {formatDateFromUTC(reportDataToSend.date_edition_to)}
            </Typography>
          </li>
        ):('')}
        {reportDataToSend.date_ending_from !== ''?(
          <li>
            <Typography variant="body2" gutterBottom>
              Fechas de finalización desde {formatDateFromUTC(reportDataToSend.date_ending_from)} hasta {formatDateFromUTC(reportDataToSend.date_ending_to)}
            </Typography>
          </li>
        ):('')}
        {reportDataToSend.subscription_number !== ''?(
          <li>
            <Typography variant="body2" gutterBottom>
              Numero de subscriptor: {reportDataToSend.subscription_number}
            </Typography>
          </li>
        ):('')}
        {reportDataToSend.card_number !== ''?(
          <li>
            <Typography variant="body2" gutterBottom>
              Numero de tarjeta: {reportDataToSend.card_number}
            </Typography>
          </li>
        ):('')}
      </ul>
  </Box>
  );
};
