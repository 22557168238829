import { useEffect, useState } from 'react'
import { Card, Stack, Typography, Box, CircularProgress } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Helmet from 'react-helmet'

import { useSelector, useDispatch } from 'react-redux'
import { SalePointFilters } from '../../components/salePoints/SalePointFilters'
import { SalePointTable } from '../../components/salePoints/SalePointTable'
import pointsOfSaleService from '../../services/pointsOfSale.service'
import mapMarkerService from '../../services/mapMarker.service'
import { SalePointMap } from '../../components/maps/SalePointMap'
import { SalePoint } from '../salePoints/salePoint'
import { withIdentityPoolId } from "@aws/amazon-location-utilities-auth-helper";
import { fetchAddresses, selectAllAddresses } from "../../common/features/addresses/addressesSlice";
import { fetchDistributors, selectAllDistributors } from '../../common/features/distributors/distributorsSlice';
import { selectAllPointsOfSale, fetchPointsOfSale } from "../../common/features/pointsOfSale/pointsOfSaleSlice"
import { Role } from "../../common/roles/role"

export default function SalePoints() {

  const dispatch = useDispatch()
  
  const { role } = useSelector(
    state => state.auth.user
  )

     //Load pointsOfSale Redux
     const pointsOfSale = useSelector(selectAllPointsOfSale)
     const pointsOfSaleStatus = useSelector(state => state.pointsOfSale.status)
 
 
     useEffect(() => {
         if (role !== Role.Kiosko && pointsOfSaleStatus === 'idle') {
             dispatch(fetchPointsOfSale(role))
         }
     }, [pointsOfSaleStatus, role, dispatch])

  //Load distributors Redux
    const distributors = useSelector(selectAllDistributors)
    const distributorStatus = useSelector(state => state.distributors.status)
  
    useEffect(() => {
        if (distributorStatus === 'idle') {
          dispatch(fetchDistributors(role))
        }
    }, [distributorStatus, dispatch])

  //Load Addresses Redux
  const addresses = useSelector(selectAllAddresses)
  const addressesStatus = useSelector(state => state.addresses.status)

  useEffect(() => {
    if (addressesStatus === 'idle') {
      dispatch(fetchAddresses({address:'',role}))
    }
  }, [dispatch, addressesStatus])

  
  // ready Identity Pool for map
  const identityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID
  const [authHelper, setAuthHelper] = useState("");
  const [isIdPool, setIsIdPool] = useState(false)

  useEffect(() => {
    const getAuthHelper = async () => {
      let result = await withIdentityPoolId(identityPoolId);
      setAuthHelper(result);
      setIsIdPool(true);
    };
  
    // Fetching Data on Initial Load
    getAuthHelper();
  }, [identityPoolId]); 


  const [selectedPosId, setSelectedPosId] = useState('')  // keep selected's SalePoint's state (id)
  const [selectedSalePoint, setSelectedSalePoint] = useState('') // keep selected's SalePoint's state (object)
  
  const [isAction, setIsAction] = useState(false) // to refresh parent comp when POS is edited, deleted, etc
  const [showMap, setShowMap] = useState(false)
  const [showPos,setShowPos] = useState(false);
  const [mapLoading, setMapLoading] = useState(false)

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 6,
    total: 0,
    rows: [],
    loading: false,
    id_pointSale: '',
    id_distributor: '',
    address_full: '',
    markers: []
  })

  const handleShowMap = () => {
    setShowMap(!showMap)
    setMapLoading(!mapLoading)
  }

  useEffect(() => {

    (async () => {
      setRowsState(prev => ({ ...prev, loading: true }))

      if (!showMap || isAction) {
        const results = await pointsOfSaleService.getAll({
          per_page: rowsState.pageSize,
          page: rowsState.page + 1,
          id_pointSale: rowsState.id_pointSale,
          id_distributor: rowsState.id_distributor,
          address_full: rowsState.address_full,
          role
        }).catch(e => console.log(e))

        setRowsState(prev => ({
          ...prev,
          loading: false,
          total: results?.total || [],
          rows: results?.pointsOfSale || [],
        }))

      } else {

        const mapMarkers = await mapMarkerService.getAll({
          id_pointSale: rowsState.id_pointSale,
          id_distributor: rowsState.id_distributor,
          address_full: rowsState.address_full,
          role
        }).catch(e => console.log(e))

        setRowsState(prev => ({
          ...prev,
          loading: false,
          markers: mapMarkers?.mapmarkers || []
        }))
      }
      setIsActiveLockers(false) // reset Active Lockers filter (miniSwitch)
      setMapLoading(false)
      setIsAction(false)

    })()

  }, [rowsState.page, rowsState.pageSize, rowsState.id_pointSale, rowsState.id_distributor, rowsState.address_full, role, showMap, isAction])


  const [isActiveLockers, setIsActiveLockers] = useState(false);

  const handleLockers = () => {
    setIsActiveLockers(!isActiveLockers)
  }

if (!showPos){
    return (
      <>
        <h3>Puntos de venta</h3>
        <Helmet>
          <title>Puntos de venta - Marina BCN</title>
        </Helmet>

        <SalePointFilters rowsState={rowsState} setRowsState={setRowsState} showMap={showMap} setShowMap={setShowMap} handleShowMap={handleShowMap} distributors={distributors} role={role} pointsOfSale={pointsOfSale} selectedSalePoint={selectedSalePoint} setSelectedSalePoint={setSelectedSalePoint}/>

        {showMap ?
          <Card variant="outlined" sx={{ height: 430, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', '& details.maplibregl-ctrl.maplibregl-ctrl-attrib': { display: 'none' } }}>
            {mapLoading && isIdPool ?
              <Box sx={{ position: 'absolute', top: '50%', left: '50%' }}>
                <CircularProgress color='secondary'></CircularProgress>
              </Box>
              :
              (rowsState.markers?.features.length > 0 ?
                <SalePointMap rowsState={rowsState} setRowsState={setRowsState} authHelper={authHelper} handleLockers={handleLockers} isActiveLockers={isActiveLockers} setSelectedPosId={setSelectedPosId} setShowPos={setShowPos}/>
                :
                <Stack direction='column' alignItems='center' sx={{ p: 2 }}>
                  <Typography sx={{ pb: 2 }}>No hay resultados</Typography>
                  <Stack direction="row" alignItems='center'>
                    <InfoOutlinedIcon size="small" />
                    <Typography sx={{ px: 1, lineHeight: 'inherit' }} variant='body2'>El mapa solo muestra puntos de venta en estado 'Alta' y con datos de geolocalización</Typography>
                  </Stack>
                </Stack>
              )

            }
          </Card>
          :
          <SalePointTable rowsState={rowsState} setRowsState={setRowsState} setSelectedPosId={setSelectedPosId} setShowPos={setShowPos} setIsAction={setIsAction} />
        }
      </>
    )
  }else{
    return(
      <SalePoint
        posId={selectedPosId}
        setShowPos={setShowPos}
        addresses={addresses}
        distributors={distributors}
        setIsAction={setIsAction}
      ></SalePoint>
    )
  }
}

