import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const getAll = async (props) => {
  const {page, per_page, id_pointSale, id_distributor, address_full, role} = props
  const userToken = await authService.getAuthToken()

  return fetch(apiService.getEndpointbyRole(role, "POINTOFSALE") +
  ( per_page ? `?&per_page=${per_page}` : '' ) + ( page ? `&page=${page}` : '' ) +
  ( id_pointSale ? `&id=${id_pointSale}` : '' ) +
  ( id_distributor ? `&id_distributor=${id_distributor}` : '' ) +
  ( address_full ? `&address_full=${address_full}` : '' ) , {

    method: "GET",
    headers: {
      Authorization: userToken,
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => {
      logout(err)
    });
}

const insert = async (pointOfSale) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("POINTOFSALE"), {
    method: "POST",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pointOfSale),
  }).then((response) => response.json())
  .catch((err) => {logout(err)});;
};

const updatebyId = async ( pointOfSale, id ) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("POINTOFSALE")+ `/${id}`, {
    method: "PUT",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pointOfSale),
  }).then((response) => response.json())
  .catch((err) => {
    // logout(err)
  });
};

const getById = async (id) => {
  const userToken = await authService.getAuthToken()

  return fetch(apiService.getEndpoint("POINTOFSALE") + `/${id}`, {
    method: "GET",
    headers: {
      Authorization: userToken,
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => {});;
}


const pointsOfSaleService = {
  getAll,
  insert,
  updatebyId,
  getById
}

export default pointsOfSaleService