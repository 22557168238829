import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const getAll = async ({ pointOfSale, date_from, date_to, date_edition_from, date_edition_to, per_page, page, subscription_number, card_number, id_distributor, id_publication, role, day }) => {
  const userToken = await authService.getAuthToken()
  // console.log(date_from,date_to);
  // console.log(date_edition_from,date_edition_to);
  return fetch(
    apiService.getEndpointbyRole(role, "CONSUMPTIONS") +
    `?pointSaleId=${pointOfSale}&date_from=${date_from}&date_to=${date_to}&page=${page}&per_page=${per_page}` +
    ( subscription_number ? `&num_subscription=${subscription_number}` : '' ) +
    ( card_number ? `&num_card=${card_number}` : '' )+
    ( id_distributor ? `&id_distributor=${id_distributor}` : '' )+
    ( id_publication ? `&id_publication=${id_publication}` : '' )+
    ( date_edition_from ? `&date_edition_from=${date_edition_from}` : '' )+
    ( date_edition_to ? `&date_edition_to=${date_edition_to}` : '' )+
    ( day ? `&day=${day}` : '' ),
    {
      method: "GET",
      headers: {
        Authorization: userToken,
      },
    }
  )
  .then((response) => response.json())
  .catch((err) => {logout(err)});
};

const deletebyId = async ({ idConsumption, role }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpointbyRole(role, "CONSUMPTIONS") + `/${idConsumption}`, {
    method: "DELETE",
    headers: {
      Authorization: userToken,
    },
  })
  .then((response) => response.json())
  .catch((err) => {logout(err)});
};

const insert = async ( consumptions, role ) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpointbyRole(role, "CONSUMPTIONS"), {
    method: "POST",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(consumptions),
  })
  .then((response) => response.json())
  .catch((err) => {logout(err)});
};

const consumptionsService = {
  getAll,
  insert,
  deletebyId,
};

export default consumptionsService;
