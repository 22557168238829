import { FormControl, FormGroup, FormLabel, FormControlLabel, Checkbox } from "@mui/material";
import { Field } from "formik";
import configPublication from "../../../config/publication.json"

export default function PublicationCheckboxForm({values, idEditor, handleChange, setFieldValue}) {
  
  const publicationList = Object.keys(configPublication[idEditor]).map((pub) => (
    <FormControlLabel key={pub} value={pub} 
    control={<Checkbox defaultChecked={true}
    size='small'/>} 
    label={configPublication[idEditor][pub]["label"]}
    >
    </FormControlLabel>
  ));

  return (
      <>
        <FormControl sx={{ paddingLeft: 1.5, paddingTop: 0.5 }}>
          <FormLabel id="publication-label">Publicación</FormLabel>
          <Field
            component={FormGroup}
            row
            aria-labelledby="publication-label"
            name="publication_id"
            defaultValue=""
            value={values.publication_id}
            onChange={handleChange('publication_id')}
          >
            {publicationList}
          </Field>
        </FormControl>
      </>
  )
}
