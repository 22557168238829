import { Paper, Typography, Tooltip, Switch } from "@mui/material";
import Map, { NavigationControl, FullscreenControl } from "react-map-gl/maplibre";
import { useEffect, useState, useMemo } from "react";
import "maplibre-gl/dist/maplibre-gl.css";
import { getMidPoint, getMaxDistance, findClosestValuePosition } from "../utils/DistancePointsMap";
import StopRoundedIcon from '@mui/icons-material/StopRounded';
import { grey } from "@mui/material/colors";


// React Component that renders markers for all provided lockers
import { LockerMarkers } from "./LockerMarkers";

const region = process.env.REACT_APP_REGION
const mapName = process.env.REACT_APP_MAP_NAME

export const SalePointMap = ({ rowsState, authHelper, handleLockers, isActiveLockers, setSelectedPosId, setShowPos }) => {

  const { markers } = rowsState;
  const apiKey = process.env.REACT_APP_MAP_API_KEY

  // transform GeoJSON features into simplified locker objects
  const lockers = useMemo(() => {
    return markers.features.map(
      ({
        geometry: {
          coordinates: [longitude, latitude],
        },
        properties: { title, address: description, codeEditor, codeDistributor, id, color },
      }) => ({
        latitude,
        longitude,
        title,
        codeEditor,
        codeDistributor,
        description,
        id,
        color
      })
    );
  }, [markers])

  const activeLockers = useMemo(() => lockers?.filter(locker => locker.color === "1"), [lockers]);

  const coordinates = useMemo(() => {
    return markers.features.map(
      ({
        geometry: {
          coordinates: [longitude, latitude],
        },
      }) => ({
        x: parseFloat(longitude),
        y: parseFloat(latitude),
      })
    );
  }, [markers])

  let midPoint = useMemo(() => getMidPoint(coordinates), [coordinates]);
  let maxDistance = useMemo(() => getMaxDistance(coordinates), [coordinates]);
  let zoomLevel = useMemo(() => {
    const mapZoomLevels = [22184, 11092, 5547, 2773, 1387, 693, 347, 173, 86.7, 43.3, 21.7, 10.8, 5.4, 2.7, 1.35, 0.677, 0.339, 0.169, 0.0846, 0.0423].map(level => level * 0.8);
    return findClosestValuePosition(maxDistance, mapZoomLevels)
  }, [maxDistance]);

  const [viewState, setViewState] = useState({
    latitude: midPoint.y,
    longitude: midPoint.x,
    zoom: zoomLevel,
  });

  const [isMounted, setIsMounted] = useState(false); // isMounted flag to control when component is mounted/unmounted

  useEffect(() => {
    setIsMounted(true);

    if (isMounted) {
      setViewState({ ...viewState, longitude: midPoint.x, latitude: midPoint.y, zoom: zoomLevel });
    }

    return () => {
      setIsMounted(false);
    };
  }, [rowsState, isMounted]);


  return (
    <>
      <Paper sx={{ position: 'absolute', zIndex: 1, height: 12, display: 'flex', justifyContent: 'start', alignItems: 'center', bottom: 12, left: 12, p: 1 }}>
        <StopRoundedIcon color="secondary"></StopRoundedIcon><Typography variant="caption" sx={{ pt: 0.2 }}>Activo</Typography>
        <StopRoundedIcon sx={{ color: grey[600] }}></StopRoundedIcon><Typography variant="caption" sx={{ pt: 0.2 }}>Inactivo</Typography>
        <Tooltip placement="right" arrow title="Ver solo Activos (Ventas últimos 60 días)">
          <Switch
            sx={{ mx: 0.5, mr:0}}
            size="small"
            color="secondary"
            checked={isActiveLockers}
            onChange={handleLockers}
          />
        </Tooltip>
      </Paper >
      <Map
        // See https://visgl.github.io/react-map-gl/docs/api-reference/map
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        reuseMaps='true'

        mapStyle={`https://maps.geo.${region}.amazonaws.com/maps/v0/maps/${mapName}/style-descriptor?key=${apiKey}`}
        {...authHelper.getMapAuthenticationOptions()}
      >
        {/* See https://visgl.github.io/react-map-gl/docs/api-reference/navigation-control */}
        <NavigationControl position="bottom-right" showZoom showCompass={false} />

        {/* Render markers for all lockers, with a popup for the selected locker */}
        <LockerMarkers lockers={isActiveLockers ? activeLockers : lockers} setSelectedPosId={setSelectedPosId} setShowPos={setShowPos}/>
        <FullscreenControl />
      </Map>
    </>
  );
};