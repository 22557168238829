import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientService from "../../../services/client.service";

const initialState = {
  clients: [],
  status: "idle",
  error: null,
};

export const fetchClients = createAsyncThunk("clients/getClients", async () => {
  const response = await clientService.getAll({});
  return response.clients;
});

export const insertClients = createAsyncThunk(
  "clients/insertClients",
  async ({ client }) => {
    const response = await clientService.insert({ client });
    return response;
  }
);

export const updateClients = createAsyncThunk(
  "clients/updateClients",
  async ({ client }) => {
    const response = await clientService.update({ client });
    return response;
  }
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState: initialState,
  reducers: {
    setClients(state, action) {
      state.clients = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      //fetchClients
      .addCase(fetchClients.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.clients = action.payload;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      //insertClients
      .addCase(insertClients.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(insertClients.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(insertClients.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      //updateClients
      .addCase(updateClients.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateClients.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(updateClients.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { setClients } = clientsSlice.actions

export const selectAllClients = (state) => state.clients.clients;

export const selectClientByCard = (card) => (state) => {
  console.log({ clients: state.clients.clients });
  return state.clients.clients.find((client) => client.card === card);
};

export default clientsSlice.reducer;
