import { Autocomplete, Box, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, TextField, Select } from "@mui/material";
import * as Yup from "yup";
import { Field, Formik , Form} from "formik";
import DateRangePicker from "../../common/forms/dateRangePicker";
import { fetchPointsOfSale, selectAllPointsOfSale } from '../../../common/features/pointsOfSale/pointsOfSaleSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { StepperNavButtons } from "../StepperNavButtons";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export const StepperUsersForm = ({ setReportDataToSend, reportDataToSend, range, setRange, selectedPointOfSale, setSelectedPointOfSale, inputSelectedPointOfSale, setInputSelectedPointOfSale, steps, activeStep, handleBack, handleNext, handleSendReport}) => {

  const pointsOfSale = useSelector(selectAllPointsOfSale);

  const pointsOfSaleStatus = useSelector((state) => state.pointsOfSale.status);

  const dispatch = useDispatch();

  useEffect(() => {
    if (pointsOfSaleStatus === "idle") {
      dispatch(fetchPointsOfSale());
    }
  }, [pointsOfSaleStatus, dispatch]);

  const userStatusList = ['Activo', 'Desactivado', 'Pendiente de activación' ].map(status => (
    <MenuItem key={status} value={status}>{status}</MenuItem>
  ))

  const oneYearMinDate = () => {
    const currDate = new Date();
    const yearBefore = currDate.getFullYear() - 1;
    currDate.setFullYear(yearBefore);
    currDate.setHours(0,0,0,0);
    return currDate;
  }
  
  const minDate = oneYearMinDate();
  const maxDate = new Date();


  const validationSchema = Yup.object().shape({
    user_status: Yup.string(),
    date_from: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_to = this.parent.date_to;
      if (!value && date_to){
          return false; // No válido por rango abierto en date_from
      }
      return true;
    }).test('test-date-from', 'Selecciona un rango de fechas', function(value){
      const date_edition_from = this.parent.date_edition_from;
      const date_edition_to = this.parent.date_edition_to;
      const date_to = this.parent.date_to;
      if(!value && !date_to && !date_edition_from && !date_edition_to){
        return false //No válido en caso de ningún valor en ambos rangos
      }
      return true
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value < minDate){
        return false;
      }
      return true
    }),
    date_to: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_from = this.parent.date_from;
      if (!value && date_from){
          return false; // No válido por rango abierto en date_to
      }
      return true;
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value > maxDate){
        return false;
      }
      return true
    })
  });

  
  const dirtyInitial = (values) =>{
    let dirtyInitial = false;
    if(values.date_from !== "" && values.date_to !== ""){
      dirtyInitial = true;
    }
    return dirtyInitial
  }

  const dirtyInitialValues = dirtyInitial(reportDataToSend)

  const onChangeRange = (value) => {
    const newRange = {
      date_from: value[0]?.getTime(),
      date_to: value[1]?.getTime(),
    };
    setRange(newRange)
    setReportDataToSend({
      ...reportDataToSend, date_from: newRange.date_from, date_to: newRange.date_to})    
  };

  const onChangeStatus = (event, setFieldValue) =>{
    const {value} = event.target
    setFieldValue("user_status", value)
    setReportDataToSend({
      ...reportDataToSend, user_status: value})
  }

  const onChangePointSaleId = (id) => {
    setReportDataToSend({...reportDataToSend, point_of_sale: id })
  };

  const onClearUserStatus = (setFieldValue) => {
    setFieldValue("user_status", '')
    setReportDataToSend({...reportDataToSend, user_status: '' })
  };

  return (
    <div>
      <Formik
        initialValues={reportDataToSend}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          isSubmitting,
          isValid,
          touched,
          errors
        }) => (
          <Box>
            <p>Añade filtros para refinar tu informe de {reportDataToSend.report_type}</p>
            <Box component={Form} sx={{ mb: 3 }}>
              <Box sx={{ width: "100%", marginBottom: 2 }}>
                <Autocomplete
                  id="point-of-sale"
                  size="small"
                  loading
                  loadingText={"Cargando..."}
                  options={pointsOfSale}
                  value={selectedPointOfSale? selectedPointOfSale: null}
                  onChange={(event, newValue, reason) => {
                    if (reason === 'clear') {
                      setSelectedPointOfSale('');
                      setInputSelectedPointOfSale('');
                      onChangePointSaleId('')
                      return
                    } else {
                      setSelectedPointOfSale(newValue);
                      setInputSelectedPointOfSale(newValue? newValue.name+' - '+newValue.code_editor : '');
                      onChangePointSaleId(newValue.id)
                    }
                  }}
                  sx={{ maxWidth: 489.55 }}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        label="Punto de venta"
                        error={touched.point_of_sale && Boolean(errors.point_of_sale)}
                      />
                    );
                  }}
                  getOptionLabel={(option) => {
                    return (option?.name && option?.code_editor? option.name + " - " + option.code_editor: "")
                  }}
                  isOptionEqualToValue={(option, value) => {
                    return(
                    option?.id === value?.id
                    )
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option?.name && option?.code_editor
                          ? option.name + " - " + option.code_editor
                          : ""}
                      </li>
                    );
                  }}                    
                  >
                </Autocomplete>
                  {touched.point_of_sale && errors.point_of_sale && (
                    <FormHelperText htmlFor="point-of-sale" error>
                      {errors.point_of_sale}
                    </FormHelperText>
                  )}
              </Box>
              <FormControl fullWidth sx={{mb:2}}>
                <InputLabel id="user_status-label">Estado de usuario</InputLabel>
                <Field
                  component={Select}
                  labelId="user_status-label"
                  id="user_status"
                  label="Estado de usuario"
                  name="user_status"
                  value={values.user_status}
                  onChange={(event) => onChangeStatus(event, setFieldValue)}
                  size="small"
                  sx={{ maxWidth: 489.55 }}
                  endAdornment={ values.user_status && (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{padding:0.5, mr:2.3}}
                        onClick={() => onClearUserStatus(setFieldValue)}
                      >
                        <ClearIcon sx={{fontSize: 20}} />
                      </IconButton>
                    </InputAdornment>
                  )}
                >
                  {userStatusList}
                </Field>
              </FormControl>
              <Field
                component={DateRangePicker}
                onChangeRange={onChangeRange}
                dateFromFieldName="date_from"
                dateToFieldName="date_to"
                startText="Fecha creación"
                middleText="hasta"
                endText="Fecha creación"
                maxDate={maxDate}
                minDate={minDate}
                initialRangeValue={[range.date_from, range.date_to]}
              />
            </Box>
            <Box>
              <StepperNavButtons
                isValid={isValid}
                dirtyInitialValues={dirtyInitialValues}
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                handleSendReport={handleSendReport}
              ></StepperNavButtons>
            </Box>
          </Box>
        )}
      </Formik>
    </div>
  );
};
