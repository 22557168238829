import React, { useRef, useState } from 'react'
import Helmet from 'react-helmet'
import { Grid } from '@mui/material'
import { HistoricalConsumptionsForm } from '../../components/consumptions/forms/historicalConsumptionsForm'
import { HistoricalConsumptionsTable } from '../../components/consumptions/HistoricalConsumptionsTable'
import { HistoricalConsumptionsSummary } from '../../components/consumptions/HistoricalConsumptionsSummary'
import consumptionsService from '../../services/consumptions.service'
import { useSnackbar } from 'notistack'
import useModal from '../../common/hooks/useModal'
import { SubmitButton } from '../../components/common/forms/submitButton'
import { IsRouteMatch } from '../../components/utils/IsRouteMatch'

export default function HistoricalConsumptions () {
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 50,
    total: 0,
    rows: [],
    grouped: [],
    loading: false
  })

  const [range, setRange] = useState({
    date_from: '',
    date_to: ''
  })

  const [rangeEdition, setRangeEdition] = useState({
    date_edition_from: '',
    date_edition_to: ''
  })

  const [pointOfSale, setPointOfSale] = useState('')
  const [subscriptionNumber, setSubscriptionNumber] = useState('')
  const [cardNumber, setCardNumber] = useState('')
  const [distributorId, setDistributorId] = useState('')
  const [publicationId, setPublicationId] = useState('')

  const enableSelectToCancel = IsRouteMatch({route: "/historical-consumptions/cancel"})

  console.log(enableSelectToCancel)
  
  const { enqueueSnackbar } = useSnackbar()
  const modal = useModal();
  const [selectionModel, setSelectionModel] = useState([])
  const prevSelectionModel = useRef(selectionModel)
  const [loadingDisableConsumption, setLoadingDisableConsumption] = useState(
    false
  )

  const handleUnsubscribe = () => {
    setLoadingDisableConsumption(true)
    selectionModel.forEach(element => {
      consumptionsService
        .deletebyId({ idConsumption: element })
        .then(response => {
          if (response.status !== 200) {
            enqueueSnackbar(response.message, {
              variant: 'error'
            })
            return
          } else {
            enqueueSnackbar(response.message, {
              variant: 'success'
            })
            setRowsState(prev => ({
              ...prev,
              rows: prev.rows.map(row => {
                if (row.id === element) {
                  return { ...row, status: 1 }
                } else {
                  return { ...row }
                }
              })
            }))
            setSelectionModel([])
          }
        })
        .catch(err => {
          enqueueSnackbar(err.message, {
            variant: 'error'
          })
        })
        .finally(() => {
          setLoadingDisableConsumption(false)
        })
    })
  }

  return (
    <>
      <h3>{ enableSelectToCancel ? "Anular consumo histórico" : "Consumo histórico" }</h3>
      <Helmet>
        <title>Consumo histórico - Marina BCN</title>
      </Helmet>
      <Grid container 
      >
        <Grid item xs={12} md={6}>
          <HistoricalConsumptionsForm
            setRange={setRange}
            setRangeEdition={setRangeEdition}
            rowsState={rowsState}
            setRowsState={setRowsState}
            setPointOfSale={setPointOfSale}
            setSubscriptionNumber={setSubscriptionNumber}
            setCardNumber={setCardNumber}
            setDistributorId={setDistributorId}
            setPublicationId={setPublicationId}
            setSelectionModel={setSelectionModel}
          />
        </Grid>
        {rowsState.grouped.length > 0 && (
          <Grid item xs={12} md={6}>
            <HistoricalConsumptionsSummary
              grouped={rowsState.grouped}
              total={rowsState.total}
            />
          </Grid>
        )}
      </Grid>
      {rowsState.rows.length > 0 && (
        <>
          <HistoricalConsumptionsTable
            range={range}
            setRange={setRange}
            rangeEdition={rangeEdition}
            setRangeEdition={setRangeEdition}
            pointOfSale={pointOfSale}
            subscriptionNumber={subscriptionNumber}
            cardNumber={cardNumber}
            distributorId={distributorId}
            publicationId={publicationId}
            rowsState={rowsState}
            setRowsState={setRowsState}
            loading={rowsState.loading}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            prevSelectionModel={prevSelectionModel}
            enableSelectToCancel={enableSelectToCancel}
          />
          {enableSelectToCancel && (
            <SubmitButton
              text='Anular venta'
              onClick={(evt) =>
                modal.show({
                  title: "Anular venta",
                  content: "¿Estás seguro de que quieres anular la venta?",
                  onSubmit: () => {
                    handleUnsubscribe();
                  },
                })
              }
              isValid={selectionModel && selectionModel.length > 0}
              variant='outlined'
              isSubmitting={loadingDisableConsumption}
            />
          )}
        </>
      )}
    </>
  )
}
