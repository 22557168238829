import Helmet from 'react-helmet'
import { ClientForm } from '../../components/clients/forms/ClientForm'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { insertClients } from '../../common/features/clients/clientsSlice'
import { useDispatch } from 'react-redux'

export default function NewClient() {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const historyStateNumber = history.location.state?.cardNumber
  const dispatch = useDispatch()

  useEffect(() => {
    if (historyStateNumber) {
      history.replace({ state: {} })
    }
  }, [historyStateNumber, history]);

  const handleSubmit = async (values, { resetForm }) => {

    dispatch(insertClients({
      client: {
        alias: values.alias,
        card: values.card_number
      }
    }))
    .then(response => {
      if (response.payload.status === 200) {
        enqueueSnackbar(response.payload.message, {
          variant: 'success'
        })
        history.push('/clients')
      } else {
        enqueueSnackbar(response.payload.message, {
          variant: 'error'
        })
      }
    })
    .catch(err => {
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    })
  }
  return (
    <>
      <h3>Nuevo cliente</h3>
      <Helmet>
        <title>Nuevo cliente - Marina BCN</title>
      </Helmet>
      <ClientForm
        cardNumber={historyStateNumber || ''}
        alias=""
        handleSubmit={handleSubmit}
      />
    </>
  )
}
