import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import clientsSlice from "../features/clients/clientsSlice";
import distributorsSlice from "../features/distributors/distributorsSlice";
import pointsOfSaleReducer from "../features/pointsOfSale/pointsOfSaleSlice";
import addressesSlice from "../features/addresses/addressesSlice";

const combinedReducer = combineReducers({
  auth: authReducer,
  pointsOfSale: pointsOfSaleReducer,
  distributors: distributorsSlice,
  clients: clientsSlice,
  addresses: addressesSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/logout/fulfilled") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
