import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import pointsOfSaleService from "../../../services/pointsOfSale.service";

const initialState = {
  pointsOfSale: [],
  current: "",
  status: "idle",
  error: null,
};

export const fetchPointsOfSale = createAsyncThunk(
  "pointsOfSale/getPointsOfSale",
  async (role) => {
    const response = await pointsOfSaleService.getAll({ role});
    return response.pointsOfSale;
  }
);

export const insertPointOfSale = createAsyncThunk(
  "pointsOfSale/insertPointOfSale",
  async ({ pointOfSale }) => {
    const response = await pointsOfSaleService.insert({ pointOfSale });
    return response;
  }
);

const pointsOfSaleSlice = createSlice({
  name: "pointsOfSale",
  initialState,
  reducers: {
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      //fetchPointsOfSale
      .addCase(fetchPointsOfSale.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchPointsOfSale.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pointsOfSale = action.payload;
      })
      .addCase(fetchPointsOfSale.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      //insertPointOfSale
      .addCase(insertPointOfSale.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(insertPointOfSale.fulfilled, (state, action) => {
        state.status = "idle"
      })
      .addCase(insertPointOfSale.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default pointsOfSaleSlice.reducer;

export const { setCurrent } = pointsOfSaleSlice.actions;

export const selectAllPointsOfSale = (state) => state.pointsOfSale.pointsOfSale;

export const selectPointOfSaleById = (posId) => (state) => { return state.pointsOfSale.pointsOfSale.find((pos) => { return pos.id === posId}) }

