import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Helmet from 'react-helmet'
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Delete } from "@mui/icons-material";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { useSnackbar } from "notistack";
import { Autocomplete, Box, Card, CardContent, CardHeader, Avatar, List, ListItemText, ListItem, Paper, TextField, FormHelperText, Button, Stack, MenuItem, FormControl, InputLabel, Select, Chip, Divider, Menu, ListItemIcon } from "@mui/material";

import useModal from "../../../common/hooks/useModal";
import userService from "../../../services/user.service";
import configEditorial from "../../../config/editorial.json";
import configDistributor from "../../../config/distributor.json";
import { Role } from '../../../common/roles/role'
import { SubmitButton } from "../../../components/common/forms/submitButton";
import { formatDateFromUTC } from "../../../components/utils/FormatDateFromUTC";
import { getFormattedStatus } from "../../../helpers";
import { getFormattedFieldName } from "../../../helpers";
import { selectAllPointsOfSale, fetchPointsOfSale } from "../../../common/features/pointsOfSale/pointsOfSaleSlice";

export const EditUserForm = (props) => {

  const [user, setUser] = useState(props.userInfo);
  const [userPointOfSale, setUserPointOfSale] = useState(props.userPointOfSale);
  const userId = props.userId

  const { enqueueSnackbar } = useSnackbar();
  const [editUser, setEditUser] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const modal = useModal();

  const dispatch = useDispatch()
  const pointsOfSaleStatus = useSelector(state => state.pointsOfSale.status)
  const pointsOfSale = useSelector(selectAllPointsOfSale)

  if (user.role === Role.Kiosko && pointsOfSaleStatus === 'idle') {
    dispatch(fetchPointsOfSale())
  }

  const valueUserPointOfSale = `${userPointOfSale.name} - ${userPointOfSale.code_editor}`

  const completeName = user.first_name + " " + user.last_name_first + " " + user.last_name_second;
  const firstLetterName = user.first_name?.charAt(0);

  const editableItems = ["first_name", "last_name_first", "last_name_second", "phone", "id_editor", "id_distributor", "id_pointsale"];
  const showItems = ["role", "email_address", "first_name", "last_name_first", "last_name_second", "phone", "id_editor", "id_distributor", "id_pointsale"].filter(item => user[item] !== null);

  const handleEdit = () => {
    setEditUser(true)
  }

  const handleSubmit = async (values) => {
    setEditUser(false)
    setUser(values)

    await userService
      .updatebyId({ user: values, userId: props.userId })
      .then((response) => {
        if (response.status !== 200) {
          enqueueSnackbar(response.message, {
            variant: "error",
          });
        } else {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          console.log('USER', user)
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      });
  }


  const distributorsByRole = configDistributor[process.env.REACT_APP_ENVIRONMENT]
  const editorialsByRole = configEditorial[process.env.REACT_APP_ENVIRONMENT]['Editor']

  const distributorList = Object.keys(distributorsByRole).map(id_distributor => (
    <MenuItem key={id_distributor} value={id_distributor}>{distributorsByRole[id_distributor]}</MenuItem>
  ));
  const editorialList = Object.keys(editorialsByRole).map(id_editor => (
    <MenuItem key={id_editor} value={id_editor}>{editorialsByRole[id_editor]}</MenuItem>
  ));

  let userStatusLabel = getFormattedStatus(user.status)  // traduce los labels

  const open = Boolean(anchorEl); // control para abrir las actions

  const handleActionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleActionsClose = () => {
    setAnchorEl(null);
  };

  const PHONE_REGEX = /^[+34]{3}[0-9]{9}$/
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('El campo "Nombre" es obligatorio'),
    last_name_first: Yup.string().required('El campo "Primer apellido" es obligatorio'),
    last_name_second: Yup.string().required('El campo "Segundo apellido" es obligatorio'),
    phone: Yup.string()
      .matches(PHONE_REGEX, 'El formato del teléfono es incorrecto')
      .typeError('El formato del teléfono es incorrecto')
      .required('El campo "teléfono" es obligatorio')
  });

  const onChangeEditorial = (event, setFieldValue) => {
    console.log(event.target)
    const id_editor = event.target.value
    setFieldValue('id_editor', id_editor)
    setFieldValue('editor', editorialsByRole[id_editor])
    setUser({ ...user, id_editor: id_editor, editor: editorialsByRole[id_editor] })
  }

  const onChangeDistributor = (event, setFieldValue) => {
    console.log(event.target)
    const id_distributor = event.target.value
    setFieldValue('id_distributor', id_distributor)
    setFieldValue('distributor', distributorsByRole[id_distributor])
    setUser({ ...user, id_distributor: id_distributor, distributor: distributorsByRole[id_distributor] })
  }

  return (
    <>
      <h3>Perfil de usuario</h3>
      <Helmet>
        <title>Perfil de usuario - Marina BCN</title>
      </Helmet>

      <Formik
        initialValues={{ ...user, userPointOfSale: userPointOfSale }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleChange, handleReset, handleBlur, setFieldValue, values, isSubmitting, isValid, touched, errors, resetForm }) => (

          <Box sx={{ maxWidth: 450 }}>
            <Form>
              <Card sx={{ mb: 2 }}>
                <Paper>
                  <CardHeader
                    sx={{ pt:3, pb: 0, '& .MuiCardHeader-action': { my: 0, mx: 2 } }}
                    avatar={
                      <Avatar sx={{ width: 42, height: 42, ml: 2 }} aria-label="user">
                        {firstLetterName}
                      </Avatar>
                    }
                    action={
                      <>
                        <Chip
                          sx={{ mr: 1 }}
                          variant="outlined"
                          label={userStatusLabel.text}
                          color={userStatusLabel.color}
                        >
                        </Chip>
                        <IconButton
                          onClick={handleActionsClick}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          id="account-menu"
                          open={open}
                          onClose={handleActionsClose}
                          onClick={handleActionsClose}
                          PaperProps={{
                            elevation: 0,
                            sx: {
                              overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, minWidth: 140,
                              '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
                              '&:before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                          }}
                          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                          {user.status === "CREATED" ? (
                            [
                              <MenuItem key="validate" onClick={(evt) =>
                                modal.show({
                                  title: "Validar usuario",
                                  content: `¿Estás seguro que quieres validar el usuario ${user.email_address}?`,
                                  onSubmit: () => {
                                    props.handleValidateUser(userId);
                                  },
                                })
                              }>
                                <ListItemIcon>
                                  <HowToRegIcon fontSize="small" />
                                </ListItemIcon>
                                Validar
                              </MenuItem>,
                              <MenuItem key="resend" onClick={(evt) =>
                                modal.show({
                                  title: "Enviar Invitación",
                                  content: `¿Estás seguro que quieres enviar invitación al usuario ${user.email_address}?`,
                                  onSubmit: () => {
                                    props.handleSendInvitation(userId);
                                  },
                                })
                              }>
                                <ListItemIcon>
                                  <ForwardToInboxIcon fontSize="small" />
                                </ListItemIcon>
                                Reenviar invitación
                              </MenuItem>,
                              <Divider key="divider" />
                            ]
                          ) : null}

                          {user.status === "DISABLED" ? (
                            <MenuItem onClick={(evt) =>
                              modal.show({
                                title: "Reactivar usuario",
                                content: `¿Estás seguro que quieres reactivar el usuario ${user.email_address}?`,
                                onSubmit: () => {
                                  props.handleReactivateUser(userId);
                                },
                              })
                            }>
                              <ListItemIcon>
                                <RefreshIcon fontSize="small" />
                              </ListItemIcon>
                              Reactivar
                            </MenuItem>
                          ) : null}

                          {user.status !== "DISABLED" ? (
                            <MenuItem  onClick={(evt) =>
                                  modal.show({
                                    title: "Desactivar usuario",
                                    content: `¿Estás seguro que quieres desactivar el usuario ${user.email_address}?`,
                                    onSubmit: () => {
                                      props.handleDisableUser(userId);
                                    },
                                  })
                                }>
                            <ListItemIcon>
                                <Delete fontSize="small" />
                              </ListItemIcon>
                              Desactivar
                            </MenuItem>
                          ) : null}
                        </Menu>
                      </>
                    }
                    title={completeName}
                    subheader={formatDateFromUTC(user.created)}
                  >
                  </CardHeader>
                  <CardContent>
                    <List dense={true} sx={{pb:0}}>
                      {Object.keys(user).map((item, i) => (
                          showItems.includes(item) ? (
                            <ListItem key={i} alignItems="flex-start" sx={{ mb: 1 }}>
                              {editUser && editableItems.includes(item) ? (
                                item === "id_pointsale" ? (
                                  <Box sx={{ width: "100%", marginBottom: 1 }}>

                                    <Autocomplete
                                      id="id_pointsale"
                                      size="small"
                                      loading
                                      loadingText={"Cargando..."}
                                      disableClearable
                                      options={pointsOfSale}
                                      value={userPointOfSale ? userPointOfSale : null}
                                      onChange={(event, newValue, reason) => {
                                        setUserPointOfSale(newValue)
                                        setUser({ ...values, id_pointsale: newValue.id, pointsale: newValue.name })
                                      }}
                                      sx={{ maxWidth: 489.55 }}
                                      renderInput={(params) => {
                                        return (
                                          <TextField
                                            {...params}
                                            label="Punto de venta"
                                            error={touched.id_pointsale && Boolean(errors.id_pointsale)}
                                          />
                                        );
                                      }}
                                      getOptionLabel={(option) => {
                                        return (option?.name && option?.code_editor ? option.name + " - " + option.code_editor : "")
                                      }}
                                      isOptionEqualToValue={(option, value) => {
                                        return (
                                          option?.id === value?.id
                                        )
                                      }}
                                      renderOption={(props, option) => {
                                        return (
                                          <li {...props} key={option.id}>
                                            {option?.name && option?.code_editor
                                              ? option.name + " - " + option.code_editor
                                              : ""}
                                          </li>
                                        );
                                      }}
                                    >
                                    </Autocomplete>
                                    {touched.id_pointsale && errors.id_pointsale && (
                                      <FormHelperText htmlFor="point-of-sale" error>
                                        {errors.id_pointsale}
                                      </FormHelperText>
                                    )}
                                  </Box>

                                ) : (
                                  item === "id_distributor" ? (
                                    <FormControl sx={{ width: '100%', mb: 1 }}>
                                      <InputLabel id='distributor-label' error={Boolean(touched.id_distributor && errors.id_distributor)}>Distribuidor</InputLabel>
                                      <Field
                                        component={Select}
                                        labelId='distributor-label'
                                        id='id_distributor'
                                        name='id_distributor'
                                        label='Distribuidor'
                                        value={values.id_distributor}
                                        onChange={(e) => { onChangeDistributor(e, setFieldValue) }}
                                        error={touched.role && Boolean(errors.id_distributor)}
                                        size='small'
                                      >
                                        {distributorList}
                                      </Field>
                                      {(touched.role && errors.id_distributor) && <FormHelperText htmlFor='editor' error>{errors.id_distributor}</FormHelperText>}
                                    </FormControl>
                                  ) : (
                                    item === "id_editor" ? (
                                      <FormControl sx={{ width: '100%', mb: 1 }}>
                                        <InputLabel id='editorial-label' error={Boolean(touched.id_editor && errors.id_editor)}>Editorial</InputLabel>
                                        <Field
                                          component={Select}
                                          labelId='editorial-label'
                                          id='id_editor'
                                          name='id_editor'
                                          label='Editorial'
                                          value={values.id_editor}
                                          onChange={(e) => { onChangeEditorial(e, setFieldValue) }}
                                          error={touched.role && Boolean(errors.id_editor)}
                                          size='small'
                                        >
                                          {editorialList}
                                        </Field>
                                        {(touched.role && errors.id_editor) && <FormHelperText htmlFor='editor' error>{errors.id_editor}</FormHelperText>}
                                      </FormControl>
                                    ) : (
                                      <Field
                                        as={TextField}
                                        variant='outlined'
                                        margin="none"
                                        size="small"
                                        label={getFormattedFieldName(item)}
                                        name={item}
                                        fullWidth
                                        error={touched[item] && Boolean(errors[item])}
                                        helperText={touched[item] && errors[item]}
                                        sx={{ mb: 1 }}
                                      />
                                    )
                                  )
                                )
                              ) : (
                                item === "id_pointsale" ? (
                                  <ListItemText primary={getFormattedFieldName('id_pointsale')} secondary={valueUserPointOfSale} />
                                ) : (
                                  item === "id_distributor" ? (
                                    <ListItemText primary={getFormattedFieldName('distributor')} secondary={user.distributor} />
                                  ) : (
                                    item === "id_editor" ? (
                                      <ListItemText primary={getFormattedFieldName('editor')} secondary={user.editor} />
                                    ) : (
                                      <ListItemText primary={getFormattedFieldName(item)} secondary={user[item]} />
                                    )
                                  )
                                )
                              )}
                            </ListItem>
                          ) : ('')
                      ))}
                    </List>
                  </CardContent>
                </Paper>
              </Card>
              <Stack direction="row" justifyContent={"space-between"}>
                {/* <Button onClick={() => { history.push('/users') }} disabled={editUser} variant="outlined" color="primary">
                  Volver
                </Button> */}
                <Button onClick={() => { history.push('/users') }} disabled={editUser} startIcon={<ArrowBackIcon />}>
                    Volver
                </Button>
                <Stack spacing={1} direction="row">
                  {!editUser ? (
                    <Button onClick={() => { handleEdit(values) }} disabled={editUser} variant="outlined" color="secondary">
                      Editar
                    </Button>
                  ) : (
                    <Button onClick={() => { handleReset(); setEditUser(false) }} type="reset" variant="outlined" color="secondary">
                      Cancelar
                    </Button>
                  )}
                  <SubmitButton
                    text='Guardar Cambios'
                    isValid={isValid && editUser}
                    isSubmitting={isSubmitting}
                  >
                  </SubmitButton>
                </Stack>
              </Stack>
            </Form>
          </Box>
        )}
      </Formik>
    </>
  );
};
