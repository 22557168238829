import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const getAll = async (props) => {
  const {address, role} = props
  const userToken = await authService.getAuthToken()

  return fetch(apiService.getEndpointbyRole(role, "ADDRESS") +
    ( address ? `?&address=${address}` : '' ), {

    method: "GET",
    headers: {
      Authorization: userToken,
    },
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => {
      logout(err)
    });
}

const insert = async ( address ) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("ADDRESS"), {
    method: "POST",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(address),
  }).then((response) => response.json())
  .catch((err) => {
    logout(err)
    console.log(err)
  });;
};

const updatebyId = async ( address, id) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("ADDRESS")+ `/${id}`, {
    method: "PUT",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(address),
  }).then((response) => response.json())
  .catch((err) => {
    logout(err)
  });
};

const deletebyId = async (id) => {
  const userToken = await authService.getAuthToken()
  return fetch(
    apiService.getEndpoint("ADDRESS") + `/${id}`,
    {
      method: "DELETE",
      headers: {
          Authorization: userToken,
      },
    }
  ).then((response) => response.json())
  .catch((err) => {logout(err)});
};


const addressService = {
  getAll, insert, updatebyId, deletebyId
}

export default addressService