import React, { useState } from "react";
import { useSelector } from "react-redux";
import { MarinaAppBar } from "./MarinaAppBar";
import { DrawerContainer } from "./drawer/DrawerContainer";

export default function ResponsiveDrawer() {
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { role, email } = user;

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <MarinaAppBar
        isAuthenticated={isAuthenticated}
        handleDrawerToggle={handleDrawerToggle}
      />
      {isAuthenticated && (
        <DrawerContainer
          role={role}
          email={email}
          drawerOpen={drawerOpen}
          handleDrawerToggle={handleDrawerToggle}
        />
      )}
    </>
  );
}
