import React from "react";
import { UserValidatedForm } from "../../../components/auth/activateAccount/forms/userValidatedForm";
import useQuery from "../../../common/hooks/useQuery"

function ActivateAccount() {
  let query = useQuery();

  return (
    <>
      <h3>Activa tu cuenta</h3>
      <p>
        Establece un password para terminar la activación de la cuenta e
        iniciar sesión
      </p>
      <UserValidatedForm
        email={query.get("email")}
        code={query.get("code")}
      />
    </>
  );
   
}
export default ActivateAccount;
