import { ListItem, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import { matchPath } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ListItemIcon, ListItemButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";
import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import ClearIcon from "@mui/icons-material/Clear";
import ContentPasteOffIcon from "@mui/icons-material/ContentPasteOff";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import SummarizeIcon from '@mui/icons-material/Summarize';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import StorefrontIcon from '@mui/icons-material/Storefront';

export const CustomListItem = ({
  to,
  text,
  exactRouteMatch,
  listItemChild,
}) => {
  const location = useLocation();

  const match = matchPath(location.pathname, {
    path: to,
  });

  const isSelected =
    exactRouteMatch && match != null ? match.isExact : match != null;

  const iconMap = {
    "/consumptions": <SearchIcon fontSize="small" />,
    "/day-consumptions": <AssignmentTurnedInIcon fontSize="small" />,
    "/historical-consumptions": <AssignmentIcon fontSize="small" />,
    "/consumptions/new": <CheckIcon fontSize="small" />,
    "/consumptions/massive": <DoneAllIcon fontSize="small" />,
    "/consumptions/new-delayed": <WatchLaterIcon fontSize="small" />,
    "/consumptions/cancel": <ClearIcon fontSize="small" />,
    "/historical-consumptions/cancel": <ContentPasteOffIcon fontSize="small" />,
    "/users": <PersonIcon fontSize="small" />,
    "/points-of-sale": <StorefrontIcon fontSize="small" />,
    "/locations": <PlaceIcon fontSize="small" />,
    "/clients": <AssignmentIndIcon fontSize="small" />,
    "/reports": <SummarizeIcon fontSize="small" />
  };

  const icon = iconMap[to] || null;

  return exactRouteMatch ? (
    <ListItemButton component={Link} to={to} selected={isSelected} sx={{ pl:4 }}>
      <ListItemIcon sx={{ minWidth: "32px" }}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  ) : (
    <ListItemButton component={Link} to={to} selected={isSelected}>
      <ListItemIcon sx={{ minWidth: "32px" }}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
};
