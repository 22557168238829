import TextField from '@mui/material/TextField'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import MobileDateRangePicker from '@mui/lab/MobileDateRangePicker'
import { es } from 'date-fns/locale'
import { useState } from 'react'

export default function DateRangePicker ({
  form,
  startText,
  middleText,
  endText,
  onChangeRange,
  dateFromFieldName,
  dateToFieldName,
  maxDate,
  minDate,
  initialRangeValue = [null, null]
}) {
  const [value, setValue] = useState(initialRangeValue)

  return (
    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <MobileDateRangePicker
          startText={startText}
          endText={endText}
          value={value}
          maxDate={maxDate}
          minDate={minDate}
          onChange={newValue => {
            setValue(newValue) //Datepicker viewable dates
            onChangeRange(newValue) //Global range object dates for other componentes (ex: historicalConsumptionsTable)
            form.setFieldValue(dateFromFieldName, newValue[0], true) //Real form date
            form.setFieldValue(dateToFieldName, newValue[1], true) //Real form date
          }}
          renderInput={(startProps, endProps) => (
            <>
              <TextField
                size='small'
                {...startProps}
                name={dateFromFieldName}
                error={Boolean(
                  (form.touched[dateFromFieldName] || form.touched[dateToFieldName]) &&
                    form.errors[dateFromFieldName]
                )}
                onBlur={() => form.setFieldTouched(dateFromFieldName, true, true)}
                helperText={
                  (form.touched[dateFromFieldName] || form.touched[dateToFieldName]) &&
                  form.errors[dateFromFieldName]
                }
              />
              <Box sx={{ mx: 2 }}> {middleText} </Box>
              <TextField
                size='small'
                {...endProps}
                name={dateToFieldName}
                error={Boolean(
                  (form.touched[dateFromFieldName] || form.touched[dateToFieldName]) &&
                    form.errors[dateToFieldName]
                )}
                onBlur={() => form.setFieldTouched(dateToFieldName, true, true)}
                helperText={
                  (form.touched[dateFromFieldName] || form.touched[dateToFieldName]) &&
                  form.errors[dateToFieldName]
                }
              />
            </>
          )}
        />
      </Stack>
    </LocalizationProvider>
  )
}
