import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import config from './aws-config'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import store from './common/store/store'
import ModalProvider from './common/providers/ModalProvider'
import Helmet from 'react-helmet'
import ScrollToTop from './common/router/ScrollToTop'

Amplify.configure(config)

const marinaTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#323232'
    },
    secondary: {
      main: '#cb3a2a'
    },
    background: {
      default: '#fff'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: '#323232'
        }
      }
    }
    /*MuiDrawer: {
      styleOverrides: {
        paper: {
          color: '#fff',
          backgroundColor: "#323232",
        },
      },
    },*/
  }
})

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={marinaTheme}>
      <ModalProvider>
        <SnackbarProvider maxSnack={3} hideIconVariant>
          <Router>
            <Helmet>
              <title>Marina BCN</title>
            </Helmet>
            <ScrollToTop />
            <App />
          </Router>
        </SnackbarProvider>
      </ModalProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
