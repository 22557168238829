import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select } from '@mui/material'
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import configDistributor from "../../../config/distributor.json";
import DateRangePicker from "../../common/forms/dateRangePicker";
import { StepperNavButtons } from '../StepperNavButtons';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Role } from '../../../common/roles/role';
import { useSelector } from "react-redux";

export const StepperPointsOfSaleForm = ({ setReportDataToSend, reportDataToSend, range, setRange, rangeEnding, setRangeEnding, steps, activeStep, handleBack, handleNext, handleSendReport}) => {

  const { role } = useSelector(
    state => state.auth.user
  )

  const onChangeRange = (value) => {
    const newRange = {
      date_from: value[0]?.getTime(),
      date_to: value[1]?.getTime(),
    };
    setRange(newRange)
    setReportDataToSend({...reportDataToSend, date_from: newRange.date_from, date_to: newRange.date_to})
  };

  const onChangeRangeEnding = (value) => {
    const newRangeEnding = {
      date_ending_from: value[0]?.getTime(),
      date_ending_to: value[1]?.getTime(),
    };
    setRangeEnding(newRangeEnding)
    setReportDataToSend({...reportDataToSend, date_ending_from: newRangeEnding.date_ending_from, date_ending_to: newRangeEnding.date_ending_to})
  };
  
  const onChangeDistributorId = (event, setFieldValue) => {
    const {value} = event.target
    setFieldValue("distributor_id", value)
    setReportDataToSend({...reportDataToSend, distributor_id: event.target.value })
  };

  const oneYearMinDate = () => {
    const currDate = new Date();
    const yearBefore = currDate.getFullYear() - 1;
    currDate.setFullYear(yearBefore);
    currDate.setHours(0,0,0,0);
    return currDate;
  }
  
  const minDate = oneYearMinDate();
  const maxDate = new Date();

  const validationSchema = Yup.object().shape({
    distributorId: Yup.string(),
    date_from: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_to = this.parent.date_to;
      if (!value && date_to){
          return false; // No válido por rango abierto en date_from
      }
      return true;
    }).test('test-date-from', 'Selecciona un rango de fechas', function(value){
      const date_ending_from = this.parent.date_ending_from;
      const date_ending_to = this.parent.date_ending_to;
      const date_to = this.parent.date_to;
      if(!value && !date_to && !date_ending_from && !date_ending_to){
        return false //No válido en caso de ningún valor en ambos rangos
      }
      return true
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value < minDate){
        return false;
      }
      return true
    }),
    date_to: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_from = this.parent.date_from;
      if (!value && date_from){
          return false; // No válido por rango abierto en date_to
      }
      return true;
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value > maxDate){
        return false;
      }
      return true
    }),
    date_ending_from: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_ending_to = this.parent.date_ending_to;
      if (!value && date_ending_to){
          return false; // No válido por rango abierto en date_ending_from
      }
      return true;
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value < minDate){
        return false;
      }
      return true
    }),
    date_ending_to: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_ending_from = this.parent.date_ending_from;
      if (!value && date_ending_from){
          return false; // No válido por rango abierto en date_ending_to
      }
      return true;
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value > maxDate){
        return false;
      }
      return true
    })
  });

  const dirtyInitial = (values) =>{
    let dirtyInitial = false;
    if(values.date_from !== "" && values.date_to !== ""){
      dirtyInitial = true;
    }
    return dirtyInitial
  }

  const dirtyInitialValues = dirtyInitial(reportDataToSend)

  const distributorList = Object.keys(configDistributor[process.env.REACT_APP_ENVIRONMENT]).map((dist) => (
    <MenuItem key={dist} value={dist}>
      {configDistributor[process.env.REACT_APP_ENVIRONMENT][dist]}
    </MenuItem>
  ));

  const onClearDistributor = (setFieldValue) => {
    setFieldValue("distributor_id", '')
    setReportDataToSend({...reportDataToSend, distributor_id: '' })
  };

  return (
    <div>
      <Formik
        initialValues={reportDataToSend}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          isSubmitting,
          isValid,
          touched,
          errors
        }) => (
          <Box sx={{ mb: 3 }}>
            <p>Añade filtros para refinar tu informe de {reportDataToSend.report_type}</p>

            <Box component={Form}
              sx={{
                "& .MuiFormControl-root": {
                  marginBottom: 2,
                  width: "100",
                },
                "& .MuiAutocomplete-root .MuiFormControl-root": {
                  marginBottom: 1,
                }
              }}
              autoComplete="off"
            >
            {role !== Role.Distribuidor && (
              <>
              <FormControl fullWidth sx={{ marginBottom: 1 }}>
                <InputLabel id="distributor-label">Distribuidor</InputLabel>
                <Field
                  component={Select}
                  labelId="distributor-label"
                  id="distributor"
                  label="Distribuidor"
                  name="distributor_id"
                  value={values.distributor_id}
                  onChange={(event) => {onChangeDistributorId(event, setFieldValue)}}
                  size="small"
                  sx={{ maxWidth: 489.55 }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 230,
                        width: 250,
                      },
                    },
                  }}
                  endAdornment={ values.distributor_id && (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{padding:0.5, mr:2.3}}
                        onClick={() => {onClearDistributor(setFieldValue)}}
                      >
                        <ClearIcon sx={{fontSize: 20}} />
                      </IconButton>
                    </InputAdornment>
                  )}
                >
                  {distributorList}
                </Field>
              </FormControl>
              </>
           )}

              <Field
              sx={{ marginBottom: 1 }}
                component={DateRangePicker}
                onChangeRange={onChangeRange}
                dateFromFieldName="date_from"
                dateToFieldName="date_to"
                startText="Fecha creación"
                middleText="hasta"
                endText="Fecha creación"
                maxDate={maxDate}
                minDate={minDate}
                initialRangeValue={[range.date_from, range.date_to]}
              />

              <Field
                component={DateRangePicker}
                onChangeRange={onChangeRangeEnding}
                dateFromFieldName="date_ending_from"
                dateToFieldName="date_ending_to"
                startText="Fecha de baja"
                middleText="hasta"
                endText="Fecha de baja"
                maxDate={maxDate}
                minDate={minDate}
                initialRangeValue={[rangeEnding.date_ending_from, rangeEnding.date_ending_to]}
              />
            </Box>
            <Box>
              <StepperNavButtons
                isValid={isValid}
                dirtyInitialValues={dirtyInitialValues}
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                handleSendReport={handleSendReport}
              ></StepperNavButtons>
            </Box>
          </Box>
        )}
      </Formik>
    </div>
  );
};
