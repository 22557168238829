import { Autocomplete, Box, FormHelperText, InputAdornment, TextField } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'

import {
  fetchPointsOfSale,
  selectAllPointsOfSale,
} from '../../../common/features/pointsOfSale/pointsOfSaleSlice'
import { SubmitButton } from '../../common/forms/submitButton'
import massiveConsumptionsService from '../../../services/massiveConsumptions.service'
import { Role } from '../../../common/roles/role'
import QRButton from '../../common/forms/QRButton'
import DateRangePicker from '../../common/forms/dateRangePicker'
import PublicationCheckboxForm from './publicationCheckboxForm'
import configPublication from "../../../config/publication.json"

export const MassiveConsumptionsForm = () => {

  const [range, setRange] = useState({
    date_edition_from: '',
    date_edition_to: ''
  })

  const pointsOfSale = useSelector(selectAllPointsOfSale)
  const pointsOfSaleStatus = useSelector(state => state.pointsOfSale.status)
  const { pointOfSale: userPointOfSale, role } = useSelector(
    state => state.auth.user
  )
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const idEditor = role && role.startsWith('editor') ? userPointOfSale : 1;
  const publicationsList = Object.keys(configPublication[idEditor]).map(publication => {return publication})

  const onChangeRange = value => {
    const newRange = {
      date_edition_from: value[0]?.getTime(),
      date_edition_to: value[1]?.getTime()
    }
    setRange(newRange)
  }

  const twoMonthsMinDate = (date) => {
    const selectedDate = date;
    selectedDate?.setHours(0, 0, 0, 0); 
    const twoMonthsBefore = new Date(selectedDate?.getFullYear(), selectedDate?.getMonth() - 2, selectedDate?.getDate());
    return twoMonthsBefore;
  }

  const oneYearMinDate = () => {
    const currDate = new Date();
    const yearBefore = currDate.getFullYear() - 1;
    currDate.setFullYear(yearBefore);
    currDate.setHours(0,0,0,0);
    return currDate;
  }
  
  const minDate = oneYearMinDate();

  const getMaxDate = () => {
    const currDate = new Date();
    const maxDate = new Date();
    maxDate.setDate((currDate).getDate() -1);
    return maxDate;
  }
  const maxDate = getMaxDate()
  
  const initialFormValues = {
    point_of_sale: '',
    card_number: '',
    publication_id: publicationsList,  //array of publication values by idEditor
    date_edition_from: '',
    date_edition_to: ''
  }

  useEffect(() => {
    if (role === Role.Marina && pointsOfSaleStatus === 'idle') {
      dispatch(fetchPointsOfSale())
    }
  }, [pointsOfSaleStatus, role, dispatch])

  const handleSubmit = async ({card_number, point_of_sale, publication_id, date_edition_from, date_edition_to}) => {

    await massiveConsumptionsService.insert({
      card_number: card_number,
      point_of_sale: point_of_sale,
      publication_id: publication_id,
      date_edition_from: date_edition_from ? date_edition_from.getTime() : "",
      date_edition_to: date_edition_to ? date_edition_to.getTime() : "",
    })
    .then(response => {
      if (response.status === 200) {
        enqueueSnackbar(response.message, {
          variant: 'success'
        })
      } else {
        enqueueSnackbar('No hemos podido enviar tu solicitud de venta masiva: ' + response.message, {
          variant: 'error'
        })
      }
    })
    .catch(err => {
      enqueueSnackbar('No hemos podido enviar tu solicitud de venta masiva: ' + err.message, {
        variant: 'error'
      })
    })
  }

  const validationSchema = Yup.object().shape({
    point_of_sale: Yup.string().required("'Punto de venta' es un campo requerido"),
    card_number: Yup.string()
      .required("'Número de tarjeta' es un campo requerido")
      .matches(/^[0-9]+$/, 'El valor debe ser numérico')
      .min(16, 'El número debe contener 16 dígitos')
      .max(16, 'El número debe contener 16 dígitos'),

    date_edition_from: Yup.date().nullable().required("'Fecha de Edición' es un campo requerido").test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_edition_to = this.parent.date_edition_to;
      if (!value && date_edition_to){
          return false; // No válido por rango abierto en date_edition_from
      }
      return true;
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value < minDate){
        return false;
      }
      return true
    }).test('invalid-range-60-days','Rango de fechas superior a 60 días',function(value){
      const date_edition_to = this.parent.date_edition_to;
      const minDateRestricted = twoMonthsMinDate(date_edition_to);
      if (value < minDateRestricted){
        return false;
      }
      return true
    }),
    date_edition_to: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_edition_from = this.parent.date_edition_from;
      if (!value && date_edition_from){
          return false; // No válido por rango abierto en date_edition_to
      }
      return true;
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value > maxDate){
        return false;
      }
      return true
    })
  });

  return (
    <Box mb={5}>
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          setFieldValue,
          isSubmitting,
          values,
          isValid,
          touched,
          errors
        }) => (
          <Box
            component={Form}
            sx={{
              '& .MuiTextField-root': {
                marginTop: 1,
                marginBottom: 1,
                width: '100'
              },
              '& .MuiTextField-root:last-of-type': { marginBottom: 2 },
              '& .MuiAutocomplete-root .MuiTextField-root:last-of-type': { marginBottom: 1 },
              '& .MuiTextField-root:first-of-type': { marginTop: 0 }
            }}
            autoComplete='off'
          >

            <Box sx={{ width: '100%', marginBottom: 1 }}>
              <Autocomplete
                id='point-of-sale'
                size='small'
                options={ pointsOfSale }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Punto de venta"
                    error={touched.point_of_sale && Boolean(errors.point_of_sale)}
                  />
                )}
                getOptionLabel={(option) => (option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id} >
                      {(option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                    </li>
                  )
                }}
                onChange={(event, value) => {
                  setFieldValue("point_of_sale", value?.id ? value.id : '');
                }}
                sx={{ maxWidth: 489.55 }}
              >
              </Autocomplete>
              {(touched.point_of_sale && errors.point_of_sale) && <FormHelperText htmlFor='point-of-sale' error>{errors.point_of_sale}</FormHelperText>}
            </Box>
            <Box>
              <Field
                as={TextField}
                label='Número de tarjeta'
                type='text'
                name='card_number'
                onChange={handleChange('card_number')}
                value={values.card_number}
                fullWidth
                autoComplete='off'
                size='small'
                error={touched.card_number && Boolean(errors.card_number)}
                helperText={touched.card_number && errors.card_number}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <QRButton fieldName='card_number' setFieldValue={setFieldValue} />
                    </InputAdornment>
                  )
                }}
                sx={{ maxWidth: 489.55 }}
              ></Field>
            </Box>
           
            <PublicationCheckboxForm 
              idEditor={idEditor}
              values={values.publication_id} 
              publicationsListOrigin={publicationsList}
              handleChange={handleChange} 
              setFieldValue={setFieldValue}
            >
            </PublicationCheckboxForm>

            <Field
              component={DateRangePicker}
              onChangeRange={onChangeRange}
              dateFromFieldName="date_edition_from"
              dateToFieldName="date_edition_to"
              startText='Fecha edición'
              middleText='hasta'
              endText='Fecha edición'
              maxDate={maxDate}
              minDate={minDate}
            />

            <Box>
              <SubmitButton
                text='Procesar ventas'
                isValid={isValid}
                isSubmitting={isSubmitting}
              ></SubmitButton>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  )
}
