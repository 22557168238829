import React, { useContext } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import { ModalContext } from '../../../common/providers/ModalProvider'

export default function Modal () {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const modal = useContext(ModalContext)

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={modal.modal.open}
        onClose={modal.remove}
        aria-labelledby='responsive-dialog-title'
      >
        {!modal.modal.variant ? (
          <>
            <DialogTitle id='responsive-dialog-title'>
              {modal.modal.title}
            </DialogTitle>
            <DialogContent>{modal.modal.content}</DialogContent>
            <DialogActions>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  modal.modal.onSubmit()
                  modal.remove()
                }}
                style={{ display: 'flex', marginLeft: 'auto' }}
              >
                {modal.modal.confirmText || 'Aceptar'}
              </Button>
              <Button 
                variant='outlined'
                onClick={() => {
                  modal.remove()
                }}>
                Cancelar
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <Box sx={{ textAlign: 'right', p: 1 }}>
              <IconButton onClick={modal.remove}>
                <CloseIcon />
              </IconButton>
            </Box>
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: fullScreen ? '0' : '360px'
              }}
            >
              {modal.modal.variant === 'error' && (
                <ErrorIcon color="error" fontSize='large' sx={{ mt: 2, mb: 1 }} />
              )}
              {modal.modal.variant === 'success' && (
                <CheckCircleIcon color="success" fontSize='large' sx={{ mt: 2, mb: 1 }} />
              )}
              <Typography id='responsive-dialog-title' sx={{ mt: 1, mb: 2, textAlign: 'center', fontSize: '1.25rem', mx: fullScreen ? '0' : '8px' }}>
                {modal.modal.content}
              </Typography>
            </DialogContent>
            <DialogActions>
              {modal.modal.secondaryBtnText && (
                <>
                  <Button
                    onClick={() => {
                      if (modal.modal.secondaryBtnHandler) {
                        modal.modal.secondaryBtnHandler()
                      }
                      modal.remove()
                    }}
                    sx={{ mr: 1 }}
                  >
                    {modal.modal.secondaryBtnText}
                  </Button>
                  <Divider orientation="vertical" flexItem />
                </>
              )}
              <Button
                onClick={() => {
                  if (modal.modal.onSubmit) {
                    modal.modal.onSubmit()
                  }
                  modal.remove()
                }}
              >
                Aceptar
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  )
}
