import Box from "@mui/material/Box";
import { Button, Stack } from "@mui/material";

export const StepperNavButtons = ({
  steps,
  activeStep,
  handleBack,
  handleNext,
  handleReset,
  handleSendReport,
  isValid,
  dirty,
  dirtyInitialValues
}) => {
  
  return (
    
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Button
        color="primary"
        variant="outlined"
        disabled={activeStep === 0 || activeStep === steps.length}
        onClick={handleBack}
        sx={{ mr: 1 }}
      >
        Volver
      </Button>
      <Box sx={{ flex: "1 1 auto" }} />

      <Stack spacing={2} direction="row">
        {console.log("dirtyInitial: ", dirtyInitialValues, "isValid: ", isValid)}
        {activeStep === steps.length - 1 ? (
          <Button
            onClick={handleSendReport}
            variant="outlined"
            color="secondary"
            disabled={!dirtyInitialValues || !isValid}
          >
            Generar Informe
          </Button>
        ) : activeStep === steps.length ? (
          <Button variant="outlined" onClick={handleReset}>Nuevo Informe</Button>
        ) : (
          <Button onClick={handleNext} disabled={(!dirty && !dirtyInitialValues) || !isValid} variant="contained" color="secondary">
            Continuar
          </Button>
        )}
      </Stack>
    </Box>
  );
};
