import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Box } from "@mui/material";

export const StepperBarGuide = ({ steps, activeStep }) => {
  console.log('STEP: ',activeStep);
  return (
    <Box sx={{my:5}}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              sx={{
                "& .MuiStepLabel-iconContainer .Mui-completed, & .MuiStepLabel-iconContainer .Mui-active":
                  {
                    color: "#CB3A2A", // circle color (Completed and Active)
                  },
              }}
            >
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};
