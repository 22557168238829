import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Field, Form, Formik } from "formik";
import { SubmitButton } from "../../../../components/common/forms/submitButton";
import { useDispatch } from "react-redux";
import { login } from "../../../../common/features/auth/authSlice";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import userService from "../../../../services/user.service";


const initialFormValues = { password: "", password2: "" };

export const UserValidatedForm = ({ email, code }) => {

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema =  Yup.object().shape({
    password: Yup.string()
      .min(8,'La contraseña debe tener como mínimo 8 caracteres')
      .required("La contraseña no puede estar vacía"),
    password2: Yup.string()
      .required("La contraseña no puede estar vacía")
      .oneOf([Yup.ref("password"), null], "Las contraseñas deben coincidir"),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      //let codeTemp = Math.random().toString(36).slice(-8);
      const password = values.password;

      /*await dispatch(
        confirmSignUp({
          username: email,
          code: code,
          codeTemp: codeTemp,
        })
      ).unwrap();*/

      await dispatch(
        login({ email, password: code, newPassword: password })
      ).unwrap();

      //activating user in postgres
      let user = {}
      user.emailAddress = email
      userService.update({user: user })

      setSubmitting(false);

      enqueueSnackbar("Usuario activado correctamente", {
        variant: "success",
      });

    } 
    catch (err) {
      console.log(err);
      setSubmitting(false);
      setErrors({ password: err.message });
      enqueueSnackbar(
       "Se ha producido un error en la activación de cuenta. Comprueba si el usuario introducido ya ha sido activado previamente.",
        {
          variant: "error",
        }
      )
    }
  }

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, values, isSubmitting, isValid, touched, errors}) => (
        <Box
          component={Form}
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100" },
          }}
          autoComplete="off"
        >
          <Field
            as={TextField}
            type="password"
            name="password"
            label="Introduzca el nuevo password"
            onChange={handleChange}
            value={values.password || ""}
            fullWidth
            autoComplete="on"
            size="small"
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
          />
          <Field
            as={TextField}
            type="password"
            name="password2"
            label="Repita de nuevo el password"
            onChange={handleChange}
            value={values.password2 || ""}
            fullWidth
            autoComplete="on"
            size="small"
            error={touched.password2 && Boolean(errors.password2)}
            helperText={touched.password2 && errors.password2}
          />
          <SubmitButton
            text="Continuar"
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
        </Box>
      )}
    </Formik>
  );
};
