import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const insert = async ({ client }) => {
  const userToken = await authService.getAuthToken()
  return fetch(
    apiService.getEndpoint("CLIENT"),
    {
      method: "POST",
      headers: {
        Authorization: userToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(client),
    }
  ).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const update = async ({ client }) => {
  const userToken = await authService.getAuthToken()
  return fetch(
    apiService.getEndpoint("CLIENT"),
    {
      method: "PUT",
      headers: {
        Authorization: userToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(client),
    }
  ).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const getAll = async ({ per_page, page, card_number, alias }) => {
  const userToken = await authService.getAuthToken()
  const params = new URLSearchParams({
    per_page: per_page,
    page: page,
  })
  if (card_number) {
    params.set('card', card_number)
  }
  if (alias) {
    params.set('alias', alias)
  }
  return fetch(
    apiService.getEndpoint("CLIENT") + '?' + params.toString(),
    {
      method: "GET",
      headers: {
        Authorization: userToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => {logout(err)});
};

const deletebyId = async ({ clientId }) => {
  const userToken = await authService.getAuthToken()
  return fetch(
    apiService.getEndpoint("CLIENT") + `/${clientId}`,
    {
      method: "DELETE",
      headers: {
          Authorization: userToken,
      },
    }
  ).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const getById = async ({ clientId }) => {
  const userToken = await authService.getAuthToken()
  return fetch(
    apiService.getEndpoint("CLIENT") + `/${clientId}`,
    {
      method: "GET",
      headers: {
        Authorization: userToken,
      },
    }
  )
    .then((response) => response.json())
    .catch((err) => {logout(err)});
};

const clientService = {
  insert,
  update,
  getAll,
  deletebyId,
  getById
};

export default clientService;