import { useEffect, useState } from "react";
import Helmet from 'react-helmet'
import { ClientForm } from '../../components/clients/forms/ClientForm'
import { useParams } from "react-router-dom";
import clientService from "../../services/client.service";
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { Box, CircularProgress } from "@mui/material";
import { updateClients } from "../../common/features/clients/clientsSlice";
import { useDispatch } from "react-redux";

export default function Client() {
  let { clientId } = useParams();
  const [client, setClient] = useState(null)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const dispatch = useDispatch()

  const handleSubmit = async (values, { resetForm }) => {
    dispatch(updateClients({
        client: {
          id: client.id,
          alias: values.alias,
          card: values.card_number
        }
      }))
      .then(response => {
        console.log("response", response)
        if (response.payload && response.payload.status === 200){
          enqueueSnackbar(response.payload.message, {
            variant: 'success'
          })
          history.push('/clients')
        } else {
          enqueueSnackbar(response.payload.message, {
            variant: 'error'
          })
        }
      })
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
  }

  useEffect(() => {
    async function fetchClientInfo() {
      await clientService.getById({ clientId })
      .then(response => {
        console.log("response", response)
        if (response.status === 200){
          setClient(response.client)
        } else {
          enqueueSnackbar(response.message, {
            variant: 'error'
          })
          history.push('/clients')
        }
      })
      .catch(err => {
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
        history.push('/clients')
      })
    }
    fetchClientInfo()
  }, [clientId]);

  if (!client) {
    return (
      <>
        <h3>Editar cliente</h3>
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          <CircularProgress size={24} color="secondary" />
        </Box>
      </>
    );
  }

  return (
    <>
      <h3>Editar cliente</h3>
      <Helmet>
        <title>Editar cliente - Marina BCN</title>
      </Helmet>
      <ClientForm
        cardNumber={client.card}
        alias={client.alias}
        handleSubmit={handleSubmit}
      />
    </>
  )
}
