import {
  Box,
  InputAdornment,
  TextField,
} from '@mui/material'
import { SubmitButton } from '../../common/forms/submitButton'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import QRButton from '../../common/forms/QRButton'

export const ClientForm = (props) => {
  const initialFormValues = {
    card_number: props.cardNumber || '',
    alias: props.alias || '',
  }

  const schema = Yup.object().shape({
    alias: Yup.string().required('El campo "Nombre" es obligatorio'),
    card_number: Yup.string()
      .required('El número de tarjeta no puede estar vacío')
      .matches(/^[0-9]+$/, 'El valor debe ser numérico')
      .min(16, 'El número debe contener 16 dígitos')
      .max(16, 'El número debe contener 16 dígitos'),
  })

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={props.handleSubmit}
      validationSchema={schema}
    >
      {({ handleChange, values, isSubmitting, isValid, touched, errors, setFieldValue }) => (
        <Box
          component={Form}
          sx={{
            '& .MuiTextField-root': {
              marginTop: 1,
              marginBottom: 1,
              width: '100'
            },
            '& .MuiTextField-root:last-of-type': { marginBottom: 2 },
            '& .MuiTextField-root:first-of-type': { marginTop: 0 }
          }}
          autoComplete='off'
        >

          <Box>
            <Field
              as={TextField}
              label='Número de tarjeta'
              type='text'
              name='card_number'
              onChange={handleChange('card_number')}
              value={values.card_number}
              fullWidth
              autoComplete='off'
              size='small'
              sx={{ maxWidth: 515 }}
              error={touched.card_number && Boolean(errors.card_number)}
              helperText={touched.card_number && errors.card_number}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <QRButton fieldName='card_number' setFieldValue={setFieldValue} />
                  </InputAdornment>
                )
              }}
            ></Field>
          </Box>

          <Box>
            <Field
              as={TextField}
              label='Nombre'
              type='text'
              name='alias'
              onChange={handleChange('alias')}
              value={values.alias}
              fullWidth
              autoComplete='off'
              size='small'
              sx={{ maxWidth: 515 }}
              error={touched.alias && Boolean(errors.alias)}
              helperText={touched.alias && errors.alias}
            ></Field>
          </Box>

          <SubmitButton
            text='Guardar'
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </Box>
      )}
    </Formik>
  )
}
