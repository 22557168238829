import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Field, Form, Formik } from "formik";
import { SubmitButton } from "../../../../components/common/forms/submitButton";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { login } from "../../../../common/features/auth/authSlice";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";

const initialFormValues = { email: "", password: "" };

export const LoginForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Formato de correo inválido')
      .required('El campo "email" es obligatorio'),
    password: Yup.string().required("La contraseña no puede estar vacía"),
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const { email, password } = values;
      await dispatch(login({ email, password })).unwrap();
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        enqueueSnackbar("El usuario está pendiente de activación", {
          variant: "error",
        });
        history.push("/activate-account");
      } else {
        setSubmitting(false);
        setErrors({ password: err.message });
      }
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, values, isSubmitting, isValid, touched, errors }) => (
        <Box
          component={Form}
          sx={{
            "& .MuiTextField-root": {
              marginTop: 1,
              marginBottom: 1,
              width: "100",
            },
            "& .MuiTextField-root:last-of-type": { marginBottom: 2 },
            "& .MuiTextField-root:first-of-type": { marginTop: 0 },
          }}
          autoComplete="off"
        >
          <Field
            as={TextField}
            type="email"
            name="email"
            label="Email"
            onChange={handleChange}
            value={values.email}
            fullWidth
            autoComplete="on"
            size="small"
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
          <Field
            as={TextField}
            type={showPassword ? "text" : "password"}
            name="password"
            label="Contraseña"
            onChange={handleChange}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            value={values.password}
            fullWidth
            autoComplete="on"
            size="small"
          />
          <SubmitButton
            text="Iniciar sesión"
            isSubmitting={isSubmitting}
            isValid={isValid}
          />
        </Box>
      )}
    </Formik>
  );
};
