import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const get = async ({ numCard, dateEdition, day, role }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpointbyRole(role, "SERVICES") + `?numCard=${numCard}&dateEdition=${dateEdition.getTime()}&day=${day}`, {
    method: "GET",
    headers: {
      Authorization: userToken,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data.services;
    })
    .catch((err) => {logout(err)});
};

const servicesService = {
  get,
};

export default servicesService;
