import { ExpandLess, ExpandMore, LogoutOutlined } from '@mui/icons-material'
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from '../../../../common/features/auth/authSlice'
import { Role } from '../../../../common/roles/role'
import withRole from '../../../../common/roles/withRole'
import { capitalizeWord } from '../../../../helpers'
import { CustomListItem } from './CustomListItem'
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';

export const DrawerContent = ({ role, email, handleDrawerToggle }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [collapseOpen, setCollapseOpen] = useState(false)

  const handleCollapseClick = () => {
    setCollapseOpen(!collapseOpen)
  }

  async function handleLogout () {
    await dispatch(logout())
    history.push('/login')
  }

  const RestrictedMarinaButton = withRole([Role.Marina])(CustomListItem)
  const RestrictedKioskoButton = withRole([Role.Kiosko])(CustomListItem)
  const RestrictedPointsOfSaleButton = withRole([Role.Marina, Role.Editor, Role.Consulta, Role.Distribuidor])(CustomListItem)
  const RestrictedReportsButton = withRole([Role.Marina, Role.Editor, Role.Distribuidor])(CustomListItem)
  const RestrictedConsumtionButton = withRole([Role.Marina, Role.Kiosko, Role.Distribuidor])(CustomListItem)
  const RestrictedMarinaKioskoButton = withRole([Role.Marina, Role.Kiosko])(CustomListItem)

  return (
    <>
      <div>
        <Toolbar />
        <Divider />
        <>
          <ListItemButton onClick={handleCollapseClick}>
          <ListItemIcon sx={{ minWidth: "32px" }}><StoreMallDirectoryIcon/></ListItemIcon>
            <ListItemText primary='Consumos' />
            {collapseOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={collapseOpen} timeout='auto'>
            <List onClick={handleDrawerToggle} sx={{pt:0}}>
              <CustomListItem
                to='/consumptions'
                text='Consulta'
                exactRouteMatch={true}
              />
              <CustomListItem
                to='/day-consumptions'
                text='Consumo del día'
                exactRouteMatch={true}
              />
              <CustomListItem
                to='/historical-consumptions'
                text='Consumo histórico'
                exactRouteMatch={true}
              />
              <RestrictedConsumtionButton
                to='/consumptions/new'
                text='Venta'
                exactRouteMatch={true}
              />
              <RestrictedMarinaButton
                to='/consumptions/massive'
                text='Venta masiva'
                exactRouteMatch={true}
              />
              <RestrictedMarinaKioskoButton
                to='/consumptions/new-delayed'
                text='Venta atrasada'
                exactRouteMatch={true}
              />
              <RestrictedConsumtionButton
                to='/consumptions/cancel'
                text='Anular venta'
                exactRouteMatch={true}
              />
              <RestrictedMarinaButton
                to='/historical-consumptions/cancel'
                text='Anular histórico'
                exactRouteMatch={true}
              />
            </List>
            <Divider />
            <Box sx={{marginBottom:1}}></Box>
          </Collapse>
        </>

        <List onClick={handleDrawerToggle} disablePadding  >
          <RestrictedKioskoButton to='/clients' text='Mis Clientes' />
          <RestrictedMarinaButton to='/users' text='Usuarios' />
          <RestrictedPointsOfSaleButton to='/points-of-sale' text='Puntos de venta' />
          {/* <RestrictedPointsOfSaleButton to='/locations' text='Ubicaciones' /> */}
          <RestrictedReportsButton to='/reports' text='Informes' />
        </List>
      </div>
      <div>
        <Divider />
        <Box m={2} sx={{ textAlign: 'end' }}>
          <Typography noWrap variant="body2" sx={{fontStyle: 'italic', mb: 0.5, mr: 1}}>{email}</Typography>
          {capitalizeWord(role)}
          <IconButton onClick={handleLogout} aria-label='logout'>
            <LogoutOutlined />
          </IconButton>
        </Box>
      </div>
    </>
  )
}
