import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "../../views/auth/login";
import NotFound from "../../views/notFound/NotFound";
import AuthenticatedRoute from "./AuthenticatedRoute";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import ForgotPassword from "../../views/auth/forgotPassword";
import ActivateAccount from "../../views/auth/activateAccount";
import Users from "../../views/users";
import Home from "../../views/home";
import Consumptions from "../../views/consumptions";
import SalePoints from "../../views/salePoints";
import NewUser from "../../views/users/newUser";
import NewConsumption from "../../views/consumptions/newConsumption";
import Clients from "../../views/clients";
import NewClient from "../../views/clients/newClient";
import Client from "../../views/clients/client";
import NewSalePoint from "../../views/salePoints/newSalePoint";
import { Role } from "../roles/role";
import HistoricalConsumptions from "../../views/consumptions/historicalConsumptions";
import DayConsumptions from "../../views/consumptions/dayConsumptions";
import { User } from "../../views/users/user";
import Reports from "../../views/reports";
import MassiveConsumptions from "../../views/consumptions/massiveConsumptions";
import { SalePoint } from "../../views/salePoints/salePoint";
import { NewAddress } from "../../views/addresses/newAddress";

export default function Routes() {
  return (
    <Switch>
      <UnauthenticatedRoute exact path="/login" component={Login} />
      <UnauthenticatedRoute
        exact
        path="/forgot-password"
        component={ForgotPassword}
      />
      <UnauthenticatedRoute
        exact
        path="/activate-account"
        component={ActivateAccount}
      />
      <AuthenticatedRoute exact path="/home" component={Home} />
      <AuthenticatedRoute
        exact
        path="/consumptions"
        key="consumptions"
        component={Consumptions}
      />
      <AuthenticatedRoute
        exact
        path="/consumptions/cancel"
        key="consumptions/cancel"
        component={Consumptions}
      />
      <AuthenticatedRoute
        exact
        path="/consumptions/new"
        key="consumptions/new"
        component={NewConsumption}
      />
      <AuthenticatedRoute
        exact
        path="/consumptions/massive"
        key="consumptions/massive"
        component={MassiveConsumptions}
      />
      <AuthenticatedRoute
        exact
        path="/consumptions/new-delayed"
        key="consumptions/new-delayed"
        component={NewConsumption}
      />
      <AuthenticatedRoute
        exact
        path="/clients"
        component={Clients}
      />
      <AuthenticatedRoute
        exact
        path="/clients/new"
        component={NewClient}
        roles={[Role.Kiosko]}
      />
      <AuthenticatedRoute
        exact
        path="/client/:clientId"
        component={Client}
        roles={[Role.Kiosko]}
      />
      <AuthenticatedRoute
        exact
        path="/historical-consumptions"
        key="historical-consumptions"
        component={HistoricalConsumptions}
      />
      <AuthenticatedRoute
        exact
        path="/day-consumptions"
        key="day-consumptions"
        component={DayConsumptions}
      />
      <AuthenticatedRoute
        exact
        path="/historical-consumptions/cancel"
        key="historical-consumptions/cancel"
        component={HistoricalConsumptions}
        roles={[Role.Marina]}
      />
      <AuthenticatedRoute
        exact
        path="/reports"
        key="reports"
        component={Reports}
      />
      <AuthenticatedRoute exact path="/users" component={Users} />
      <AuthenticatedRoute
        exact
        path="/users/new"
        component={NewUser}
        roles={[Role.Marina]}
      />
      <AuthenticatedRoute
        exact
        path="/user/:userId"
        component={User}
        roles={[Role.Marina]}
      />
      <AuthenticatedRoute exact path="/points-of-sale" component={SalePoints} />
      <AuthenticatedRoute
        exact
        path="/points-of-sale/new"
        component={NewSalePoint}
        roles={[Role.Marina]}
      />
      <AuthenticatedRoute
        exact
        path="/point-of-sale/:posId"
        component={SalePoint}
        roles={[Role.Marina]}
      />
      <AuthenticatedRoute
        exact
        path="/addresses/new"
        component={NewAddress}
        roles={[Role.Marina]}
      />
      <Route exact path="/">
        <Redirect to="/home" />
      </Route>
      <Route component={NotFound} />
    </Switch>
  );
}
