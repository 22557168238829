import { Box, Stack, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { green } from "@mui/material/colors";
import { useSelector } from "react-redux";

export const ClientsList = ({
  setFieldValue,
  setSource,
  clients,
  showAll,
  setSelectedClient,
  isDelayedConsumption,
}) => {

  const clientsStatus = useSelector((state) => state.clients.status);

  const columns = [
    {
      headerName: "Mis Clientes",
      title: "alias",
      field: "alias",
      flex: 1,
      minWidth: 160,
      sortable: false,
      renderHeader: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AssignmentIndIcon sx={{ mr: 1 }} />
          <span>{showAll ? `Mis clientes` : "Pendientes"} </span>
        </Box>
      ),
    },
    {
      headerName: "",
      title: "card",
      field: "card",
      flex: 0,
      minWidth: 160,
      sortable: false,
    },
  ];

  const showConsumedRowColor = (params) => {
    if (isDelayedConsumption === false) {
      if (params.row.consumed === 1) {
        return "consumed";
      } else {
        return "";
      }
    }
  };

  return (
    <DataGrid
      sx={{
        height: 280,
        width: "100%",
        minHeight: "50vh",
        marginBottom: 1,
        marginTop: 2.5,
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-root .MuiDataGrid-cell:focus":
          {
            outline: "none",
          },
        "&.MuiDataGrid-root .MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-row.consumed": {
          backgroundColor: green[50],
        },
      }}
      onSelectionModelChange={(newSelectionModel) => {
        let selectedClient = clients.filter((client) =>
          newSelectionModel.includes(client.id)
        );
        selectedClient.forEach((client) => {
          setFieldValue("card_number", client.card);
          setSelectedClient(client);
          setSource(3);
        });
      }}
      density="compact"
      disableColumnMenu
      hideFooterSelectedRowCount
      rows={clients}
      columns={columns}
      pageSize={50}
      rowsPerPageOptions={[50]}
      getRowClassName={(params) => showConsumedRowColor(params)}
      pagination
      components={{
        NoRowsOverlay: () => (
          <Stack
            height="100%"
            alignItems="center"
            justifyContent="center"
            sx={{ mt: 20 }}
          >
            {clientsStatus === "loading" ? (
              <CircularProgress
                size={24}
                color="secondary"
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              "No hay resultados"
            )}
          </Stack>
        ),
      }}
    />
  );
};
