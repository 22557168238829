import React from 'react';
import { ListItem, Grid, FormControl, InputLabel, Select, TextField, FormHelperText, MenuItem } from '@mui/material';
import { Field } from 'formik';
import configTypesOfAddresses from "../../../config/typesOfAddresses.json"

const typesOfAddresses = Object.keys(configTypesOfAddresses).map(type => (
  <MenuItem key={type} value={type}>{type}</MenuItem>
));

const AddressForm = ({
  values,
  touched,
  errors,
  onChangeField,
  setFieldValue
}) => {



  return (
    <>
      <ListItem>
        <Grid container item spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={2}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel
                id='type-label'
                error={Boolean(touched.address_type && errors.address_type)}
              >
                Tipo
              </InputLabel>
              <Field
                component={Select}
                labelId='type-label'
                id='address_type'
                name='address_typer'
                label='Tipo'
                value={values.address_type}
                fullWidth
                onChange={(event) => { onChangeField(event, "address_type", setFieldValue, "address") }}
                size='small'
                error={touched.address_type && Boolean(errors.address_type)}
              >
                {typesOfAddresses}
              </Field>
              {touched.address_type && errors.address_type && (
                <FormHelperText htmlFor='address_type' error>
                  {errors.address_type}
                </FormHelperText>
              )}
            </FormControl>

          </Grid>
          <Grid item xs={8}>
            <Field as={TextField} label="Nombre Calle" name="address_name" onChange={(event) => { onChangeField(event, "address_name", setFieldValue, "address") }}
              value={values.address_name} fullWidth size="small" error={Boolean(errors.address_name)} helperText={errors.address_name} />
          </Grid>
          <Grid item xs={2}>
            <Field as={TextField} label="Número" name="address_number" onChange={(event) => { onChangeField(event, "address_number", setFieldValue, "address") }}
              value={values.address_number} fullWidth size="small" error={Boolean(errors.address_number)} helperText={errors.address_number} />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container item spacing={2} sx={{ mb: 1 }}>
          <Grid item xs={6} md={2}>
            <Field as={TextField} label="Planta" name="address_floor" onChange={(event) => { onChangeField(event, "address_floor", setFieldValue, "address") }}
              value={values.address_floor} fullWidth size="small" error={Boolean(errors.address_floor)} helperText={errors.address_floor} />
          </Grid>
          <Grid item xs={6} md={2}>
            <Field as={TextField} label="Puerta" name="address_door" onChange={(event) => { onChangeField(event, "address_door", setFieldValue, "address") }}
              value={values.address_door} fullWidth size="small" error={Boolean(errors.address_door)} helperText={errors.address_door} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Field as={TextField} label="Adicional" name="address_additional" onChange={(event) => { onChangeField(event, "address_additional", setFieldValue, "address") }}
              value={values.address_additional} fullWidth size="small" error={Boolean(errors.address_additional)} helperText={errors.address_additional} />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container item spacing={3} sx={{ mb: 1 }}>
          <Grid item xs={6} md={9}>
            <Field as={TextField} label="Ciudad" name="address_city" onChange={(event) => { onChangeField(event, "address_city", setFieldValue, "address") }}
              value={values.address_city} fullWidth size="small" error={Boolean(errors.address_city)} helperText={errors.address_city} />
          </Grid>
          <Grid item xs={6} md={3}>
            <Field as={TextField} label="Código Postal" name="address_postcode" onChange={(event) => { onChangeField(event, "address_postcode", setFieldValue, "address") }}
              value={values.address_postcode} fullWidth size="small" error={Boolean(errors.address_postcode)} helperText={errors.address_postcode} />
          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container item spacing={3} sx={{ mb: 1 }}>
          <Grid item xs={6}>
            <Field as={TextField} label="Provincia" name="address_province" onChange={(event) => { onChangeField(event, "address_province", setFieldValue, "address") }}
              value={values.address_province} fullWidth size="small" error={Boolean(errors.address_province)} helperText={errors.address_province} />
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ width: '100%' }}>
              <InputLabel
                id='type-label'
                error={Boolean(touched.address_country && errors.address_country)}
              >
                País
              </InputLabel>
              <Field
                component={Select}
                labelId='type-label'
                id='address_country'
                name='address_country'
                label='Tipo'
                value={values.address_country}
                fullWidth
                onChange={(event) => { onChangeField(event, "address_country", setFieldValue, "address") }}
                size='small'
                error={touched.address_country && Boolean(errors.address_country)}
              >
                <MenuItem value="España">España</MenuItem>
                <MenuItem value="Andorra">Andorra</MenuItem>
              </Field>
              {touched.address_country && errors.address_country && (
                <FormHelperText htmlFor='address_country' error>
                  {errors.address_country}
                </FormHelperText>
              )}
            </FormControl>

          </Grid>
        </Grid>
      </ListItem>
      <ListItem>
        <Grid container item spacing={3} sx={{ mb: 1 }}>
          <Grid item xs={6}>
            <Field as={TextField} label="Latitud" name="address_latitude" onChange={(event) => { onChangeField(event, "address_latitude", setFieldValue, "address") }}
              value={values.address_latitude} fullWidth size="small" error={Boolean(errors.address_latitude)} helperText={errors.address_latitude} />
          </Grid>
          <Grid item xs={6}>
            <Field as={TextField} label="Longitud" name="address_longitude" onChange={(event) => { onChangeField(event, "address_longitude", setFieldValue, "address") }}
              value={values.address_longitude} fullWidth size="small" error={Boolean(errors.address_longitude)} helperText={errors.address_longitude} />
          </Grid>
        </Grid>
      </ListItem>
    </>
  );
};

export default AddressForm;
