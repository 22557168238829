
import { Field, Form, Formik } from 'formik'
import { Autocomplete, Stack, TextField, Button, Select, MenuItem, FormControl, FormControlLabel, InputLabel, Grid, Switch, IconButton, InputAdornment } from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"; 
import { Link } from 'react-router-dom'

import { Role } from "../../common/roles/role"
import { SubmitButton } from '../common/forms/submitButton'
import withRole from "../../common/roles/withRole"

export const SalePointFilters = ({ rowsState, setRowsState, showMap, handleShowMap, distributors, role, pointsOfSale, selectedSalePoint, setSelectedSalePoint }) => {

    
    const handleSubmit = async values => {
        const { id_pointSale, id_distributor, address_full } = values
        setRowsState(prev => ({ ...prev, page: 0, id_pointSale: id_pointSale, id_distributor: id_distributor, address_full: address_full, }))
    }
    
    const RestrictedButton = withRole([Role.Marina])(Button)

    const onChangeSalePoint = (setFieldValue, newValue) => {
        setSelectedSalePoint(newValue)
        setFieldValue("id_pointSale", newValue.id? newValue.id : '')
    }

    return (
        <Grid container>
            <Grid item xs={12} md={5} mb={5}>
                <Formik
                    initialValues={rowsState}
                    onSubmit={handleSubmit}
                >
                    {({ handleChange, isSubmitting, values, isValid, touched, errors, setFieldValue }) => (
                        
                        <Stack direction="column"
                        component={Form}
                            sx={{
                                '& .MuiTextField-root,& .MuiFormControl-root': {
                                    marginBottom: 2,
                                }
                            }}
                            autoComplete='off'
                        >
                            <Autocomplete
                                id='id_pointSale'
                                size='small'
                                loading
                                loadingText={"Cargando..."}
                                options={pointsOfSale}
                                value={selectedSalePoint ? selectedSalePoint : null}
                                onChange={(event, newValue, reason) => {
                                    if (reason === 'clear') {
                                      onChangeSalePoint(setFieldValue,'')
                                      return
                                    } else {
                                      onChangeSalePoint(setFieldValue, newValue) 
                                    }
                                  }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Punto de venta"
                                        error={touched.id_pointSale && Boolean(errors.id_pointSale)}

                                    />
                                )}
                                getOptionLabel={(option) => (option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id} >
                                            {(option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                                        </li>
                                    )
                                }}
                            >
                            </Autocomplete>
                            
                            { [Role.Marina, Role.Editor, Role.Consulta].includes(role) && (
                            <FormControl sx={{ width: '100%', marginBottom: 1 }}>
                                <InputLabel
                                    id='distributor-label'
                                >
                                    Distribuidor
                                </InputLabel>
                                <Field
                                    component={Select}
                                    labelId='distributor-label'
                                    id='id_distributor'
                                    name='id_distributor'
                                    label='Distribuidora'
                                    value={values.id_distributor}
                                    fullWidth
                                    onChange={handleChange('id_distributor')}
                                    size='small'
                                    error={touched.id_distributor && Boolean(errors.id_distributor)}
                                    endAdornment={ values.id_distributor && (
                                        <InputAdornment position="end">
                                          <IconButton
                                            sx={{padding:0.5, mr:2.3}}
                                            onClick={() => {setFieldValue("id_distributor", '')}}
                                          >
                                            <ClearIcon sx={{fontSize: 20}} />
                                          </IconButton>
                                        </InputAdornment>
                                      )}
                                >
                                    {distributors &&
                                        distributors.length > 0 &&
                                        distributors.map(dist => {
                                            return (
                                                <MenuItem key={dist.id} value={dist.id}>
                                                    {dist.name}
                                                </MenuItem>
                                            )
                                        })}
                                </Field>
                            </FormControl>
                            )}

                            <Field
                                as={TextField}
                                label='Dirección'
                                type='text'
                                name='address_full'
                                onChange={handleChange('address_full')}
                                value={values.address_full}
                                autoComplete='off'
                                size='small'
                                fullWidth
                            ></Field>

                            <Stack justifyContent={"space-between"} direction="row">
                                <Stack spacing={1} direction="row">
                                    <SubmitButton
                                        text='Buscar'
                                        isValid={isValid}
                                        isSubmitting={isSubmitting || rowsState.loading}
                                    ></SubmitButton>
                                    <RestrictedButton
                                        component={Link}
                                        to='/points-of-sale/new'
                                        variant='outlined'
                                        color='primary'
                                    >
                                        Nuevo
                                    </RestrictedButton>
                                </Stack>
                                <Stack>
                                    <FormControlLabel sx={{mr:1}} disabled={rowsState.loading? true: false}checked={showMap} control={<Switch color="secondary" onChange={handleShowMap} />} label="Mapa" />
                                </Stack>
                            </Stack>
                        </Stack>

                    )}
                </Formik>
            </Grid >
        </Grid>
    )

}