import configAPI from "../config/configApi.json";
//const endpoint =  configAPI['default'].SERVER_URL.replace('[API_GATEWAY_ID]',process.env.REACT_APP_API_GATEWAY_ID) +  "/" +  configAPI['default'].STAGE +  "/" +  configAPI['default'].API +  "/";

const getEndpoint = (resource) => {
  return getEndpointbyRole('default', resource)
}

const getEndpointbyRole = (prole, resource) => {
  let role = 'default'
  if (prole && prole.startsWith('editor')) role = 'editor';
  if (prole && prole.startsWith('distribuidor')) role = 'distributor';
  const endpoint =  configAPI[role].SERVER_URL.replace('[API_GATEWAY_ID]',process.env.REACT_APP_API_GATEWAY_ID) +  configAPI[role].STAGE +  configAPI[role].API;
  let _endpoint = endpoint+configAPI[role].RESOURCES[resource].ENDPOINT;
  return _endpoint;
}

const apiService = {
  getEndpoint,
  getEndpointbyRole
}

export default apiService
