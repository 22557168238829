import React from "react";
import { Box } from "@mui/material";
import { LoginForm } from "../../../components/auth/login/forms/loginForm";
import { Link } from "react-router-dom";

function Login() {
  return (
    <Box sx={{ maxWidth: 515, margin: '0 auto'}}>
      <h3>Introduce tu contraseña para iniciar sesión</h3>
      <LoginForm />
      <br />
      <Link to="/forgot-password">¿Olvidaste tu contraseña?</Link>
      <br />
    </Box>
  );
}
export default Login;
