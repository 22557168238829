import { Autocomplete, Box, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, TextField, Select } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { SubmitButton } from '../../common/forms/submitButton'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import * as Yup from 'yup'
import {
  fetchPointsOfSale,
  selectAllPointsOfSale
} from '../../../common/features/pointsOfSale/pointsOfSaleSlice'
import DateRangePicker from '../../common/forms/dateRangePicker'
import consumptionsService from '../../../services/consumptions.service'
import { Role } from '../../../common/roles/role'
import QRButton from '../../common/forms/QRButton'
import configDistributor from "../../../config/distributor.json"
import configPublication from "../../../config/publication.json"
import PublicationCheckboxForm from './publicationCheckboxForm'

export const HistoricalConsumptionsForm = ({
  setRange,
  setRangeEdition,
  rowsState,
  setRowsState,
  setPointOfSale,
  setSubscriptionNumber,
  setCardNumber,
  setDistributorId,
  setPublicationId,
  setSelectionModel
}) => {
  const pointsOfSale = useSelector(selectAllPointsOfSale)
  const pointsOfSaleStatus = useSelector(state => state.pointsOfSale.status)
  const { pointOfSale: userPointOfSale, role } = useSelector(
    state => state.auth.user
  )
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const idEditor = role && role.startsWith('editor') ? userPointOfSale : 1;
  const publicationsList = Object.keys(configPublication[idEditor]).map(publication => {return publication})

  const initialFormValues = {
    point_of_sale: '',
    date_from: '',
    date_to: '',
    date_edition_from: '',
    date_edition_to: '',
    subscription_number: '',
    card_number: '',
    distributor_id:'',
    publication_id: publicationsList,  //array of publication values by idEditor
  }

  useEffect(() => {
    if (role !== Role.Kiosko && pointsOfSaleStatus === 'idle') {
      dispatch(fetchPointsOfSale(role))
    }
  }, [pointsOfSaleStatus, role, dispatch])


  const onChangeRange = value => {
    const newRange = {
      date_from: value[0]?.getTime(),
      date_to: value[1]?.getTime()
    }
    setRange(newRange)
  }

  const onChangeRangeEdition = value => {
    const newRangeEdition = {
      date_edition_from: value[0]?.getTime(),
      date_edition_to: value[1]?.getTime()
    }
    setRangeEdition(newRangeEdition)
  }

  const twoMonthsMinDate = (date) => {
    const selectedDate = date;
    selectedDate?.setHours(0, 0, 0, 0); 
    const twoMonthsBefore = new Date(selectedDate?.getFullYear(), selectedDate?.getMonth() - 2, selectedDate?.getDate());
    return twoMonthsBefore;
  }

  const oneYearMinDate = () => {
    const currDate = new Date();
    const yearBefore = currDate.getFullYear() - 1;
    currDate.setFullYear(yearBefore);
    currDate.setHours(0,0,0,0);
    return currDate;
  }
  
  const minDate = oneYearMinDate();
  const maxDate = new Date();
  maxDate.setDate((maxDate).getDate() -1);

  const handleSubmit = async values => {
    
    const { point_of_sale, date_from, date_to, date_edition_from, date_edition_to, subscription_number, card_number, distributor_id, publication_id } = values
    
    setPointOfSale(point_of_sale)
    setSubscriptionNumber(subscription_number)
    setCardNumber(card_number)
    setDistributorId(distributor_id)
    setPublicationId(publication_id)

    // We clear the previous selection to fix https://github.com/mui/mui-x/issues/3614
    // TO DO: upgrade DataGrid package
    setSelectionModel([])

    setRowsState(prev => ({ ...prev, page: 0, loading: true }))

    const results = await consumptionsService.getAll({
      pointOfSale: point_of_sale,
      date_from: date_from ? date_from.getTime() : "" ,
      date_to: date_to ? date_to.getTime() : "",
      date_edition_from: date_edition_from ? date_edition_from.getTime() : "",
      date_edition_to: date_edition_to ? date_edition_to.getTime() : "",
      per_page: rowsState.pageSize,
      page: 1,
      subscription_number: subscription_number,
      card_number: card_number,
      id_distributor: distributor_id,
      id_publication: publication_id === "0" ? "" : publication_id,
      role
    })

    if (results.statusCode === 400) {
      let error = JSON.parse(results.body)
      enqueueSnackbar(error.message, {
        variant: 'error'
      })
      setRowsState(prev => ({
        ...prev,
        loading: false,
        total: 0,
        rows: [],
        grouped: []
      }))
    }   
    if (results.statusCode === 200) {
      if (results.consumptions.length === 0) {
        enqueueSnackbar(
          'No se encuentran consumos disponibles en la fecha indicada',
          {
            variant: 'error'
          }
        )
      }
      
      setRowsState(prev => ({
        ...prev,
        loading: false,
        total: results.total,
        rows: results.consumptions,
        grouped: results.grouped
      }))
    }
  }

  const validationSchema = Yup.object().shape({
    point_of_sale: Yup.string(),
    card_number: Yup.string()
      .matches(/^[0-9]+$/, 'El valor debe ser numérico')
      .min(16, 'El número debe contener 16 dígitos')
      .max(16, 'El número debe contener 16 dígitos'),

    date_from: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_to = this.parent.date_to;
      if (!value && date_to){
          return false; // No válido por rango abierto en date_from
      }
      return true;
    }).test('test-date-from', 'Selecciona un rango de fechas', function(value){
      const date_edition_from = this.parent.date_edition_from;
      const date_edition_to = this.parent.date_edition_to;
      const date_to = this.parent.date_to;
      if(!value && !date_to && !date_edition_from && !date_edition_to){
        return false //No válido en caso de ningún valor en ambos rangos
      }
      return true
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value < minDate){
        return false;
      }
      return true
    }).test('invalid-range-60-days','Rango de fechas superior a 60 días',function(value){
      const date_to = this.parent.date_to;
      const minDateRestricted = twoMonthsMinDate(date_to);
      if (value < minDateRestricted){
        return false;
      }
      return true
    }),
    date_to: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_from = this.parent.date_from;
      if (!value && date_from){
          return false; // No válido por rango abierto en date_to
      }
      return true;
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value > maxDate){
        return false;
      }
      return true
    }),
    date_edition_from: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_edition_to = this.parent.date_edition_to;
      if (!value && date_edition_to){
          return false; // No válido por rango abierto en date_edition_from
      }
      return true;
    }).test('invalid-range-60-days','Rango de fechas superior a 60 días',function(value){
      const date_edition_to = this.parent.date_edition_to;
      const minDateRestricted = twoMonthsMinDate(date_edition_to);
      if (value < minDateRestricted){
        return false;
      }
      return true
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value < minDate){
        return false;
      }
      return true
    }),
    date_edition_to: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_edition_from = this.parent.date_edition_from;
      if (!value && date_edition_from){
          return false; // No válido por rango abierto en date_edition_to
      }
      return true;
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value > maxDate){
        return false;
      }
      return true
    })
  });

  const distributorList = Object.keys(configDistributor[process.env.REACT_APP_ENVIRONMENT]).map(dist => (
    <MenuItem key={dist} value={dist}>{configDistributor[process.env.REACT_APP_ENVIRONMENT][dist]}</MenuItem>
  ));

  return (
    <Box mb={5}>
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleChange, handleBlur, isSubmitting, values, isValid, touched, errors, setFieldValue }) => (
         
         <Box
            component={Form}
            sx={{
              '& .MuiFormControl-root': {
                marginBottom: 2,
                width: '100'
              },
              '& .MuiAutocomplete-root .MuiFormControl-root': { marginBottom: 1 }
            }}
            autoComplete='off'
          >
            { [Role.Marina, Role.Editor, Role.Consulta, Role.Distribuidor].includes(role) && (
            <>
              <Box sx={{ width: '100%', marginBottom: 1 }}>
                <Autocomplete
                  id='point-of-sale'
                  size='small'
                  loading
                  loadingText={"Cargando..."}
                  options={ pointsOfSale }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Punto de venta"
                      error={touched.point_of_sale && Boolean(errors.point_of_sale)}
                    />
                  )}
                  getOptionLabel={(option) => (option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id} >
                        {(option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                      </li>
                    )
                  }}
                  onChange={(event, value) => {
                    setFieldValue("point_of_sale", value?.id ? value.id : '');
                  }}
                  sx={{ maxWidth: 489.55 }}
                >
                </Autocomplete>
                {(touched.point_of_sale && errors.point_of_sale) && <FormHelperText htmlFor='point-of-sale' error>{errors.point_of_sale}</FormHelperText>}
              </Box>
            </>
            )}
            
            { [Role.Marina, Role.Editor, Role.Consulta].includes(role) && (
            <>
              <FormControl fullWidth sx={{marginBottom:1}}>
                <InputLabel id="distributor-label">Distribuidor</InputLabel>
                <Field
                  component={Select}
                  labelId="distributor-label"
                  id="distributor"
                  label="Distribuidor"
                  name = "distributor_id"
                  value= {values.distributor_id}
                  onChange={handleChange('distributor_id')}
                  size="small"
                  sx={{ maxWidth: 489.55 }}

                  endAdornment={ values.distributor_id && (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{padding:0.5, mr:2.3}}
                        onClick={() => {setFieldValue("distributor_id", '')}}
                      >
                        <ClearIcon sx={{fontSize: 20}} />
                      </IconButton>
                    </InputAdornment>
                  )}
                >
                  {distributorList}
                </Field>
              </FormControl>
            </>
            )}

            <PublicationCheckboxForm 
              idEditor={idEditor}
              values={values} 
              publicationsListOrigin={publicationsList}
              handleChange={handleChange} 
              setFieldValue={setFieldValue}
            >
            </PublicationCheckboxForm>

            <Field
              component={DateRangePicker}
              onChangeRange={onChangeRange}
              dateFromFieldName="date_from"
              dateToFieldName="date_to"
              startText='Fecha consumo'
              middleText='hasta'
              endText='Fecha consumo'
              maxDate={maxDate}
              minDate={minDate}
            />

            <Field
              component={DateRangePicker}
              onChangeRange={onChangeRangeEdition}
              dateFromFieldName="date_edition_from"
              dateToFieldName="date_edition_to"
              startText='Fecha edición'
              middleText='hasta'
              endText='Fecha edición'
              maxDate={maxDate}
              minDate={minDate}
            /> 
            
            <Box>
              <Field
                as={TextField}
                label='Número de suscripción'
                type='text'
                name='subscription_number'
                onChange={handleChange('subscription_number')}
                value={values.subscription_number}
                fullWidth
                autoComplete='off'
                size='small'
                sx={{ maxWidth: 489.55 }}
                error={touched.subscription_number && Boolean(errors.subscription_number)}
                helperText={touched.subscription_number && errors.subscription_number}
              ></Field>
            </Box>

            <Box>
              <Field
                as={TextField}
                label='Número de tarjeta'
                type='text'
                name='card_number'
                onChange={handleChange('card_number')}
                value={values.card_number}
                fullWidth
                autoComplete='off'
                size='small'
                sx={{ maxWidth: 489.55 }}
                error={touched.card_number && Boolean(errors.card_number)}
                helperText={touched.card_number && errors.card_number}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <QRButton fieldName='card_number' setFieldValue={setFieldValue} />
                    </InputAdornment>
                  )
                }}
              ></Field>
            </Box>

            <SubmitButton
              text='Buscar'
              isValid={isValid}
              isSubmitting={isSubmitting}
            ></SubmitButton>
          </Box>
        )}
      </Formik>
    </Box>
  )
}
