import { Stack } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { formatDate } from '../../helpers'

export const ConsumptionsTable = ({
  selectionModel,
  setSelectionModel,
  prevSelectionModel,
  rowsState,
  setRowsState,
  enableSelectToCancel = false
}) => {
  const columns = [
    {
      headerName: 'Num. tarjeta',
      title: 'num_card',
      field: 'num_card',
      flex: 1,
      minWidth: 160
    },
    {
      headerName: 'Publicación',
      title: 'name',
      field: 'name',
      flex: 1,
      minWidth: 180
    },
    {
      headerName: 'Copias',
      title: 'copies',
      field: 'copies',
      width: 90,
      align: 'center'
    },
    {
      headerName: 'Fecha consumo',
      title: 'date_consumed',
      field: 'date_consumed',
      minWidth: 150,
      align: "center",
      valueFormatter: ({ value }) => {
        return formatDate(value, "es", {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'});
      }
    }
  ]

  const { pageSize, total, rows } = rowsState

  const handlePaginate = async page => {
    prevSelectionModel.current = selectionModel

    setRowsState(prev => ({ ...prev, page: page, loading: true }))

    setTimeout(() => {
      setSelectionModel(prevSelectionModel.current)
    })
  }

  return (
    <div style={{ height: 400, width: '100%', marginBottom: 16 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={total}
        {...rowsState}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        pagination
        checkboxSelection={enableSelectToCancel}
        disableSelectionOnClick={!enableSelectToCancel}
        paginationMode='server'
        onPageSizeChange={pageSize =>
          setRowsState(prev => ({ ...prev, pageSize }))
        }
        onPageChange={page => handlePaginate(page)}
        onSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel)
        }}
        selectionModel={selectionModel}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No hay resultados
            </Stack>
          )
        }}
      />
    </div>
  )
}
