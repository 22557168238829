import { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { UsersFilters } from '../../components/users/UsersFilters'
import { UsersTable } from '../../components/users/UsersTable'
import userService from '../../services/user.service'

export default function Users () {
  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 50,
    total: 0,
    rows: [],
    loading: false,
    email: '',
    point_of_sale:''
  })

  useEffect(() => {
    let active = true
    console.log(rowsState)
    ;(async () => {
      setRowsState(prev => ({ ...prev, loading: true }))

      const results = await userService.getAll({
        per_page: rowsState.pageSize,
        page: rowsState.page + 1,
        email: rowsState.email,
        pointOfSale: rowsState.point_of_sale,
        status: rowsState.status
      }).catch(e=> console.log(e))

      if (!active) {
        return
      }

      setRowsState(prev => ({
        ...prev,
        loading: false,
        total: results?.total || 0,
        rows: results?.users || []
      }))
    })()

    return () => {
      active = false
    }
  }, [rowsState.page, rowsState.pageSize, rowsState.email, rowsState.point_of_sale, rowsState.status])

  return (
    <>
      <h3>Usuarios</h3>
      <Helmet>
        <title>Usuarios - Marina BCN</title>
      </Helmet>
      <UsersFilters
        rowsState={rowsState}
        setRowsState={setRowsState}
      />
      <UsersTable rowsState={rowsState} setRowsState={setRowsState} />
    </>
  )
}
