import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from '@mui/material'

export const HistoricalConsumptionsSummary = ({ grouped, total }) => {
  return (
    <TableContainer component={Paper} variant="outlined" sx={{ mb: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Publicación</TableCell>
            <TableCell align="right">Copias</TableCell>
            <TableCell align="right">Anulados</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {grouped.map(element => (
            <TableRow key={element.name}>
              <TableCell>{element.name}</TableCell>
              <TableCell align="right">{element.copies}</TableCell>
              <TableCell align="right">{element.disabled}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>Total</TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
              {grouped.reduce((previousValue, currentValue) => {
                return previousValue + (Number.isInteger(currentValue.copies) ? currentValue.copies : 0);
              }, 0)}
            </TableCell>
            <TableCell align="right" sx={{ fontWeight: 'bold', fontSize: '0.875rem' }}>
              {grouped.reduce((previousValue, currentValue) => {
                return previousValue + (Number.isInteger(currentValue.disabled) ? currentValue.disabled : 0);
              }, 0)}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
};
