import React, { useRef, useEffect, useState } from 'react'
import { Button, Stack } from '@mui/material'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Role } from '../../common/roles/role'
import { PointsOfSaleFilter } from '../../components/consumptions/PointsOfSaleFilter'
import consumptionsService from '../../services/consumptions.service'
import { ConsumptionsTable } from '../../components/consumptions/ConsumptionsTable'
import { useSnackbar } from 'notistack'
import { SubmitButton } from '../../components/common/forms/submitButton'
import Helmet from 'react-helmet'
import useModal from '../../common/hooks/useModal'
import { fetchClients } from '../../common/features/clients/clientsSlice'
import { IsRouteMatch } from '../../components/utils/IsRouteMatch'
import withRole from '../../common/roles/withRole'

export default function Consumptions() {
  const { pointOfSale: userPointOfSale, role } = useSelector(
    state => state.auth.user
  )

  const [rowsState, setRowsState] = useState({
    page: 0,
    pageSize: 50,
    total: 0,
    rows: [],
    loading: false
  })

  const { enqueueSnackbar } = useSnackbar()
  const [selectionModel, setSelectionModel] = useState([])
  const [pointOfSale, setPointOfSale] = useState('')
  const prevSelectionModel = useRef(selectionModel)
  const [loadingDisableConsumption, setLoadingDisableConsumption] = useState(false)

  const range = {
    date_from: new Date().getTime(),
    date_to: new Date().getTime()
  }

  const enableSelectToCancel = IsRouteMatch({ route: "/consumptions/cancel" })

  const modal = useModal();
  const dispatch = useDispatch();

  useEffect(() => {
    let active = true

    if (role === Role.Kiosko) {
      setPointOfSale(userPointOfSale)
    }

    if (role === Role.Kiosko && !pointOfSale) {
      return
    }

    ; (async () => {
      setRowsState(prev => ({ ...prev, loading: true }))

      const results = await consumptionsService.getAll({
        pointOfSale,
        date_from: range.date_from,
        date_to: range.date_to,
        per_page: rowsState.pageSize,
        page: rowsState.page + 1,
        role,
        day: true
      }).catch(e => console.log(e))

      if (!active) {
        return
      }

      setRowsState(prev => ({
        ...prev,
        loading: false,
        total: results?.total || 0,
        rows: results?.consumptions || []
      }))

      setTimeout(() => {
        setSelectionModel(prevSelectionModel.current)
      })
    })()

    return () => {
      active = false
    }
  }, [pointOfSale, rowsState.page, rowsState.pageSize])

  const handleUnsubscribe = () => {
    setLoadingDisableConsumption(true)
    selectionModel.forEach(element => {
      consumptionsService
        .deletebyId({ idConsumption: element, role })
        .then(response => {
          if (response.status !== 200) {
            enqueueSnackbar(response.message, {
              variant: 'error'
            })
            return
          } else {
            enqueueSnackbar(response.message, {
              variant: 'success'
            })
            setRowsState(prev => ({
              ...prev,
              rows: prev.rows.filter(row => row.id !== element)
            }))
          }
        })
        .catch(err => {
          enqueueSnackbar(err.message, {
            variant: 'error'
          })
        })
        .finally(() => {
          setLoadingDisableConsumption(false)
          if (Role.Kiosko === role) {
            dispatch(fetchClients());
            console.log('dispatch fetchClients')
          }
        })
    })
  }

  const RestrictedConsumtionButton = withRole([Role.Marina, Role.Kiosko])(Button)

  return (
    <>
      <h3>{IsRouteMatch({ route: "/consumptions/cancel" }) ? "Anular venta del día" : "Consulta del día"}</h3>
      <Helmet>
        <title>Consulta del día - Marina BCN</title>
      </Helmet>

      {[Role.Marina, Role.Editor, Role.Consulta, Role.Distribuidor].includes(role) && (
        <>
          <PointsOfSaleFilter
            pointOfSale={pointOfSale}
            setPointOfSale={setPointOfSale}
          />
        </>
      )}

      <ConsumptionsTable
        range={range}
        rowsState={rowsState}
        setRowsState={setRowsState}
        selectionModel={selectionModel}
        setSelectionModel={setSelectionModel}
        prevSelectionModel={prevSelectionModel}
        pointOfSale={pointOfSale}
        enableSelectToCancel={enableSelectToCancel}
      />
      <Stack spacing={2} direction='row'>
        {!enableSelectToCancel && (
          <RestrictedConsumtionButton
            component={Link}
            to='/consumptions/new'
            variant='contained'
            color='secondary'
          >
            Venta
          </RestrictedConsumtionButton>
        )}
        {enableSelectToCancel && (
          <SubmitButton
            text='Anular venta'
            onClick={(evt) =>
              modal.show({
                title: "Anular venta",
                content: "¿Estás seguro de que quieres anular la venta?",
                onSubmit: () => {
                  handleUnsubscribe();
                },
              })
            }
            isValid={selectionModel && selectionModel.length > 0}
            variant='outlined'
            isSubmitting={loadingDisableConsumption}
          />
        )}
      </Stack>
    </>
  )
}
