import { useState } from "react";
import Helmet from "react-helmet";
import { StepperNavButtons } from "../../components/reports/StepperNavButtons";
import { StepperBarGuide } from "../../components/reports/StepperBarGuide";
import { SetReport } from "../../components/reports/forms/SetReport";
import { StepperConsumptionsForm } from "../../components/reports/forms/StepperConsumptionsForm";
import { StepperUsersForm } from "../../components/reports/forms/StepperUsersForm";
import { StepperPointsOfSaleForm } from "../../components/reports/forms/StepperPointsOfSaleForm";
import { StepperSummary } from "../../components/reports/StepperSummary";
import reportsService from "../../services/reports.service";
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";
import configPublication from "../../config/publication.json"


export default function Reports() {

  const { user } = useSelector((state) => state.auth);
  const { email, role } = user;

  const steps = ["Selecciona tipo de informe", "Añade filtros"];
  
  const [activeStep, setActiveStep] = useState(0);
  
  const { pointOfSale: userPointOfSale } = useSelector(
    state => state.auth.user
  )
  const idEditor = role && role.startsWith('editor') ? userPointOfSale : 1;
  const publicationsList = Object.keys(configPublication[idEditor]).map(publication => {return publication})

  const [reportDataToSend, setReportDataToSend] = useState({
    report_user_email: email,
    report_type:'',
    report_title: '',
    user_status:'',
    date_from:'',
    date_to:'',
    date_edition_from:'',
    date_edition_to:'',
    date_ending_from:'',
    date_ending_to:'',
    subscription_number:'',
    card_number:'',
    point_of_sale:'',
    type_of_sale:'',
    distributor_id:'',
    publication_id: publicationsList //array of publication values by idEditor
  })

  const [selectedPointOfSale, setSelectedPointOfSale] = useState(null);
  const [inputSelectedPointOfSale, setInputSelectedPointOfSale] = useState('');
  
  const [range, setRange] = useState({
    date_from: '',
    date_to: ''
  })

  const [rangeEdition, setRangeEdition] = useState({
    date_edition_from: '',
    date_edition_to: ''
  })

  const [rangeEnding, setRangeEnding] = useState({
    date_ending_from: '',
    date_ending_to: ''
  })

  // StepperNavButtons management

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setReportDataToSend({
      report_user_email: email,
      report_type:'',
      report_title: '',
      user_status:'',
      date_from:'',
      date_to:'',
      date_edition_from:'',
      date_edition_to:'',
      date_ending_from:'',
      date_ending_to:'',
      subscription_number:'',
      card_number:'',
      point_of_sale:'',
      point_of_sale_name:'',
      type_of_sale:'',
      distributor_id:'',
      publication_id: publicationsList //array of publication values by idEditor
    })
    setRange({ date_from: '', date_to: '' })
    setRangeEdition({ date_edition_from: '', date_edition_to: '' })
    setRangeEnding({ date_ending_from: '', date_ending_to: ''})
    setSelectedPointOfSale(null)
    setInputSelectedPointOfSale('')
  };

  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  
  const handleSendReport = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setReportDataToSend(reportDataToSend)
    console.log('Generando Informe: ', reportDataToSend)

    reportsService.insert({
      reportDataToSend: reportDataToSend,
      role: role
    })
    .then(response => {
      if (response.status === 200) {
        enqueueSnackbar('Solicitud de informe enviado correctamente', {
          variant: 'success'
        })
        history.push('/reports')
      } else {
        enqueueSnackbar('No hemos podido enviar tu solicitud de Informe: ' + response.message, {
          variant: 'error'
        })
      }
    })
    .catch(err => {
      enqueueSnackbar('No hemos podido enviar tu solicitud de Informe: ' + err.message, {
        variant: 'error'
      })
    })
  };

  return (
    <>
      <h3>
        Nuevo Informe{" "}
        {reportDataToSend.report_type && activeStep === 1? (
          <span style={{fontWeight:"normal", fontSize:"initial", marginLeft:6}}>
            <i>{reportDataToSend.report_title}</i>
          </span>
        ) : (
          ""
        )}
      </h3>
      <Helmet>
        <title>Informes - Marina BCN</title>
      </Helmet>

      <StepperBarGuide steps={steps} activeStep={activeStep} />

      <>
        {activeStep === 0 ? (
          <>
            {/* <p>Paso {activeStep + 1}</p> */}

            <SetReport
              setReportDataToSend={setReportDataToSend}
              reportDataToSend={reportDataToSend}
              setRange={setRange}
              setRangeEdition={setRangeEdition}
              setRangeEnding={setRangeEnding}
              setSelectedPointOfSale={setSelectedPointOfSale}
              setInputSelectedPointOfSale={setInputSelectedPointOfSale}
              steps={steps}
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              role={role}
            />
          </>
        ) : activeStep === 1 ? (
          <>
            {reportDataToSend.report_type === "Consumos" ? (
              <>
                <StepperConsumptionsForm
                  setReportDataToSend={setReportDataToSend}
                  reportDataToSend={reportDataToSend}
                  range={range}
                  setRange={setRange}
                  rangeEdition={rangeEdition}
                  setRangeEdition={setRangeEdition}
                  selectedPointOfSale={selectedPointOfSale}
                  setSelectedPointOfSale={setSelectedPointOfSale}
                  inputSelectedPointOfSale={inputSelectedPointOfSale}
                  setInputSelectedPointOfSale={setInputSelectedPointOfSale}
                  steps={steps}
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  handleSendReport={handleSendReport}
                />
              </>
            ) : reportDataToSend.report_type === "Usuarios" ? (
              <>
                <StepperUsersForm
                  setReportDataToSend={setReportDataToSend}
                  reportDataToSend={reportDataToSend}
                  range={range}
                  setRange={setRange}
                  selectedPointOfSale={selectedPointOfSale}
                  setSelectedPointOfSale={setSelectedPointOfSale}
                  inputSelectedPointOfSale={inputSelectedPointOfSale}
                  setInputSelectedPointOfSale={setInputSelectedPointOfSale}
                  steps={steps}
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  handleSendReport={handleSendReport}
                />
              </>
            ) : reportDataToSend.report_type === "Puntos de venta" ? (
              <>
                <StepperPointsOfSaleForm
                  setReportDataToSend={setReportDataToSend}
                  reportDataToSend={reportDataToSend}                
                  range={range}
                  setRange={setRange}
                  rangeEnding={rangeEnding}
                  setRangeEnding={setRangeEnding}
                  steps={steps}
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  handleSendReport={handleSendReport}
                />
              </>
            ) : (
              <>
               <p>Error: Tipo de informe no seleccionado</p>
              </>
            )}
          </>
        ) : (
          <>
            <StepperSummary
              reportDataToSend={reportDataToSend}
            />
            <StepperNavButtons
              steps={steps}
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              handleReset={handleReset}
              handleSendReport={handleSendReport}
            />
          </>
        )}
      </>
    </>
  );
}
