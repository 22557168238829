import { Avatar, Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const HomeItem = ({ to, text, icon }) => {
  return (
    <Grid item xs={6} md={4} lg={3}>
      <Card elevation={2}>
        <CardActionArea component={Link} to={to}>
          <CardContent>
            <Avatar>{icon}</Avatar>
            <Typography>{text}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};