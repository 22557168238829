// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { memo } from "react";
import { grey } from "@mui/material/colors";
import LocationOnIcon from '@mui/icons-material/LocationOn';

// A pushpin that changes its colour to orange when selected
export default memo(({size, isSelected, color}) => {
  const colors = [grey[600],'#cb3a2a'];    

  return (
    <LocationOnIcon fontSize={"large"} sx={{ color: colors[color] }}/>
  );
});