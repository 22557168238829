import { Box, Button, Stack, TextField } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { SubmitButton } from '../common/forms/submitButton'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { Role } from '../../common/roles/role'
import withRole from '../../common/roles/withRole'

export const ClientFilters = ({
  rowsState,
  setRowsState
}) => {

  const initialFormValues = {
    card_number: '',
    alias: '',
  }

  const handleSubmit = async values => {
    const { card_number, alias } = values
    setRowsState(prev => ({ ...prev, page: 0, cardNumber: card_number, alias: alias }))
  }

  const validationSchema = Yup.object().shape({
    card_number: Yup.string(),
    alias: Yup.string(),
  })

  const RestrictedButton = withRole([Role.Kiosko])(Button)

  return (
    <Box mb={5}>
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ handleChange, isSubmitting, values, isValid, touched, errors }) => (
          <Box
            component={Form}
            sx={{
              '& .MuiTextField-root': {
                marginTop: 1,
                marginBottom: 1,
                width: '100'
              },
              '& .MuiTextField-root:last-of-type': { marginBottom: 2 },
              '& .MuiTextField-root:first-of-type': { marginTop: 0 }
            }}
            autoComplete='off'
          >

            <Box>
              <Field
                as={TextField}
                label='Número de tarjeta'
                type='text'
                name='card_number'
                onChange={handleChange('card_number')}
                value={values.card_number}
                fullWidth
                autoComplete='off'
                size='small'
                sx={{ maxWidth: 515 }}
                error={touched.card_number && Boolean(errors.card_number)}
                helperText={touched.card_number && errors.card_number}
              ></Field>
            </Box>

            <Box>
              <Field
                as={TextField}
                label='Nombre'
                type='text'
                name='alias'
                onChange={handleChange('alias')}
                value={values.alias}
                fullWidth
                autoComplete='off'
                size='small'
                sx={{ maxWidth: 515 }}
                error={touched.alias && Boolean(errors.alias)}
                helperText={touched.alias && errors.alias}
              ></Field>
            </Box>

            <Stack spacing={1} direction="row">
              <SubmitButton
                text='Buscar'
                isValid={isValid}
                isSubmitting={isSubmitting || rowsState.loading}
              ></SubmitButton>
              <RestrictedButton
                component={Link}
                to='/clients/new'
                variant='outlined'
                color='primary'
              >
                Nuevo
              </RestrictedButton>
            </Stack>
          </Box>
        )}
      </Formik>
    </Box>
  )
}
