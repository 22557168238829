import React, { useEffect, useState } from "react";
import { useParams} from "react-router-dom";
import userService from "../../services/user.service";
import { PreEditUserForm } from "../../components/users/forms/PreEditUserForm";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";

export const User = () => {
  
  let { userId } = useParams();
  const [user, setUser] = useState(null);

  const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true)
  const [isAction, setIsAction] = useState(false)
  
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {  
    if(!isAction){
      async function fetchUserInfo() {
        const response = await userService.getById({ userId })
        setUser(response) 
        setIsLoadingUserInfo(false)
      }
      fetchUserInfo()
    }
  }, [userId, isAction])

  // action Reactivate User

  const handleReactivateUser = (userId) => {
    setIsAction(true)
    userService
        .reactivateUserById({ userId }) 
        .then((response) => {
          if (response.status !== 200) {
            enqueueSnackbar(response.message, {
              variant: "error",
            });
            return;
          } else {
            enqueueSnackbar(response.message, {
              variant: "success",
            });
            setUser({...user, status: 'VALIDATED'})
          }
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        })
        .finally(
        () => {setIsAction(false)}
        );
    };

  // action Disable User

  const handleDisableUser = (userId) => {
  setIsAction(true)
  userService
    .deletebyId({ userId })
    .then((response) => {
      if (response.status !== 200) {
        enqueueSnackbar(response.message, {
          variant: "error",
        });
        return;
      } else {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
        setUser({...user, status: 'DISABLED'})
      }
    })
    .catch((err) => {
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    })
    .finally(
      () => {setIsAction(false)}
      );
  };

   // action Validar User

   const handleValidateUser = (userId) => {
    userService
       .validateById({ userId })
       .then((response) => {
         if (response.status !== 200) {
           enqueueSnackbar(response.message, {
             variant: "error",
           });
           return;
         } else {
           enqueueSnackbar(response.message, {
             variant: "success",
           });
           setUser({...user, status: 'VALIDATED'})
         }
       })
       .catch((err) => {
         enqueueSnackbar(err.message, {
           variant: "error",
         });
       });
   };

  // action Reenviar Invitación

  const handleSendInvitation = (userId) => {
    userService
        .resendCodeById({ userId }) 
        .then((response) => {
          if (response.status !== 200) {
            enqueueSnackbar(response.message, {
              variant: "error",
            });
            return;
          } else {
            enqueueSnackbar(response.message, {
              variant: "success",
            });
          }
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
        });
  };
  
  if ((isLoadingUserInfo && !user) || isAction) {
    return <CircularProgress size={24} color="secondary" />    
  }
  
  return (
    <>
      {user &&  
        <PreEditUserForm 
          userId={userId} 
          userInfo={user} 
          handleDisableUser={handleDisableUser} 
          handleReactivateUser={handleReactivateUser}
          handleValidateUser={handleValidateUser}
          handleSendInvitation={handleSendInvitation}
        />
      }
    </>
  )
}