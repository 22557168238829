import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const insert = async ({ reportDataToSend, role }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpointbyRole(role, "REPORTS"), {
    method: "POST",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(reportDataToSend),
    
  })
  .then((response) => response.json())
  .catch((err) => {logout(err)});
};

const reportsService = {
  insert
};

export default reportsService;
