import apiService from "./api.service";
import authService from "./auth.service";
import { logout } from "../helpers/logout";

const insert = async ({ user }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("USER"), {
    method: "POST",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  }).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const update = async ({ user }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("USER"), {
    method: "PUT",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  }).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const getAll = async ({ per_page, page, email, pointOfSale, status }) => {
  const userToken = await authService.getAuthToken()
  const params = new URLSearchParams({
    per_page: per_page,
    page: page,
  })
  if (email) {
    params.set('email', email)
  }
  if (pointOfSale) {
    params.set('pointOfSale', pointOfSale)
  }
  if (status) {
    params.set('status', status)
  }
  
  return fetch(
    apiService.getEndpoint("USER") + '?' + params.toString(),
    {
      method: "GET",
      headers: {
        Authorization: userToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then((response) => response.json())
    .then((data) => data)
    .catch((err) => {logout(err)});
};

const deletebyId = async ({ userId }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("USER") + `/${userId}`, {
    method: "DELETE",
    headers: {
      Authorization: userToken,
    },
  }).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const getById = async ({ userId }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("USER") + `/${userId}`, {
    method: "GET",
    headers: {
      Authorization: userToken,
    },
  })
    .then((response) => response.json())
    .then((data) => data.user)
    .catch((err) => {logout(err)});
};

const updatebyId = async ({ user, userId }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("USER")+ `/${userId}`, {
    method: "PUT",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  }).then((response) => response.json())
  .catch((err) => {logout(err)});;
};

const validateById = async ({ userId }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("USER") + `/${userId}/bck`, {
    method: "PUT",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ action: "validate" }),
  }).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const resendCodeById = async ({ userId }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("USER") + `/${userId}/bck`, {
    method: "PUT",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ action: "resend_code" }),
  }).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const reactivateUserById = async ({ userId }) => {
  const userToken = await authService.getAuthToken()
  return fetch(apiService.getEndpoint("USER") + `/${userId}/bck`, {
    method: "PUT",
    headers: {
      Authorization: userToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ action: "reactivate_user" }),
  }).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const forgotPassword = async ({ user }) => {
  return fetch(apiService.getEndpoint("FORGOT_PASSWORD"), {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  }).then((response) => response.json())
  .catch((err) => {logout(err)});
};

const userService = {
  insert,
  update,
  getAll,
  deletebyId,
  getById,
  updatebyId,
  validateById,
  forgotPassword,
  resendCodeById,
  reactivateUserById
};

export default userService;