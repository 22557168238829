import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Autocomplete, Grid, Stack, List, ListItem, Divider, Button, Card, CardHeader, CardContent, Paper, Avatar } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import StorefrontIcon from '@mui/icons-material/Storefront';

import AddressForm from './AddressForm';
import DatePicker from '../../common/forms/datepicker';
import { getFormattedFieldName } from "../../../helpers";
import { SubmitButton } from '../../common/forms/submitButton';
import addressService from '../../../services/address.service';
import pointOfSaleService from '../../../services/pointsOfSale.service';
import { fetchPointsOfSale } from "../../../common/features/pointsOfSale/pointsOfSaleSlice";
import { fetchAddresses, selectAllAddresses } from "../../../common/features/addresses/addressesSlice";
import { fetchDistributors, selectAllDistributors } from '../../../common/features/distributors/distributorsSlice';
import useModal from "../../../common/hooks/useModal";
import { getSimilarity } from "../../utils/Similarity";

export const NewSalePointForm = () => {

  const [isSubmited, setIsSubmited] = useState(false)
  const { role } = useSelector(state => state.auth.user)
  const history = useHistory()
  const modal = useModal()
  const newItems = ["name", "id_distributor", "code_distributor", "code_editor", "phone", "nif", "email", "start_date", "end_date", "description"];

  const getMinDate = () => {
    const currDate = new Date();
    const minDate = new Date();
    minDate.setDate((currDate).getDate() + 1);
    minDate.setHours(0, 0, 0, 0);
    return minDate;
  }

  const getMaxDate = () => {
    const currDate = new Date();
    const futureDate = new Date(currDate);
    futureDate.setFullYear(currDate.getFullYear() + 100);
    futureDate.setHours(0, 0, 0, 0);
    return futureDate;
  }

  const initialValues = {
    name: '',
    id_distributor: '',
    code_distributor: '',
    code_editor: '',
    start_date: new Date(),
    end_date: getMaxDate(),
    phone: '',
    nif: '',
    email: '',
    description: '',
    id_address: '',
    address_type: '',
    address_name: '',
    address_number: '',
    address_floor: '',
    address_door: '',
    address_additional: '',
    address_postcode: '',
    address_city: '',
    address_province: '',
    address_country: '',
    address_latitude: '',
    address_longitude: '',

  }

  const [dataSalePoint, setDataSalePoint] = useState({
    name: '',
    id_distributor: '',
    code_distributor: '',
    code_editor: '',
    start_date: new Date(),
    end_date: getMaxDate(),
    phone: '',
    nif: '',
    email: '',
    description: '',
    id_address: '',
  })

  const [isNewAddress, setIsNewAddress] = useState(false)

  const [dataAddress, setDataAddress] = useState({
    address_type: '',
    address_name: '',
    address_number: '',
    address_floor: '',
    address_door: '',
    address_additional: '',
    address_postcode: '',
    address_city: '',
    address_province: '',
    address_country: '',
    address_latitude: '',
    address_longitude: '',
  })

  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  //Status SalePoints Redux
  const pointsOfSaleStatus = useSelector(state => state.pointsOfSale.status)

  //Load distributors Redux
  const distributors = useSelector(selectAllDistributors)
  const distributorStatus = useSelector(state => state.distributors.status)

  useEffect(() => {
    if (distributorStatus === 'idle') {
      dispatch(fetchDistributors(role))
    }
  }, [distributorStatus, dispatch])

  //Load Addresses Redux
  const addresses = useSelector(selectAllAddresses)
  const addressesStatus = useSelector(state => state.addresses.status)

  useEffect(() => {
    if (addressesStatus === 'idle') {
      dispatch(fetchAddresses(role))
    }
  }, [addressesStatus, dispatch])


  const schema = Yup.object().shape({
    id_distributor: Yup.string().required('El campo "Distribuidor" es requerido'),
    code_distributor: Yup.string()
      .required('El campo "Código Distribuidor" es requerido')
      .matches(/^[0-9]+$/, 'El valor debe ser numérico')
      .max(8, 'El código no puede ser mayor de 8 dígitos'),
    code_editor: Yup.string()
      .required('El campo "Código Editor" es requerido')
      .matches(/^[0-9]+$/, 'El valor debe ser numérico')
      .max(6, 'El código no puede ser mayor de 6 dígitos'),
    name: Yup.string().required('El campo "Nombre" es requerido').max(50, 'El "Nombre" no puede ser mayor de 50 carácteres'),
    nif: Yup.string()
      .matches(/^\d{8}[A-HJ-NP-TV-Z]$|^[ABCDEFGHJKLMNPQRSUVW]\d{7}[0-9A-J]$|^[Aa][0-9]{6}[A-Za-z0-9]$/, 'NIF/CIF no válido'),
    phone: Yup.string()
      .max(13, 'El teléfono no puede ser mayor de 13 dígitos')
      .matches(/^\+(34|376)\d{6,14}$/, "El prefijo (+34) o (+376) es requerido"),
    start_date: Yup.date().typeError('Fecha no válida').required('El campo "Fecha de Alta" es requerido').test('test-out-of-range', 'Fecha mínima fuera de rango disponible', function (value) {
      if (value < new Date().setHours(0, 0, 0, 0)) {
        return false;
      }
      return true
    }),
    end_date: Yup.date().typeError('Fecha no válida').required('El campo "Fecha de Baja" es requerido').test('test-out-of-range', 'Fecha fuera de rango disponible', function (value) {
      if (value < getMinDate()) {
        return false;
      }
      return true
    }),
    address_latitude: Yup.string()
      .matches(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/, 'La latitud debe ser un valor válido'),
    address_longitude: Yup.string()
      .matches(/^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/, 'La longitud debe ser un valor válido')
  })

  

  const onChangeField = (event, item, setFieldValue, objectTarget) => {
    const value = event.target.value
    setFieldValue(item, value)
    if (objectTarget === "salePoint") {
      setDataSalePoint({ ...dataSalePoint, [item]: value })
    }
    if (objectTarget === "address") {
      setDataAddress({ ...dataAddress, [item]: value })
    }
  }

  const onChangeIdDistributor = (event, setFieldValue) => {
    const value = event.target.value
    setFieldValue("id_distributor", value)
    setDataSalePoint({ ...dataSalePoint, id_distributor: value })
  }

  const onChangeDate = (item, value) => {
    setDataSalePoint({ ...dataSalePoint, [item]: value })
  };

  const newAddress = () => {
    setIsNewAddress(true)
    setDataSalePoint({ ...dataSalePoint, id_address: null })
  }

  const checkSimilarity = (element, index, array) => {
    if (dataAddress["address_id"] !== element["id"]) {
      if (dataAddress["address_name"] && dataAddress["address_number"] && dataAddress["address_postcode"]) {
          if (dataAddress["address_postcode"] === element["postcode"]){
              let similarity = getSimilarity(
                  element["name​"] + "-" + element["number​"] ,
                  dataAddress["address_name"] + "-" + dataAddress["address_number"] 
              );
              if (similarity * 100 > 75) {
                  return true;
              }
          }
      }
    }
    return false;
  };



  const handleSubmit = async (values, { setSubmitting }) => {
    setIsSubmited(true)
    if (isNewAddress) {
      try {
        //check if a similar address already exists
        let addressExists = addresses.find(checkSimilarity);

        if ((addressExists)) {
          modal.show({
            content: <><p>Hay una dirección similar en: <strong>{(addressExists)['name​'] + " " + (addressExists)['number​']}</strong></p><p>¿Estás seguro que quieres crear una nueva dirección sin reutilizar una ya existente?</p></>,
            onSubmit: () => {
              submitForm(values, { setSubmitting })
            },
            secondaryBtnText: 'Cancelar',
            secondaryBtnHandler: () =>{
              setIsSubmited(false)
            },
            variant: 'error'
          })
        } else {
          submitForm(values, { setSubmitting })
        }
      } catch (error) {
        console.error("find address ", error);
      }
    }
    else {
      submitForm(values, { setSubmitting })
    }
  }

  const submitForm = async () => {
    if (isNewAddress) {

      let dataAddressDB = Object.keys(dataAddress).reduce((acc, key) => ({ ...acc, [key.replace('address_', '')]: dataAddress[key] }), {})

      // POST insert address (response new id_address) 
      await addressService.insert(dataAddressDB)
        .then(response => {
          if (response.status === 200) {
            setDataSalePoint({ ...dataSalePoint, id_address: response.id })
            let dataSalePointDB = dataSalePoint
            dataSalePointDB['id_address'] = response.id // assign new id_address before send new info
            dataSalePoint['start_date'] = dataSalePoint['start_date'] && typeof dataSalePoint['start_date'] === 'object' ? dataSalePoint['start_date'].getTime() : dataSalePoint['start_date']
            dataSalePoint['end_date'] = dataSalePoint['end_date'] && typeof dataSalePoint['end_date'] === 'object' ? dataSalePoint['end_date'].getTime() : dataSalePoint['end_date']

            // POST pointOfSale
            pointOfSaleService.insert(dataSalePointDB).then(response => {
              if (response.status === 200) {
                setIsSubmited(false)
                enqueueSnackbar('Punto de venta creado correctamente', {
                  variant: 'success'
                })
                dispatch(fetchPointsOfSale(role))
                history.push('/points-of-sale')
              }
              else {
                addressService.deletebyId(dataSalePointDB['id_address'])
                setIsSubmited(false)
                enqueueSnackbar(response.message, {
                  variant: 'error'
                })
              }
            }).catch(err => {
              addressService.deletebyId(response.id)
              setIsSubmited(false)
              enqueueSnackbar(err.message, {
                variant: 'error'
              })
            })
          }
          else {
            setIsSubmited(false)
            enqueueSnackbar(response.message, {
              variant: 'error'
            })
          }
        }).catch(err => {
          setIsSubmited(false)
          enqueueSnackbar(err.message, {
            variant: 'error'
          })
        })

    } else {
      // POST pdv with existing address
      console.log('dataSalePoint:', dataSalePoint)
      dataSalePoint['start_date'] = dataSalePoint['start_date'] && typeof dataSalePoint['start_date'] === 'object' ? dataSalePoint['start_date'].getTime() : dataSalePoint['start_date']
      dataSalePoint['end_date'] = dataSalePoint['end_date'] && typeof dataSalePoint['end_date'] === 'object' ? dataSalePoint['end_date'].getTime() : dataSalePoint['end_date']
      pointOfSaleService.insert(dataSalePoint).then(response => {
        if (response.status === 200) {
          setIsSubmited(false)
          enqueueSnackbar('Punto de venta creado correctamente', {
            variant: 'success'
          })
          dispatch(fetchPointsOfSale(role))
          history.push('/points-of-sale')
        }
        else {
          setIsSubmited(false)
          enqueueSnackbar(response.message, {
            variant: 'error'
          })
        }
      }).catch(err => {
        setIsSubmited(false)
        enqueueSnackbar(err.message, {
          variant: 'error'
        })
      })
    }
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={schema}
        validateOnChange={true}  // Activa o Desactiva la validación al cambiar los valores de los campos
        validateOnBlur={true}
      >
        {({
          handleChange,
          handleBlur,
          values,
          isValid,
          setFieldValue,
          errors,
          touched
        }) => (
          <Form>
            <Card sx={{ mb: 2 }}>
              <Paper elevation={0}>
                <CardHeader
                  sx={{ pt: 3, pb: 0 }}
                  avatar={
                    <Avatar sx={{ width: 42, height: 42, ml: 2 }} aria-label="point-of-sale">
                      <StorefrontIcon />
                    </Avatar>
                  }
                  title={values.name}
                  subheader={distributors.map(distributor => values.id_distributor === distributor.id ? distributor.name : '')}
                >
                </CardHeader>
                <CardContent>
                  <Grid container>
                    <Grid item xs={12} md={4}>

                      <List dense={true} sx={{ pb: 0 }}>
                        {newItems.map((item, i) => (
                          <ListItem key={i} alignItems="flex-start" sx={{ mb: 1 }}>
                            {(item === "start_date" ? (
                              <DatePicker
                                label={getFormattedFieldName(item)}
                                setFieldValue={setFieldValue}
                                customOnChange={onChangeDate}
                                fieldName={item}
                                views={['day']}
                                maxDate={null}
                                minDate={new Date()}
                                value={values[item]}
                                customError={errors.start_date}
                                customHelperText={errors.start_date}
                              />
                            ) : (
                              item === "end_date" ? (
                                <DatePicker
                                  label={getFormattedFieldName(item)}
                                  setFieldValue={setFieldValue}
                                  customOnChange={onChangeDate}
                                  fieldName={item}
                                  views={['day']}
                                  maxDate={null}
                                  minDate={getMinDate()}
                                  value={values[item]}
                                  customError={errors.end_date}
                                  customHelperText={errors.end_date}
                                />
                              ) : (
                                item === "id_distributor" ? (
                                  <FormControl sx={{ width: '100%' }}>
                                    <InputLabel
                                      id='distributor-label'
                                      error={Boolean(touched.id_distributor && errors.id_distributor)}
                                    >
                                      Distribuidor
                                    </InputLabel>
                                    <Field
                                      component={Select}
                                      labelId='distributor-label'
                                      id='id_distributor'
                                      name='id_distributor'
                                      label='Distribuidor'
                                      value={values.id_distributor}
                                      fullWidth
                                      onChange={(event) => { onChangeIdDistributor(event, setFieldValue) }}
                                      size='small'
                                      error={touched.id_distributor && Boolean(errors.id_distributor)}
                                    >
                                      {distributors &&
                                        distributors.length > 0 &&
                                        distributors.map(dist => {
                                          return (
                                            <MenuItem key={dist.id} value={dist.id}>
                                              {dist.name}
                                            </MenuItem>
                                          )
                                        })}
                                    </Field>
                                    {touched.id_distributor && errors.id_distributor && (
                                      <FormHelperText htmlFor='id_distributor' error>{errors.id_distributor}</FormHelperText>
                                    )}
                                  </FormControl>
                                ) : (
                                  <FormControl fullWidth>
                                    <Field
                                      as={TextField}
                                      variant='outlined'
                                      margin="none"
                                      size="small"
                                      value={values.item}
                                      label={getFormattedFieldName(item)}
                                      onChange={(event) => { onChangeField(event, item, setFieldValue, "salePoint") }}
                                      name={item}
                                      error={touched[item] && Boolean(errors[item])}
                                    />
                                    {touched[item] && errors[item] && (
                                      <FormHelperText htmlFor={item} error>{errors[item]}</FormHelperText>
                                    )}
                                  </FormControl>
                                )
                              )))}
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ m: 2, mb: 4 }} />
                    <Grid item xs={12} md>

                      <List dense={true}>
                        {!isNewAddress ? (
                          <ListItem alignItems="flex-start" sx={{ mb: 1 }}>
                            <Autocomplete
                              id='id_address'
                              size='small'
                              loading
                              loadingText={"Cargando..."}
                              fullWidth sx={{ pt: 0, mb: 1 }}
                              options={addresses}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Dirección completa"
                                  error={touched.id_address && Boolean(errors.id_address)}
                                  helperText="Asigna una dirección existente"
                                />
                              )}
                              getOptionLabel={(option) => option.info_address}
                              isOptionEqualToValue={(option, value) => option.id === value.id}

                              onChange={(event, value) => {
                                setFieldValue("id_address", value?.id ? value.id : '');
                                setDataSalePoint({ ...dataSalePoint, "id_address": value.id })
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props} key={option.id}>
                                  <span>{option.info_address}</span>
                                </li>
                              )}
                            >
                            </Autocomplete>
                          </ListItem>

                        ) : (

                          <AddressForm        //all the rest of Address (ListItems) Fields
                            values={values}
                            touched={touched}
                            errors={errors}
                            onChangeField={onChangeField}
                            setFieldValue={setFieldValue}
                          />
                        )}

                      </List>
                    </Grid>
                  </Grid>
                </CardContent>
              </Paper>
            </Card>

            <Stack direction="row" justifyContent={"space-between"}>
              <Stack spacing={1} direction="row">
                <Button onClick={() => { history.push('/points-of-sale') }} startIcon={<ArrowBackIcon />}>
                    Volver
                </Button>
                <SubmitButton
                  text='Guardar'
                  isValid={isValid}
                  isSubmitting={pointsOfSaleStatus === 'loading' || isSubmited}
                />
              </Stack>
              <Stack spacing={1} direction="row">
                {(isNewAddress &&
                  <Button onClick={() => { setIsNewAddress(false) }} variant="outlined" color="primary">
                    Cancelar
                  </Button>
                )}
                <Button onClick={() => { newAddress() }} disabled={isNewAddress} variant="outlined" color="primary">
                  Nueva Dirección
                </Button>
              </Stack>
            </Stack>

          </Form>
        )}
      </Formik>
    </>
  )
}
