import { Chip, IconButton, Stack, Link, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import userService from "../../services/user.service";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import useModal from "../../common/hooks/useModal";
import { getFormattedStatus } from "../../helpers";
import { useHistory } from "react-router-dom";

export const UsersTable = ({ rowsState, setRowsState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const modal = useModal();
  const history = useHistory();

  const { pageSize, total, rows } = rowsState;

  const columns = [
    {
      headerName: "Email",
      title: "email_address",
      field: "email_address",
      minWidth: 250,
      renderCell:(cellValues) => {
        return(
          <Link color="primary" underline="hover" href={`/user/${cellValues.id}`}>{cellValues.row.email_address}</Link>
        )
      }
    },
    {
      headerName: "Nombre",
      title: "first_name",
      field: "first_name",
      minWidth: 140,
    },
    {
      headerName: "Primer apellido",
      title: "last_name_first",
      field: "last_name_first",
      minWidth: 140,
    },
    {
      headerName: "Segundo apellido",
      title: "last_name_second",
      field: "last_name_second",
      minWidth: 140,
    },
    {
      headerName: "Teléfono",
      title: "phone",
      field: "phone",
      minWidth: 120,
    },
    {
      headerName: "PDV",
      title: "point_of_sale",
      field: "point_of_sale",
      minWidth: 80,
    },
    {
      headerName: "Estado",
      title: "status",
      field: "status",
      minWidth: 80,
      align: "center",
      renderCell: (cellValues) => {
        let label = getFormattedStatus(cellValues.row.status);
        return (
          <Chip
            variant='outlined'
            label={label.text}
            size="small"
            color={label.color}
          />
        );
      },
    },
    {
      headerName: "Acciones",
      field: "actions",
      type: "actions",
      align: "center",
      flex: 1,
      renderCell: (cellValues) => {

          return (
            <>
              <IconButton
                aria-label="Show user"
                edge="start"
                size="small"
                onClick={(evt) => history.push(`/user/${cellValues.id}`)}
              >
                <Tooltip title="Ver / Editar">
                  <EditIcon />
                </Tooltip>
              </IconButton>
              {cellValues.row.status === "DISABLED" ? (
                <IconButton
                  aria-label="Reactivate user"
                  size="small"
                  onClick={(evt) =>
                    modal.show({
                      title: "Reactivar usuario",
                      content: `¿Estás seguro que quieres reactivar el usuario ${cellValues.row.email_address}?`,
                      onSubmit: () => {
                        handleReactivateUser(cellValues.id);
                      },
                    })
                  }
                >
                <Tooltip title="Reactivar">
                  <RefreshIcon />             
                </Tooltip>
              </IconButton>
              ):null} 

              {cellValues.row.status !== "DISABLED" && (
                <IconButton
                  aria-label="Remove user"
                  size="small"
                  onClick={(evt) =>
                    modal.show({
                      title: "Desactivar usuario",
                      content: `¿Estás seguro que quieres desactivar el usuario ${cellValues.row.email_address}?`,
                      onSubmit: () => {
                        handleDisableUser(cellValues.id);
                      },
                    })
                  }
                >
                <Tooltip title="Desactivar">
                  <DeleteIcon />             
                </Tooltip>
                </IconButton>
              )}
              {cellValues.row.status === "CREATED" && (
              <IconButton
                aria-label="Send invitation user"
                size="small"
                edge="end"
                onClick={(evt) =>
                  modal.show({
                    title: "Enviar Invitación",
                    content: `¿Estás seguro que quieres enviar invitación al usuario ${cellValues.row.email_address}?`,
                    onSubmit: () => {
                      handleSendInvitation(cellValues.id);
                    },
                  })
                }
              >
                <Tooltip title="Reenviar invitación">
                <ForwardToInboxIcon />
                </Tooltip>
              </IconButton>)}

            </>
          );
      },
    },
  ];

  const handleReactivateUser = (userId) => {
    setRowsState({ ...rowsState, loading: true }); 
    userService
        .reactivateUserById({ userId }) 
        .then((response) => {
          if (response.status !== 200) {
            enqueueSnackbar(response.message, {
              variant: "error",
            });
            setRowsState({ ...rowsState, loading: false });
          } else {
            enqueueSnackbar(response.message, {
              variant: "success",
            });
            window.location.reload(false);
          }
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
          setRowsState({ ...rowsState, loading: false });
        });
    };

  const handleDisableUser = (userId) => {
    setRowsState({ ...rowsState, loading: true });
    userService
      .deletebyId({ userId })
      .then((response) => {
        if (response.status !== 200) {
          enqueueSnackbar(response.message, {
            variant: "error",
          });
          setRowsState({ ...rowsState, loading: false });
          return;
        } else {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          setRowsState({ ...rowsState, loading: false });
          window.location.reload(false);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
        setRowsState({ ...rowsState, loading: false });
      });
  };

  const handleSendInvitation = (userId) => {
    setRowsState({ ...rowsState, loading: true });
    userService
        .resendCodeById({ userId }) 
        .then((response) => {
          if (response.status !== 200) {
            enqueueSnackbar(response.message, {
              variant: "error",
            });
            setRowsState({ ...rowsState, loading: false });
          } else {
            enqueueSnackbar(response.message, {
              variant: "success",
            });
            window.location.reload(false);
          }
        })
        .catch((err) => {
          enqueueSnackbar(err.message, {
            variant: "error",
          });
          setRowsState({ ...rowsState, loading: false });
        });
    };

  return (
    <div style={{ height: 430, width: "100%", marginBottom: 30 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={total}
        {...rowsState}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        pagination
        paginationMode="server"
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        onPageChange={(page) => {
          setRowsState((prev) => ({ ...prev, page }));
        }}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No hay resultados
            </Stack>
          )
        }}
        sx={{
          '& .actions-justify-start': {
            justifyContent: 'start'
          }
        }}
      />
    </div>
  );
};
