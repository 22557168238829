import React, { useEffect, useState } from "react";

import pointsOfSaleService from "../../../services/pointsOfSale.service";
import { EditUserForm } from "./EditUserForm";
import { CircularProgress } from "@mui/material";

export const PreEditUserForm = (props) => {
  
  const userId = props.userId
  const userInfo = props.userInfo
  const [userPointOfSale, setUserPointOfSale] = useState(null);
  const [isLoadingPOS, setIsLoadingPOS] = useState(true)
  
  
  useEffect(() => {  
    async function fetchUserPointOfSaleInfo() {
      const response = await pointsOfSaleService.getById( userInfo.id_pointsale )
      setUserPointOfSale(response.pointOfSale)
      setIsLoadingPOS(false)
    }
    if (userInfo.id_pointsale !== null){
      fetchUserPointOfSaleInfo()
    }else{
      setUserPointOfSale("")
      setIsLoadingPOS(false)
    }
  },[userInfo.id_pointsale])

  if (isLoadingPOS && !userPointOfSale) {
    return <CircularProgress size={24} color="secondary" />    
  }
  
  return (
    <EditUserForm 
      userId={userId} 
      userInfo={userInfo} 
      userPointOfSale={userPointOfSale} 
      handleDisableUser={props.handleDisableUser} 
      handleReactivateUser={props.handleReactivateUser}
      handleValidateUser={props.handleValidateUser}
      handleSendInvitation={props.handleSendInvitation}
    />
  )
}