import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NewUserForm } from '../../components/users/forms/NewUserForm'

import {
  fetchPointsOfSale,
  selectAllPointsOfSale
} from '../../common/features/pointsOfSale/pointsOfSaleSlice'
import Helmet from 'react-helmet'

export default function NewUser () {
  const dispatch = useDispatch()
  const pointsOfSale = useSelector(selectAllPointsOfSale)
  const pointsOfSaleStatus = useSelector(state => state.pointsOfSale.status)

  useEffect(() => {
    if (pointsOfSaleStatus === 'idle') {
      dispatch(fetchPointsOfSale())
    }
  }, [pointsOfSaleStatus, dispatch])

  return (
    <>
      <h3>Nuevo usuario</h3>
      <Helmet>
        <title>Nuevo usuario - Marina BCN</title>
      </Helmet>
      <NewUserForm pointsOfSale={pointsOfSale}></NewUserForm>
    </>
  )
}
