import { Autocomplete, Box, FormControl, FormHelperText, InputAdornment, InputLabel, MenuItem, TextField, Select, FormControlLabel, FormLabel, Checkbox, FormGroup } from "@mui/material";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { fetchPointsOfSale, selectAllPointsOfSale } from "../../../common/features/pointsOfSale/pointsOfSaleSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import DateRangePicker from "../../common/forms/dateRangePicker";
import QRButton from "../../common/forms/QRButton";
import configDistributor from "../../../config/distributor.json";
import configPublication from "../../../config/publication.json"
import { StepperNavButtons } from "../StepperNavButtons";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Role } from '../../../common/roles/role';

export const StepperConsumptionsForm = ({ setReportDataToSend, reportDataToSend, range, setRange, rangeEdition, setRangeEdition, selectedPointOfSale, setSelectedPointOfSale, inputSelectedPointOfSale, setInputSelectedPointOfSale, steps, activeStep,handleBack, handleNext, handleSendReport}) => {
  const pointsOfSale = useSelector(selectAllPointsOfSale);

  const pointsOfSaleStatus = useSelector((state) => state.pointsOfSale.status);

  const { pointOfSale: userPointOfSale, role } = useSelector(
    state => state.auth.user
  )

  const dispatch = useDispatch();

  useEffect(() => {
    if (pointsOfSaleStatus === "idle") {
      dispatch(fetchPointsOfSale(role));
    }
  }, [pointsOfSaleStatus, role, dispatch]);
  
  const onChangeRange = (value) => {
    const newRange = {
      date_from: value[0]?.getTime(),
      date_to: value[1]?.getTime(),
    };
    setRange(newRange)
    setReportDataToSend({...reportDataToSend, date_from: newRange.date_from, date_to: newRange.date_to})
  };

  const onChangeRangeEdition = (value) => {
    const newRangeEdition = {
      date_edition_from: value[0]?.getTime(),
      date_edition_to: value[1]?.getTime(),
    };
    setRangeEdition(newRangeEdition)
    setReportDataToSend({...reportDataToSend, date_edition_from: newRangeEdition.date_edition_from, date_edition_to: newRangeEdition.date_edition_to})
  };

  const onChangeSuscriptionNumber = (event, setFieldValue, handleChange) => {
    handleChange(event)
    setFieldValue(event.target.value)
    setReportDataToSend({...reportDataToSend, subscription_number: event.target.value })
  };

  const onChangeCardNumber = (event, setFieldValue, handleChange) => {
    handleChange(event)
    setFieldValue(event.target.value)
    setReportDataToSend({...reportDataToSend, card_number: event.target.value })
  };

  const onChangeDistributorId = (event, setFieldValue) => {
    const {value} = event.target
    setFieldValue("distributor_id", value)
    setReportDataToSend({...reportDataToSend, distributor_id: event.target.value })
  };

  const onChangePublicationId = (event, handleChange) => {
    handleChange('publication_id')(event);
    const { value, checked} = event.target;
    setReportDataToSend((prevState) => {
      const newState = { ...prevState };
      if (checked) {
        newState.publication_id = [...newState.publication_id, value];
      } else {
        newState.publication_id = newState.publication_id.filter((id) => id !== value);
      }
      return newState;
    });
  }

  const onChangeTypeOfSale = (event, setFieldValue) => {
    const {value} = event.target
    setFieldValue("type_of_sale", value)
    setReportDataToSend({...reportDataToSend, type_of_sale: event.target.value })
  };

  const onChangePointSaleId = (id) => {
    setReportDataToSend({...reportDataToSend, point_of_sale: id })
  };  

  const oneMonthsMinDate = (date) => {
    const selectedDate = date;
    selectedDate?.setHours(0, 0, 0, 0); 
    const oneMonthsBefore = new Date(selectedDate?.getFullYear(), selectedDate?.getMonth(), selectedDate?.getDate() - 30);
    return oneMonthsBefore;
  }

  const oneYearMinDate = () => {
    const currDate = new Date();
    const yearBefore = currDate.getFullYear() - 1;
    currDate.setFullYear(yearBefore);
    currDate.setHours(0,0,0,0);
    return currDate;
  }
  
  const minDate = oneYearMinDate();
  const maxDate = new Date();
  maxDate.setDate((maxDate).getDate() -1);

  const validationSchema = Yup.object().shape({
    point_of_sale: Yup.string(),
    card_number: Yup.string()
      .matches(/^[0-9]+$/, 'El valor debe ser numérico')
      .min(16, 'El número debe contener 16 dígitos')
      .max(16, 'El número debe contener 16 dígitos'),

    date_from: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_to = this.parent.date_to;
      if (!value && date_to){
          return false; // No válido por rango abierto en date_from
      }
      return true;
    }).test('test-date-from', 'Selecciona un rango de fechas', function(value){
      const date_edition_from = this.parent.date_edition_from;
      const date_edition_to = this.parent.date_edition_to;
      const date_to = this.parent.date_to;
      if(!value && !date_to && !date_edition_from && !date_edition_to){
        return false //No válido en caso de ningún valor en ambos rangos
      }
      return true
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value !== null && value < minDate){
        return false;
      }
      return true
    }).test('invalid-range-31-days','Rango de fechas superior a 31 días',function(value){
      const date_to = this.parent.date_to;
      const minDateRestricted = oneMonthsMinDate(date_to);
      if (value < minDateRestricted){
        return false;
      }
      return true
    }),
    date_to: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_from = this.parent.date_from;
      if (!value && date_from){
          return false; // No válido por rango abierto en date_to
      }
      return true;
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value !== null && value > maxDate){
        return false;
      }
      return true
    }),
    date_edition_from: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_edition_to = this.parent.date_edition_to;
      if (!value && date_edition_to){
          return false; // No válido por rango abierto en date_edition_from
      }
      return true;
    }).test('invalid-range-31-days','Rango de fechas superior a 31 días',function(value){
      const date_edition_to = this.parent.date_edition_to;
      const minDateRestricted = oneMonthsMinDate(date_edition_to);
      if (value < minDateRestricted){
        return false;
      }
      return true
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value !== null && value < minDate){
        return false;
      }
      return true
    }),
    date_edition_to: Yup.date().nullable().test('test-date-from', 'Falta cerrar el rango', function(value){
      const date_edition_from = this.parent.date_edition_from;
      if (!value && date_edition_from){
          return false; // No válido por rango abierto en date_edition_to
      }
      return true;
    }).test('test-out-of-range', 'Fecha fuera de rango disponible', function(value){
      if (value !== null && value > maxDate){
        return false;
      }
      return true
    })
  });

  const dirtyInitial = (values) =>{
    let dirtyInitial = false;
    if(values.date_from !== "" && values.date_to !== ""){
      dirtyInitial = true;
    } else if (values.date_edition_from !== "" && values.date_edition_to !== ""){
      dirtyInitial = true;
    }
    return dirtyInitial
  }

  const dirtyInitialValues = dirtyInitial(reportDataToSend)

  const typeOfSaleList = ["OFFLINE", "ONLINE", "ONLINE ATRASADO", "WEB"].map((type) => (
    <MenuItem key={type} value={type}>
      {type}
    </MenuItem>
  ));

  const distributorList = Object.keys(configDistributor[process.env.REACT_APP_ENVIRONMENT]).map((dist) => (
    <MenuItem key={dist} value={dist}>
      {configDistributor[process.env.REACT_APP_ENVIRONMENT][dist]}
    </MenuItem>
  ));

  const idEditor = role && role.startsWith('editor') ? userPointOfSale : 1;

  const publicationList = Object.keys(configPublication[idEditor]).map((pub) => (
    <FormControlLabel key={pub} value={pub} 
    control={<Checkbox defaultChecked={true}
    size='small'/>} 
    label={configPublication[idEditor][pub]["label"]}
    >
    </FormControlLabel>
  ));

  const onClearDistributor = (setFieldValue) => {
    setFieldValue("distributor_id", '')
    setReportDataToSend({...reportDataToSend, distributor_id: '' })
  };

  const onClearTypeOfSale = (setFieldValue) => {
    setFieldValue("type_of_sale", '')
    setReportDataToSend({...reportDataToSend, type_of_sale: '' })
  };

  console.log(reportDataToSend)

  return (
    <div>
      <p>Añade filtros para refinar tu informe de {reportDataToSend.report_type}</p>
 
      <Box>
        <Formik
          initialValues={reportDataToSend}
          validationSchema={validationSchema}
        >
          {({
            handleChange,
            handleBlur,
            isSubmitting,
            values,
            isValid,
            touched,
            errors,
            setFieldValue
          }) => (
            
            <Box component={Form}
            sx={{
              mb:2,
              "& .MuiFormControl-root": {
                  marginBottom: 2
                },
                "& .MuiAutocomplete-root .MuiFormControl-root": {
                  marginBottom: 1,
                },
              }}
              autoComplete="off"
            >
              <Box>
                <>
                  <Box sx={{ width: "100%", marginBottom: 1 }}>

                    <Autocomplete
                      id="point-of-sale"
                      size="small"
                      loading
                      loadingText={"Cargando..."}
                      options={pointsOfSale}
                      value={selectedPointOfSale? selectedPointOfSale: null}
                      onChange={(event, newValue, reason) => {
                        if (reason === 'clear') {
                          setSelectedPointOfSale('');
                          setInputSelectedPointOfSale('');
                          onChangePointSaleId('')
                          return
                        } else {
                          setSelectedPointOfSale(newValue);
                          console.log('SELECTED', selectedPointOfSale)
                          setInputSelectedPointOfSale(newValue? newValue.name+' - '+newValue.code_editor : '');
                          onChangePointSaleId(newValue.id)
                        }
                      }}
                      sx={{ maxWidth: 489.55 }}
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label="Punto de venta"
                            error={touched.point_of_sale && Boolean(errors.point_of_sale)}
                          />
                        );
                      }}
                      getOptionLabel={(option) => {
                        return (option?.name && option?.code_editor? option.name + " - " + option.code_editor: "")
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return(
                        option?.id === value?.id
                        )
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option?.name && option?.code_editor
                              ? option.name + " - " + option.code_editor
                              : ""}
                          </li>
                        );
                      }}                    
                      >
                    </Autocomplete>
                    {touched.point_of_sale && errors.point_of_sale && (
                      <FormHelperText htmlFor="point-of-sale" error>
                        {errors.point_of_sale}
                      </FormHelperText>
                    )}
                  </Box>

                  <>
                    <FormControl fullWidth sx={{ marginBottom: 1 }}>
                      <InputLabel id="typeOfSale-label">
                        Tipo de venta
                      </InputLabel>
                      <Field
                        component={Select}
                        labelId="typeOfSale-label"
                        id="typeOfSale"
                        label="Tipo de venta"
                        name="typeOfSale"
                        value={values.type_of_sale}
                        onChange={(event) => {onChangeTypeOfSale(event, setFieldValue)}}
                        size="small"
                        sx={{ maxWidth: 489.55 }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 230,
                              width: 250,
                            },
                          },
                        }}
                        endAdornment={ values.type_of_sale && (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{padding:0.5, mr:2.3}}
                                onClick={() => {onClearTypeOfSale(setFieldValue)}}
                              >
                                <ClearIcon sx={{fontSize: 20}} />
                              </IconButton>
                            </InputAdornment>
                          )
                        }
                      >
                        {typeOfSaleList}
                      </Field>
                    </FormControl>
                  </>
                  {role !== Role.Distribuidor && (
                     <>
                    <FormControl fullWidth sx={{ marginBottom: 1 }}>
                      <InputLabel id="distributor-label">Distribuidor</InputLabel>
                      <Field
                        component={Select}
                        labelId="distributor-label"
                        id="distributor"
                        label="Distribuidor"
                        name="distributor"
                        value={values.distributor_id}
                        onChange={(event) => {onChangeDistributorId(event, setFieldValue)}}
                        size="small"
                        sx={{ maxWidth: 489.55 }}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 230,
                              width: 250,
                            },
                          },
                        }}
                        endAdornment={ values.distributor_id && (
                            <InputAdornment position="end">
                              <IconButton
                                sx={{padding:0.5, mr:2.3}}
                                onClick={() => {onClearDistributor(setFieldValue)}}
                              >
                                <ClearIcon sx={{fontSize: 20}} />
                              </IconButton>
                            </InputAdornment>
                        )}
                      >
                        {distributorList}
                      </Field>
                    </FormControl>
                    </>
                  )}
                </>

                <>
                  <FormControl sx={{ paddingLeft: 1.5, paddingTop: 0.5 }}>
                    <FormLabel id="publication-label">Publicación</FormLabel>
                    <Field
                      component={FormGroup}
                      row
                      aria-labelledby="publication-label"
                      name="publication_id"
                      defaultValue=""
                      value={values.publication_id}
                      onChange={ (event) => onChangePublicationId (event, handleChange)}
                    >
                      {publicationList}
                    </Field>
                  </FormControl>
                </>

                <Field
                  component={DateRangePicker}
                  onChangeRange={onChangeRange}
                  dateFromFieldName="date_from"
                  dateToFieldName="date_to"
                  startText="Fecha consumo"
                  middleText="hasta"
                  endText="Fecha consumo"
                  maxDate={maxDate}
                  minDate={minDate}
                  initialRangeValue={[range.date_from, range.date_to]}
                />

                <Field
                  component={DateRangePicker}
                  onChangeRange={onChangeRangeEdition}
                  dateFromFieldName="date_edition_from"
                  dateToFieldName="date_edition_to"
                  startText="Fecha edición"
                  middleText="hasta"
                  endText="Fecha edición"
                  maxDate={maxDate}
                  minDate={minDate}
                  initialRangeValue={[rangeEdition.date_edition_from, rangeEdition.date_edition_to]}
                />

                <Box>
                  <Field
                    as={TextField}
                    label="Número de suscripción"
                    type="text"
                    name="subscription_number"
                    onChange={(event) =>{onChangeSuscriptionNumber(event,setFieldValue,handleChange)}}
                    value={values.subscription_number}
                    fullWidth
                    autoComplete="off"
                    size="small"
                    sx={{ maxWidth: 489.55 }}
                    error={
                      touched.subscription_number &&
                      Boolean(errors.subscription_number)
                    }
                    helperText={
                      touched.subscription_number && errors.subscription_number
                    }
                  ></Field>
                </Box>

                <Box>
                  <Field
                    as={TextField}
                    label="Número de tarjeta"
                    type="text"
                    name="card_number"
                    onChange={(event) =>{onChangeCardNumber(event, setFieldValue, handleChange)}}
                    value={values.card_number}
                    fullWidth
                    autoComplete="off"
                    size="small"
                    sx={{ maxWidth: 489.55 }}
                    error={touched.card_number && Boolean(errors.card_number)}
                    helperText={touched.card_number && errors.card_number}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <QRButton
                            fieldName="card_number"
                            setFieldValue={setFieldValue}
                          />
                        </InputAdornment>
                      ),
                    }}
                  ></Field>
                </Box>
              </Box>
              <StepperNavButtons
                isValid={isValid}
                dirtyInitialValues={dirtyInitialValues}
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                handleSendReport={handleSendReport}
              ></StepperNavButtons>
            </Box>
          )}
        </Formik>
      </Box>
    </div>
  );
};
