import React, { useState } from "react";
import { Link } from "react-router-dom";
import useQuery from "../../../common/hooks/useQuery";
import { ForgotPasswordForm } from "../../../components/auth/forgotPassword/forms/forgotPasswordForm";
import { ForgotPasswordSubmitForm } from "../../../components/auth/forgotPassword/forms/forgotPasswordSubmitForm";
import { Box } from "@mui/material";

function ForgotPassword(props) {
  const [isConfirmationSend, setIsConfirmationSend] = useState(false);
  const [username, setUsername] = useState(null);
  let query = useQuery();

  let email = query.get("email");
  let code = query.get("code");

  if (email && code) {
    return (
      <Box sx={{ maxWidth: 515, margin: "0 auto" }}>
        <h3>Introduce tu nueva contraseña</h3>
        <p>
          Introduce el código de activación que has recibido por correo y genera
          tu nueva contraseña
        </p>
        <ForgotPasswordSubmitForm username={email} code={code} />
      </Box>
    );
  }
  if (!isConfirmationSend) {
    return (
      <Box sx={{ maxWidth: 515, margin: "0 auto" }}>
        <h3>Confirma tu correo electrónico</h3>
        <p>
          Recibirás un correo para recuperar tu contraseña. Si no lo ves, revisa la carpeta de spam
        </p>
        <ForgotPasswordForm
          setUsername={setUsername}
          setIsConfirmationSend={setIsConfirmationSend}
        />
        <br />
        <p>
          Si ya dispones del código de activación de tu cuenta, haz click{" "}
          <Link to="#" onClick={() => setIsConfirmationSend(true)}>
            aquí
          </Link>
        </p>
      </Box>
    );
  } else {
    return (
      <Box sx={{ maxWidth: 515, margin: "0 auto" }}>
        <h3>Introduce tu nueva contraseña</h3>
        <p>
          Introduce el código de activación que has recibido por correo y genera
          tu nueva contraseña
        </p>
        <ForgotPasswordSubmitForm username={username} />
      </Box>
    );
  }
}

export default ForgotPassword;
