import { useState } from "react";
import { useSnackbar } from 'notistack';
import { Stack, IconButton, Link, Chip, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshIcon from '@mui/icons-material/Refresh';

import withRole from "../../common/roles/withRole";
import { Role } from "../../common/roles/role";
import useModal from "../../common/hooks/useModal";
import pointOfSaleService from "../../services/pointsOfSale.service";
import { getFormattedStatus } from "../../helpers";
import { useSelector } from 'react-redux'

export const SalePointTable = ({ rowsState, setRowsState, setSelectedPosId, setShowPos, setIsAction }) => {

  const { role } = useSelector(
    state => state.auth.user
  )
  const RestrictedMarinaAction = withRole([Role.Marina])(IconButton)

  const [dataSalePoint] = useState({})
  const { pageSize, total, rows } = rowsState;
  const modal = useModal()

  const { enqueueSnackbar } = useSnackbar()

  const columns = [
    {
      headerName: "Nombre",
      title: "name",
      field: "name",
      minWidth: 225,
      renderCell: (cellValues) => {
        return (
          (role === Role.Marina) ?
            (<Link component="button" variant="body2" onClick={() => {setSelectedPosId(cellValues.row.id); setShowPos(true)}}><p>{cellValues.row.name}</p></Link>)
            :
            <p>{cellValues.row.name}</p>
        )
      }
    },
    {
      headerName: "Cód. Distribuidor",
      title: "code_distributor",
      field: "code_distributor",
      minWidth: 105,
      align: "left",
    },
    {
      headerName: "Cód. Editor",
      title: "code_editor",
      field: "code_editor",
      minWidth: 105,
      align: "left",
    },
    {
      headerName: "Dirección",
      title: "address_full",
      field: "address_full",
      minWidth: 340,
      flex: 1,
      align: "left",
      renderCell: (cellValues) => {
        let label = cellValues.row.address_full;
        if (label !== null) {
          return (
            <Tooltip title={label} placement="left">
              <p>{label}</p>
            </Tooltip>
          )
        } else {
          return ('')
        }
      },

    },
    {
      headerName: "Teléfono",
      title: "phone",
      field: "phone",
      minWidth: 120,
      align: "left",
    },
    {
      headerName: "Estado",
      title: "status",
      field: "status",
      width: 75,
      align: "center",
      renderCell: (cellValues) => {
        let label = getFormattedStatus(cellValues.row.status);
        return (
          <Chip
            variant='outlined'
            label={label.text}
            size="small"
            color={label.color}
          />
        );
      },
    }
  ]

  if (role === Role.Marina) {
    columns.push(
      {
        headerName: "Acciones",
        field: "actions",
        type: "actions",
        width: 100,
        align: "center",
        renderCell: (cellValues) => {
          return (
            <>
              <IconButton
                aria-label="Show Point Of Sale"
                edge="start"
                size="small"
                onClick={(evt) => {setSelectedPosId(cellValues.row.id); setShowPos(true)}}
              >
                <Tooltip title="Ver / Editar">
                  <EditIcon />
                </Tooltip>
              </IconButton>

              {cellValues.row.status === "BAJA" ? (
                <RestrictedMarinaAction
                  aria-label="Reactivate SalePoint"
                  size="small"
                  onClick={(evt) =>
                    modal.show({
                      title: "Reactivar",
                      content: `¿Estás seguro que quieres reactivar el punto de venta ${cellValues.row.name}?`,
                      onSubmit: () => {
                        actionToReactivate(cellValues.row.id)
                      },
                    })
                  }
                >
                  <Tooltip title="Reactivar">
                    <RefreshIcon />
                  </Tooltip>
                </RestrictedMarinaAction>
              ) : null}

              {cellValues.row.status !== "BAJA" && (
                <RestrictedMarinaAction
                  aria-label="Remove SalePoint"
                  size="small"
                  onClick={(evt) =>
                    modal.show({
                      title: "Dar de Baja",
                      content: `¿Estás seguro que quieres dar de baja el punto de venta ${cellValues.row.name}?`,
                      onSubmit: () => {
                        actionToUnsubscribe(cellValues.row.id)
                      },
                    })
                  }
                >
                  <Tooltip title="Dar de baja">
                    <DeleteIcon />
                  </Tooltip>
                </RestrictedMarinaAction>
              )}
            </>
          )
        }
      }
    )
  }



  const actionToUnsubscribe = (id) => {
    setRowsState(prev => ({ ...prev, loading: true }))
    setIsAction(true)
    dataSalePoint['id'] = id
    dataSalePoint['end_date'] = Date.now()
    pointOfSaleService.updatebyId(dataSalePoint, id).then(response => {
      console.log(dataSalePoint, id)
      if (response.status === 200) {
        enqueueSnackbar("Punto de venta dado de baja", {
          variant: 'success'
        })
      }
      else {
        console.log(response.message)
        enqueueSnackbar(response.message, {
          variant: 'error'
        })
      }
    }).catch(err => {
      setRowsState(prev => ({ ...prev, loading: false }))
      enqueueSnackbar(err.message, {
        variant: 'error'
      })
    }).finally(
      setRowsState(prev => ({ ...prev, loading: false })),
    )
  }

  const actionToReactivate = (id) => {
    setRowsState(prev => ({ ...prev, loading: true }))
    setIsAction(true)
    dataSalePoint['id'] = id
    dataSalePoint['end_date'] = Date.parse("Jan 1, 2100")
    pointOfSaleService.updatebyId(dataSalePoint, id).then(response => {
      setRowsState(prev => ({ ...prev, loading: false }))
      console.log(dataSalePoint, id)
      if (response.status === 200) {
        enqueueSnackbar("Punto de venta reactivado", {
          variant: 'success'
        })
      }
      else {
        enqueueSnackbar(response.message, {
          variant: 'error'
        })
      }
    }).catch(err => {
      enqueueSnackbar(err.message, {
        variant: 'error'

      })
    }).finally(
      setRowsState(prev => ({ ...prev, loading: false })),
    )
  }

  return (
    <div style={{ height: 430, width: "100%", marginBottom: 30 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={total}
        {...rowsState}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        pagination
        paginationMode="server"
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        onPageChange={(page) => {
          setRowsState((prev) => ({ ...prev, page }));
        }}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No hay resultados
            </Stack>
          )
        }}
      />
    </div>
  );
};
