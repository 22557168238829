import { Button, Stack, TextField, Autocomplete, Grid, MenuItem, FormControl, InputLabel, InputAdornment, Select } from '@mui/material'
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import { Field, Form, Formik } from 'formik'
import { SubmitButton } from '../common/forms/submitButton'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import withRole from '../../common/roles/withRole'
import { selectAllPointsOfSale } from '../../common/features/pointsOfSale/pointsOfSaleSlice'
import { Role } from '../../common/roles/role'
import { fetchPointsOfSale } from '../../common/features/pointsOfSale/pointsOfSaleSlice'
import { getFormattedStatus } from "../../helpers";

export const UsersFilters = ({
  rowsState,
  setRowsState
}) => {

  const pointsOfSale = useSelector(selectAllPointsOfSale)
  const pointsOfSaleStatus = useSelector(state => state.pointsOfSale.status)
  const { pointOfSale: role } = useSelector(
    state => state.auth.user
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (role !== Role.Kiosko && pointsOfSaleStatus === 'idle') {
      dispatch(fetchPointsOfSale(role))
    }
  }, [pointsOfSaleStatus, role, dispatch])



  const initialFormValues = {
    point_of_sale: '',
    email: '',
    status: ''
  }

  const handleSubmit = async values => {
    const { email, point_of_sale, status } = values
    setRowsState(prev => ({ ...prev, page: 0, email: email, point_of_sale: point_of_sale, status: status }))
    console.log(rowsState)
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string(),
  })

  const RestrictedButton = withRole([Role.Marina])(Button)

  const statusLabel = ['VALIDATED', 'DISABLED', 'CREATED']

  const statusList = statusLabel.map(status => (
    <MenuItem key={status} value={status}>{getFormattedStatus(status).text}</MenuItem>
  ))

  return (
    <Grid container>
      <Grid item xs={12} md={5} mb={5}>
        <Formik
          initialValues={initialFormValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ handleChange, isSubmitting, values, isValid, touched, errors, setFieldValue }) => (

            <Stack direction="column"
              component={Form}
              sx={{
                '& .MuiTextField-root,& .MuiFormControl-root': {
                  marginBottom: 2,
                  maxWidth: 489.55
                }
              }}
              autoComplete='off'
            >

              <Field
                as={TextField}
                label='Email'
                type='text'
                name='email'
                onChange={handleChange('email')}
                value={values.email}
                autoComplete='off'
                size='small'
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                fullWidth
              ></Field>
              <FormControl>
                <InputLabel id='status-label'>Estado</InputLabel>
                <Field
                  component={Select}
                  labelId='status-label'
                  id='status'
                  name='status'
                  label='Estado'
                  value={values.status}
                  onChange={handleChange('status')}
                  size='small'
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 230,
                        width: 250,
                      },
                    },
                  }}
                  endAdornment={values.status && (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ padding: 0.5, mr: 2.3 }}
                        onClick={() => { setFieldValue("status", '') }}
                      >
                        <ClearIcon sx={{ fontSize: 20 }} />
                      </IconButton>
                    </InputAdornment>
                  )}
                >
                  {statusList}
                </Field>
              </FormControl>
              <Autocomplete
                id='point_of_sale'
                size='small'
                loading
                loadingText={"Cargando..."}
                options={pointsOfSale}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Punto de venta"
                    error={touched.point_of_sale && Boolean(errors.point_of_sale)}
                  />
                )}
                getOptionLabel={(option) => (option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id} >
                      {(option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                    </li>
                  )
                }}
                onChange={(event, value) => {
                  setFieldValue("point_of_sale", value?.id ? value.id : '');
                }}
              >
              </Autocomplete>

              <Stack spacing={1} direction="row">
                <SubmitButton
                  text='Buscar'
                  isValid={isValid}
                  isSubmitting={isSubmitting || rowsState.loading}
                ></SubmitButton>
                <RestrictedButton
                  component={Link}
                  to='/users/new'
                  variant='outlined'
                  color='primary'
                >
                  Nuevo
                </RestrictedButton>
              </Stack>
            </Stack>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}
