//function to get the midpoint of many points with coordinates
export const getMidPoint = (points) => {
    let x = 0;
    let y = 0;
    points.forEach(point => {
        x += point.x;
        y += point.y;
    });
    return {
        x: x / points.length,
        y: y / points.length
    }
}

// funtion to find the value that is closest to an array of values and return the position of this closest value in the array
export const findClosestValuePosition = (value, values) => {
    let closestValue = values[0];
    let closestDistance = Math.abs(value - closestValue);
    let closestPosition = 0;
    values.forEach((val, i) => {
        const distance = Math.abs(value - val);
        if (distance < closestDistance) {
            closestDistance = distance;
            closestValue = val;
            closestPosition = i;
        }
    });
    return closestPosition;
}

//function to find the maximum distance between many points with coordinates
export const getMaxDistance = (points) => {
    let maxDistance = 0;
    points.forEach(point => {
        points.forEach(point2 => {
            const distance = getDistanceBetweenPoints(point.y, point.x, point2.y, point2.x);
            if (distance > maxDistance) {
                maxDistance = distance;
            }
        });
    });
    return maxDistance;
}

//function to get the distance in km between two points with latitude and longitude
export const getDistanceBetweenPoints = (latitude1, longitude1, latitude2, longitude2) => {
    let theta = longitude1 - longitude2;
    let distance = 60 * 1.1515 * (180/Math.PI) * Math.acos(
        Math.sin(latitude1 * (Math.PI/180)) * Math.sin(latitude2 * (Math.PI/180)) + 
        Math.cos(latitude1 * (Math.PI/180)) * Math.cos(latitude2 * (Math.PI/180)) * Math.cos(theta * (Math.PI/180))
    );

    return distance * 1.609344;
}

