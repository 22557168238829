import { Box, Stack } from "@mui/material";
import { grey } from '@mui/material/colors';
import { DataGrid } from "@mui/x-data-grid";
import { formatDate } from "../../helpers";
import consumptionsService from "../../services/consumptions.service";
import { selectAllPointsOfSale } from '../../common/features/pointsOfSale/pointsOfSaleSlice'
import { useSelector } from 'react-redux'
import { Role } from '../../common/roles/role'

export const DayConsumptionsTable = ({
  range,
  rangeEdition,
  pointOfSale,
  subscriptionNumber,
  cardNumber,
  distributorId,
  publicationId,
  rowsState,
  setRowsState,
  selectionModel,
  setSelectionModel,
  prevSelectionModel,
  enableSelectToCancel = false,
}) => {
  const pointsOfSale = useSelector(selectAllPointsOfSale)
  const { role } = useSelector(
    state => state.auth.user
  )

  const { pageSize, total, rows } = rowsState;

  const columns = [
    {
      headerName: "Num. tarjeta",
      title: "num_card",
      field: "num_card",
      flex:1,
      minWidth: 160,
    },
    {
      headerName: "Num. suscripción",
      title: "subscription",
      field: "subscription",
      flex:1,
      minWidth: 100,
    },
    { headerName: "Publicación", title: "name", field: "name",flex:1, minWidth: 190 },
    { headerName: "Copias", title: "copies", field: "copies", width: 75, align: "center" },
    {
      headerName: "Fecha consumo",
      title: "date_consumed",
      field: "date_consumed",
      minWidth: 130,
      align: "center",
      valueFormatter: ({ value }) => {
        return formatDate(value, "es", {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric'});
      },
    },
    {
      headerName: "Fecha edición",
      title: "date_edition",
      field: "date_edition",
      minWidth: 120,
      align: "center",
      valueFormatter: ({ value }) => {
        return formatDate(value, "es");
      },
    },
  ];

  if (role !== Role.Kiosko) {
    columns.push({
      headerName: "Punto de Venta",
      title: "id_pointsale",
      field: "id_pointsale",
      minWidth: 120,
      valueFormatter: ({ value }) => {
        let pdv = pointsOfSale.find((pointOfSale) => pointOfSale.id === value);
        return pdv?.code_editor || '';
      },
    })
    columns.push({ 
      headerName: "Distribuidor",
      title: "name_distributor",
      field: "name_distributor",
      flex:1,
      minWidth: 100,
    })
  }

  const handlePaginate = async (page) => {
    // We clear the previous selection to fix https://github.com/mui/mui-x/issues/3614
    // TO DO: upgrade DataGrid package
    // prevSelectionModel.current = selectionModel
    setSelectionModel([])

    setRowsState((prev) => ({ ...prev, page: page, loading: true }));

    const results = await consumptionsService.getAll({
      pointOfSale,
      date_from: new Date().getTime(),
      date_to: new Date().getTime(),
      per_page: rowsState.pageSize,
      page: page + 1,
      subscription_number: subscriptionNumber,
      card_number: cardNumber,
      id_distributor: distributorId,
      id_publication: publicationId,
      role,
      day: true
    });

    setRowsState((prev) => ({
      ...prev,
      loading: false,
      total: results.total,
      rows: results.consumptions,
    }));

    // setTimeout(() => {
    //   setSelectionModel(prevSelectionModel.current)
    // })
  };

  return (
    <Box sx={{
      height: 400,
      width: '100%',
      marginBottom: 4,
      '& .row-deleted': {
        textDecoration: 'line-through',
        color: grey[500]
      }
    }}>
      <DataGrid
        rows={rows}
        columns={columns}
        rowCount={total}
        {...rowsState}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        pagination
        paginationMode="server"
        checkboxSelection={enableSelectToCancel}
        disableSelectionOnClick={!enableSelectToCancel}
        getRowClassName={(params) => params.row.status === 1 ? 'row-deleted' : 'row-not-deleted'}
        isRowSelectable={(params) => params.row.status !== 1}
        onPageSizeChange={(pageSize) =>
          setRowsState((prev) => ({ ...prev, pageSize }))
        }
        onPageChange={(page) => handlePaginate(page)}
        onSelectionModelChange={newSelectionModel => {
          setSelectionModel(newSelectionModel)
        }}
        selectionModel={selectionModel}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              No hay resultados
            </Stack>
          )
        }}
      />
    </Box>
  );
};
