import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { Field, Form, Formik } from "formik";
import { SubmitButton } from "../../../../components/common/forms/submitButton";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import userService from "../../../../services/user.service";

const initialFormValues = { username: "" };

export const ForgotPasswordForm = ({ setUsername, setIsConfirmationSend }) => {
  const { enqueueSnackbar } = useSnackbar(); // globo de notificacion

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .email('Formato de correo inválido')
      .required('El campo "email" es obligatorio'),
  });

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      setUsername(values.username);
      const username = values.username;
      let user = {}
      user.emailAddress = username
      await userService
      .forgotPassword({ user:user })
      .then((response) => {
        if (response.status !== 200) {
          setSubmitting(false);
          setUsername(null);
          enqueueSnackbar(response.message, {
            variant: "error", 
          });
        } else {
          enqueueSnackbar(response.message, {
            variant: "success",
          });
          setSubmitting(false);
          setIsConfirmationSend(true);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      });

    } catch (err) {
      setSubmitting(false);
      setErrors({ username: err.message });
    }
  };

  return (
    <Formik
      initialValues={initialFormValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, isSubmitting, values, isValid, touched, errors }) => (
        <Box
          component={Form}
          sx={{
            "& .MuiTextField-root": {
              marginTop: 1,
              marginBottom: 1,
              width: "100",
            },
            "& .MuiTextField-root:last-of-type": { marginBottom: 2 },
            "& .MuiTextField-root:first-of-type": { marginTop: 0 },
          }}
          autoComplete="off"
        >
          <Field
            as={TextField}
            type="email"
            name="username"
            label="Email"
            onChange={handleChange}
            value={values.username}
            fullWidth
            autoComplete="on"
            size="small"
            error={touched.username && Boolean(errors.username)}
            helperText={touched.username && errors.username}
          />
          <SubmitButton
            text="Aceptar"
            isValid={isValid}
            isSubmitting={isSubmitting}
          />
        </Box>
      )}
    </Formik>
  );
};
