import { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { ClientFilters } from '../../components/clients/ClientsFilters'
import { ClientsTable } from '../../components/clients/ClientsTable'
import { useDispatch, useSelector } from 'react-redux';
import {fetchClients, selectAllClients} from '../../common/features/clients/clientsSlice';

export default function Clients() {
  const clientsAll = useSelector(selectAllClients);
  const clientsStatus = useSelector((state) => state.clients.status);
  const dispatch = useDispatch();

  const [rowsState, setRowsState] = useState({
    rows: [],
    loading: clientsStatus !== "succeeded",
    cardNumber: '',
    alias: ''
  })

  useEffect(() => {
    if (clientsStatus === "idle") {
      dispatch(fetchClients());
    }
    if (clientsStatus === "succeeded") {
      setRowsState(prev => ({
        ...prev,
        loading: false,
      }))
    }
  }, [clientsStatus, dispatch]);

  useEffect(() => {
    let clients = clientsAll
    
    if (rowsState.cardNumber!==''){
      clients = clientsAll.filter(client => client.card === rowsState.cardNumber)  
    }

    if (rowsState.alias!==''){
      clients = clients.filter(client => client.alias.toLowerCase().startsWith(rowsState.alias.toLowerCase())) 
    }
    
    setRowsState(prev => ({
      ...prev,
      rows: clients || []
    }))


  }, [clientsAll, rowsState.cardNumber, rowsState.alias])


      
  return (
    <>
      <h3>Mis Clientes</h3>
      <Helmet>
        <title>Mis Clientes - Marina BCN</title>
      </Helmet>
      <ClientFilters
        rowsState={rowsState}
        setRowsState={setRowsState}
      />
      <ClientsTable rowsState={rowsState} setRowsState={setRowsState} />
    </>
  )
}
