/**
 * Devuele una fecha formatada dado un timestamp y un locale
 * Ejemplo de uso: format(value,'es')
 * @returns {String}
 **/
export const formatDate = (date, locale, options) => {
  return new Intl.DateTimeFormat(locale, options).format(date);
};

export const capitalizeWord = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * Devuele el status traducido y con el color que se pintara en su label
 * @returns {Object}
 **/
export const getFormattedStatus = (value) => {
  const label = {
    text: "",
    color: ""
  }
  if (value === "CREATED") {
    label.text = "Creado"
    label.color = "warning"
  } else if (value === "DISABLED") {
    label.text = "Inactivo"
    label.color = "secondary"
  } else if (value === "VALIDATED") {
    label.text = "Validado"
    label.color = "success"
  } else if (value === "ACTIVO") {
    label.text = "Alta"
    label.color = "success"
  } else if (value === "BAJA") {
    label.text = "Baja"
    label.color = "secondary"
  } else {
    label.text = value
    label.color = "primary"
  }
  return label
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
export const getMobileOS = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/ggmarinadroid/i.test(userAgent)) {
    return "android";
  }

  if (/ggmarinaios/.test(userAgent)) {
    return "ios";
  }

  return "unknown";
};


/**
 * Devuele campos traducidos de labels para forms
 * @returns {Object}
 **/
export const getFormattedFieldName = (value) => {
  let label = ""

  if (value === "email_address" || value === "email") {
    label = "Email"
  } else if (value === "role") {
      label = "Rol"
  } else if (value === "first_name" || value === "name") {
    label = "Nombre"
  } else if (value === "last_name_first") {
    label = "Primer Apellido"
  } else if (value === "last_name_second") {
    label = "Segundo Apellido"
  } else if (value === "phone") {
    label = "Teléfono"
  } else if (value === "editor" || value === "id_editor") {
    label = "Editorial"  
  } else if (value === "distributor" || value === "id_distributor") {
    label = "Distribuidor"  
  } else if (value === "pointsale" || value === "id_pointsale") {
    label = "Punto de Venta"
  } else if (value === "code_editor") {
    label = "Código Editor"    
  } else if (value === "code_distributor") {
    label = "Código Distribuidor"
  } else if (value === "start_date") {
    label = "Fecha de Alta"
  } else if (value === "end_date") {
    label = "Fecha de Baja"
  } else if (value === "nif") {
    label = "NIF / CIF"
  } else if (value === "address_full" || value === "address_id") {
    label = "Dirección"
  } else if (value === "description") {
    label = "Descripción"
  } else {
    label = value
  }
  return label
}