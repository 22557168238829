import { Box, FormControl, InputLabel, MenuItem, TextField, Select } from '@mui/material'
import * as Yup from "yup";
import { Field, Formik, Form } from "formik";
import FormHelperText from '@mui/material/FormHelperText';
import { StepperNavButtons } from '../StepperNavButtons';
import configPublication from "../../../config/publication.json"
import { useSelector } from 'react-redux'


export const SetReport = ({setReportDataToSend, reportDataToSend, setRange, setRangeEdition, setRangeEnding, setSelectedPointOfSale, setInputSelectedPointOfSale, steps, activeStep, handleBack, handleNext, role}) => {

  const report_typeList = ['Consumos', 'Usuarios', 'Puntos de venta'].map(type => (
    <MenuItem key={type} value={type}>{type}</MenuItem>
  ))

  const report_typeList_editor_dis = ['Consumos', 'Puntos de venta'].map(type => (
    <MenuItem key={type} value={type}>{type}</MenuItem>
  ))

  const { pointOfSale: userPointOfSale } = useSelector(
    state => state.auth.user
  )
  const idEditor = role && role.startsWith('editor') ? userPointOfSale : 1;
  const publicationsList = Object.keys(configPublication[idEditor]).map(publication => {return publication})
  
  const onChangeReportType = (event, setFieldValue) =>{
    const {value} = event.target
    setFieldValue("report_type", value)
    setReportDataToSend({...reportDataToSend,  // to not keep the values ​​of reportDataToSend when going back and changing the report_type
      report_type: value,
      user_status:'',
      date_from:'',
      date_to:'',
      date_edition_from:'',
      date_edition_to:'',
      date_ending_from:'',
      date_ending_to:'',
      subscription_number:'',
      card_number:'',
      point_of_sale:'',
      type_of_sale:'',
      distributor_id:'',
      publication_id: publicationsList //array of publication values by idEditor
    })
      setRange({ date_from: '', date_to: '' })
      setRangeEdition({ date_edition_from: '', date_edition_to: '' })
      setRangeEnding({ date_ending_from: '', date_ending_to: ''})
      setSelectedPointOfSale(null)
      setInputSelectedPointOfSale('')
  }

  const onChangeReportTitle = (event, setFieldValue) =>{
    setFieldValue("report_title", event.target.value)
    const formatted_report_title = event.target.value.toLowerCase().replace(/ /g, '_');
    setReportDataToSend({...reportDataToSend, report_title: formatted_report_title})
  }
  
  const validationSchema = Yup.object().shape({
    report_title: Yup.string().matches(/^[^\s].*$/, 'El nombre no puede comenzar con un espacio en blanco')
    .matches(/^[a-zA-Z0-9_\s]+$/, 'El nombre debe contener solo letras y números')
    .required("'Nombre de Informe' es un campo requerido"),
    report_type: Yup.string().required("'Tipo de informe' es un campo requerido")
  });

  const dirtyInitial = (values) =>{
    let dirtyInitial = false;
    if(values.report_title !== "" && values.report_type !== ""){
      dirtyInitial = true;
    }
    return dirtyInitial
  }

  const dirtyInitialValues = dirtyInitial(reportDataToSend)

  return (
    <div>
      <Formik
        initialValues={reportDataToSend}
        validationSchema={validationSchema}
        enableReinitialize={false}
      >
        {({
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          isSubmitting,
          isValid,
          touched,
          errors,
          dirty
        }) => (
        <>
          <Box sx={{mb:3}}>
            <p>Selecciona el tipo de informe</p>
          <Form>
            <FormControl fullWidth>
              <InputLabel id="report_type-label">Tipo de informe</InputLabel>
              <Field
                component={Select}
                labelId="report_type-label"
                id="report_type"
                label="Tipo de informe"
                name="report_type"
                value={values.report_type}
                onBlur={handleBlur('report_type')}
                onChange={(event) => {
                  onChangeReportType(event, setFieldValue);
                }}
                size="small"
                sx={{ maxWidth: 515 }}
                error={(touched.report_type && Boolean(errors.report_type))}
                >
                {role && (role.startsWith('editor') || role.startsWith('distribuidor')) ? report_typeList_editor_dis : report_typeList}
              </Field>
              {touched.report_type && errors.report_type && (
                <FormHelperText error>{errors.report_type}</FormHelperText>
              )}
            </FormControl>

              <p>Introduce un nombre para el nuevo informe</p>
              
              <Field
                as={TextField}
                type="text"
                name="report_title"
                label="Nombre del informe"
                value={values.report_title}
                onChange={(event) => {
                  onChangeReportTitle(event, setFieldValue);
                }}
                autoComplete="off"
                size="small"
                fullWidth
                sx={{ maxWidth: 515 }}
                error={touched.report_title && Boolean(errors.report_title)}
                helperText={touched.report_title && errors.report_title}
              >
              </Field>
            </Form>
          </Box>
          <StepperNavButtons
            isValid={isValid}
            dirty={dirty}
            dirtyInitialValues={dirtyInitialValues}
            steps={steps}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
          ></StepperNavButtons>
        </>
        )}
      </Formik>
    </div>
  );
};
