import { Autocomplete, Box, FormHelperText, InputAdornment, TextField, Stack, FormControlLabel, Switch } from "@mui/material";
import { Field, Form, Formik } from "formik";
import DatePicker from "../../common/forms/datepicker";
import { SubmitButton } from "../../common/forms/submitButton";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPointsOfSale,
  selectAllPointsOfSale,
  setCurrent,
} from "../../../common/features/pointsOfSale/pointsOfSaleSlice";
import { useEffect, useState } from "react";
import servicesService from "../../../services/services.service";
import { Role } from "../../../common/roles/role";
import QRButton from "../../common/forms/QRButton";
import { useSnackbar } from "notistack";
import { ClientsList } from "../../clients/ClientsList";
import { useRouteMatch } from "react-router-dom";

export const NewConsumptionForm = ({
  cardNum,
  setCardNum,
  setServices,
  disableDateField,
  enableClientList,
  setSource,
  source,
  clients,
  clientsPendingConsumption
}) => {
  const { user } = useSelector((state) => state.auth);
  const { role } = user;

  const pointsOfSale = useSelector(selectAllPointsOfSale);
  const pointsOfSaleStatus = useSelector((state) => state.pointsOfSale.status);

  const dispatch = useDispatch();

  const [showAll, setShowAll] = useState(true);

  const handleShowAll = () => {
    // Show All Clients or Pending Consumptions Clients
    setShowAll(!showAll);
  };

  const { enqueueSnackbar } = useSnackbar();

  const currentRouterMatch = useRouteMatch();
  const isDelayedConsumption = currentRouterMatch?.path === '/consumptions/new-delayed';

  const getMinDate = () => {
    let date = new Date();
    date.setDate(date.getDate() - 2);
    date.setHours(0, 0, 0, 0);
    return date;
  }
  const minDate = ([Role.Kiosko, Role.Distribuidor].includes(role) && process.env.REACT_APP_ENVIRONMENT !== 'des') ? getMinDate() : null


  const getMaxDate = () => {
    const currDate = new Date();
    const maxDate = new Date();
    maxDate.setDate(currDate.getDate() - 1);
    return maxDate;
  };
  const maxDate = (isDelayedConsumption ? getMaxDate() : new Date()) // Only if view is "venta atrasada" maxDate is yesterday 

  const initialFormValues = {
    show_point_of_sale: [Role.Marina, Role.Distribuidor].includes(role),
    point_of_sale: "",
    card_number: cardNum || "",
    edition_date: maxDate,
    id_client: "",
  };

  useEffect(() => {
    if ([Role.Marina, Role.Distribuidor].includes(role) && pointsOfSaleStatus === 'idle') {
      dispatch(fetchPointsOfSale(role))
    }
  }, [pointsOfSaleStatus, role, dispatch]);

  const handleSubmit = async (values, actions) => {
    
    const { card_number, edition_date } = values;
    if ([Role.Marina, Role.Distribuidor].includes(role)) {
      const { point_of_sale } = values;
      dispatch(setCurrent(point_of_sale));
    } else {
      dispatch(setCurrent(user.pointOfSale));
    }

    setCardNum(card_number);

    await servicesService
      .get({
        numCard: card_number,
        dateEdition: edition_date,
        day: isDelayedConsumption === false ? true : false,
        role,
      })
      .then((data) => {
        if (data && data.length > 0) {
          setServices(data);
          if (!data.filter((service) => service.consumed === 0).length) {
            enqueueSnackbar(
              "Ya se han retirado todos los ejemplares disponibles para esta fecha",
              {
                variant: "error",
              }
            );
          }
        } else {
          setServices([]);
          enqueueSnackbar(
            "No se encuentran servicios disponibles asociados a la búsqueda realizada",
            {
              variant: "error",
            }
          );
        }
      });
  };

  const validationSchema = Yup.object().shape({
    point_of_sale: Yup.string().when("show_point_of_sale", {
      is: true,
      then: Yup.string().required("Debes seleccionar un punto de venta"),
      otherwise: Yup.string(),
    }),
    card_number: Yup.string()
      .required("El número de tarjeta no puede estar vacío")
      .matches(/^[0-9]+$/, "El valor debe ser numérico")
      .min(16, "El número debe contener 16 dígitos")
      .max(16, "El número debe contener 16 dígitos"),
    edition_date: Yup.date()
      .typeError("Introduce una fecha válida")
      .test(
        "is-valid-date",
        "Introduce una fecha válida",
        (value, context) => value >= minDate && value <= maxDate
      ),
  });

  const [selectedClient, setSelectedClient] = useState("");

  const onChangeClient = (setFieldValue, newValue) => {
    setSelectedClient(newValue);
    setFieldValue("card_number", newValue.card);
    setSource(3);
  };

  const onChangeCardNumber = (event, setFieldValue) => {
    setFieldValue("card_number", event.target.value);
    setSource(1);
  };

  return (
    <Box mb={5}>
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
          values,
          isValid,
          touched,
          errors,
          handleReset,
          resetForm
        }) => (
          <Stack
            direction="column"
            component={Form}
            sx={{
              maxWidth: 489.55,
              "& .MuiTextField-root": {
                marginBottom: 2,
              },
              "& .MuiTextField-root:first-of-type": { marginTop: 0 },
            }}
            autoComplete="off"
          >
            <Field name="show_point_of_sale" hidden />
            {[Role.Marina, Role.Distribuidor].includes(role) && (
              <Box>
                <Autocomplete
                  id="point-of-sale"
                  size="small"
                  loading
                  loadingText={"Cargando..."}
                  options={pointsOfSale}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Punto de venta"
                      error={touched.point_of_sale && Boolean(errors.point_of_sale)}
                    />
                  )}
                  getOptionLabel={(option) => (option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id} >
                        {(option?.name && option?.code_editor) ? (option.name + " - " + option.code_editor) : ""}
                      </li>
                    );
                  }}
                  onChange={(event, value) => {
                    setFieldValue("point_of_sale", value?.id ? value.id : "");
                    setServices([]);
                  }}
                ></Autocomplete>
                {touched.point_of_sale && errors.point_of_sale && (
                  <FormHelperText htmlFor="point-of-sale" error>
                    {errors.point_of_sale}
                  </FormHelperText>
                )}
              </Box>
            )}

            {role === Role.Kiosko && enableClientList && !isDelayedConsumption && (
                <Autocomplete
                id="id_client"
                size="small"
                loading
                loadingText={"Cargando..."}
                  options={showAll ? clients : clientsPendingConsumption}
                  value={selectedClient ? selectedClient : null}
                  onChange={(event, newValue, reason) => {
                    if (reason === "clear") {
                      setSelectedClient("");
                      onChangeClient("");
                      return;
                    } else {
                      onChangeClient(setFieldValue, newValue);
                    }
                  }}
                  noOptionsText="No hay opciones disponibles"
                  getOptionLabel={(option) => option.alias}
                  renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                      {option.alias + ` (` + option.card + `)`}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Clientes" />
                  )}
                />
              )}

            <Field
              as={TextField}
              label="Número de tarjeta"
              type="text"
              name="card_number"
              onChange={(event) => {
                onChangeCardNumber(event, setFieldValue);
              }}
              value={values.card_number}
              fullWidth
              autoComplete="off"
              size="small"
              error={touched.card_number && Boolean(errors.card_number)}
              helperText={touched.card_number && errors.card_number}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <QRButton
                      fieldName="card_number"
                      setFieldValue={setFieldValue}
                      setSource={setSource}
                    />
                  </InputAdornment>
                ),
              }}
            ></Field>

            {!disableDateField && (
              <Box>
                <DatePicker
                  label="Fecha de edición"
                  setFieldValue={setFieldValue}
                  fieldName="edition_date"
                  value={maxDate}
                  maxDate={maxDate}
                  minDate={minDate}
                  views={["day"]}
                  customError={Boolean(errors.edition_date)}
                  customHelperText={errors.edition_date}
                />
              </Box>
            )}
            <Stack justifyContent={"space-between"} direction="row">
              <Stack spacing={1} direction="row">
                <SubmitButton
                  text="Procesar venta"
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                ></SubmitButton>
              </Stack>
              {role === Role.Kiosko && enableClientList && !isDelayedConsumption && (
                <Stack>
                  <FormControlLabel sx={{ mr: 1 }} checked={!showAll} control={<Switch color="secondary" onChange={handleShowAll} />} label="Pendientes" />
                </Stack>
              )}
            </Stack>

            {role === Role.Kiosko && enableClientList && (
              <ClientsList
                setFieldValue={setFieldValue}
                setSource={setSource}
                clients={showAll ? clients : clientsPendingConsumption}
                showAll={showAll}
                setSelectedClient={setSelectedClient}
                isDelayedConsumption={isDelayedConsumption}
              />
            )}
          </Stack>
        )}
      </Formik>
    </Box>
  );
};
