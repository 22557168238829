import { formatDate, capitalizeWord } from "../../helpers";

export const formatDateFromUTC = (utcDate) =>{

  if (!utcDate) {
    return 'Fecha no proporcionada';
  }

  const date = new Date(utcDate);
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = formatDate(date, 'es-ES', options);
  
  const words = formattedDate.split(' ');
  // Capitalizar la primera letra de la tercera palabra (que es el mes)
  words[2] = capitalizeWord(words[2])
  const capitalizedDate = words.join(' ');

  return capitalizedDate;
}