import { Typography, Box, Link } from "@mui/material";
import { Popup } from "react-map-gl/maplibre";
import { Role } from "../../common/roles/role";
import { useSelector } from "react-redux";


// A popup containing locker details
export const LockerPopup = ({ locker: { latitude, longitude, title, description, codeEditor, codeDistributor, id }, onClose, setSelectedPosId, setShowPos }) => {
  
  const { role } = useSelector(
    state => state.auth.user
  )

  return (
    // See https://visgl.github.io/react-map-gl/docs/api-reference/popup
    <Popup
    latitude={latitude}
    longitude={longitude}
    // offset the popup so that it attaches to the top of the pushpin
    offset={[0, -36]}
    closeButton
    closeOnClick
    onClose={onClose}
    anchor="bottom"
    style={{borderRadius:6,}}
    >
      {console.log(role)}
      <Box sx={{ px: 2 }}>
        {(role === Role.Marina) ?
          (<Link component="button" onClick={() => {setSelectedPosId(id); setShowPos(true)}} color="primary" underline="hover" href={`/point-of-sale/${id}`}><Typography variant="subtitle2" gutterBottom>{title}</Typography></Link>)
          :
          <Typography variant="subtitle2" gutterBottom>{title}</Typography>
        }
        <Typography variant="body2" fontSize={12}>Editor: {codeEditor} </Typography>
        <Typography variant="body2" fontSize={12} gutterBottom>Distribuidor: {codeDistributor} </Typography>
        <Typography variant="body2" fontSize={12}>{description}</Typography>
      </Box>
    </Popup>
  )
}