import { Box, Paper, Stack, Typography } from "@mui/material";
import { grey } from '@mui/material/colors';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useSelector } from 'react-redux';
import { selectClientByCard } from '../../common/features/clients/clientsSlice';


export const ClientAlias = ({cardNum}) => {

  const client = useSelector(selectClientByCard(cardNum));

  return (
    <Stack component={Paper} variant="outlined" direction="row" spacing={1.5} sx={{
      my: 2,
      p: 1,
      minHeight: 46,
      color: grey[700],
      backgroundColor: grey[100],
      alignItems: 'center',
      '& .MuiTypography-body2 > em': {
        pr: 0.5
      }
    }}>
      <AssignmentIndIcon fontSize='large' sx={{ color: grey[600] }} />
      <Box>
        {client?.alias && (
            <Typography variant="body2" sx={{ my: 0.25 }}><em>Nombre:</em> {client.alias}</Typography>
        )}
        <Typography variant="body2" sx={{ my: 0.25 }}><em>Núm. tarjeta:</em> {cardNum}</Typography>
      </Box>
    </Stack>
  );
};
